import React from 'react'
import { useState , useEffect, useRef } from 'react'
import {Fade} from 'react-reveal'
import './ErrorMessage.css'
const ErrorMessage = ({message}) => {

    const [time , setTime] = useState(true)
    const [show , setShow] =useState(true)
    const [remove,setRemove] = useState(false)
    const display = () =>{
      setShow(true)
      setTimeout(()=>{
        setRemove(true)
      }, 3000)
      setTimeout(()=>{
        setShow(false)
      }, 4100)
    }
    useEffect(() => {

      display()
    }, []);




  return (
    <>

  {show && <div className='success' style={{background: '#FF3737', animationName : remove ? 'errorLeave' : 'errorEnter', animationDuration : remove ? '1s' : '1s', animationTimingFunction : remove ? 'linear' : ""}}>
    <div className="success-text">
        <h1>Error</h1>
        <p>{message}</p>
    </div>
</div> }

</>
  )
}

export default ErrorMessage