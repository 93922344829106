import { useState, useEffect, useRef } from "react";
import "./security.css";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import Success from "../../../../Success/Success";
const Security = () => {
  const { api, token } = useAuthContext();

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const name = useRef();
  const businessName = useRef();
  const email = useRef();
  const oldPassword = useRef();
  const newPassword = useRef();
  const confirmPassword = useRef();

  const [emailError, setEmailError] = useState(false);

  const [businessNamee, setBusinessNamee] = useState("Qevolt");
  const [fullNamee, setFullNamee] = useState("Fagbenja Tolani");
  const [eemail, setEemail] = useState("hello@qevolt.co");
  const [passwordd, setPasswordd] = useState("password");
  const [checkSecurity, setCheckSecurity] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [valid, setValid] = useState(false);

  const [confirm_password, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [old_Password, setOldPassword] = useState("");
  const [passwordPending, setPasswordPending] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const ChangePassword = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const form = {old_password: old_Password , confirm_password, password };
    try {
      setPasswordPending(true);
      const response = await axios.post(
        `${api}/accounts/set_password`,
        form,
        config
      );

      if (response) {
        setConfirmPassword("");
        setPassword("");
        setErrorMessage(response.data.error);
        setSuccessMessage(response.data.success);
        setPasswordPending(false);
        console.log(response);
      }
    } catch (err) {}
  };

  // const filled = () => {
  //   if (oldPassword.current.value.length < 8) {
  //     setCheckSecurity(false);
  //   } else {
  //     setCheckSecurity(true);
  //   }
  // };

  const checkPass = () => {
    if (confirm_password !== "" && old_Password !== "" && password !== "") {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  useEffect(() => {
    checkPass();
  }, [confirm_password, old_Password, password]);

  // const filled = () => {
  //   if (
  //     name.current.value !== "" &&
  //     email.current.value !== "" &&
  //     password.current.value !== "" &&
  //     !emailError &&
  //     !passwordError
  //   ) {
  //     setCheckSignUp(true);
  //   } else {
  //     setCheckSignUp(false);
  //   }
  // };

  // useEffect(() => {
  //   filled();
  // });

  return (
    <Fade duration={250}>
      <div className="setting-profile-container">
        {errorMessage !== "" && errorMessage && (
          <ErrorMessage message={errorMessage} />
        )}
        {successMessage !== "" && successMessage && (
          <Success message={successMessage} />
        )}

        <form
          className="setting-profile-form"
          action=""
          onSubmit={ChangePassword}
        >
          <label>
            <div className="settings-label">Old Password</div>
            <input
              className="settingsInputBox"
              type="password"
              name="old_password"
              required
              // value={eemail}
              value={old_Password}
              // ref={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
                checkPass();
              }}
              // onChange={filled()}
            />
          </label>
          <label>
            <div className="settings-label">New Password</div>
            <input
              className="settingsInputBox"
              type="password"
              name="password"
              required
              // value={eemail}
              // ref={password}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                checkPass();
              }}
              // onChange={filled()}
            />
          </label>
          <label>
            <div className="settings-label">Confirm Password</div>
            <input
              className="settingsInputBox no-mb"
              type="password"
              name="confirm_password"
              value={confirm_password}
              // ref={confirm_password}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                checkPass();
              }}
              required
              // value={eemail}

              // onChange={filled()}
            />
          </label>
          {emailError && (
            <p className="emailError">Please enter a valid email address</p>
          )}

          {/* <p style={{transform:'translateY(15px)' , color: 'green'}}> {successMessage} </p>  */}
          {/* <p style={{transform:'translateY(15px)' , color: 'red'}}> {errorMessage} </p> */}

          <br />

          {!passwordPending && (
            <button
              className="profile-save-button neg-btn"
              style={{
                cursor: valid ? "pointer" : "",
                background: valid ? "#8F00FF" : "#D9D9D9",
                color: "#FFF",
              }}
              disabled={!valid}
              onClick={ChangePassword}
            >
              Save Changes
            </button>
          )}
          {passwordPending && (
            <button
              className="profile-save-button neg-btn"
              style={{ cursor: "pointer" }}
              disabled
            >
              <img src="/assets/spinner.svg" alt="" style={{ width: "40px" }} />
            </button>
          )}
        </form>
      </div>
    </Fade>
  );
};

export default Security;
