import axios from "axios";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { Fade } from "react-reveal";
import QevoltWarning from "../../QevoltWarning/QevoltWarning";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PopUp from "../PopUp/PopUp";
import "./Home.css";
import Success from "../../Success/Success";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import AOS from "aos";
import "aos/dist/aos.css";
import Hamburger from "../Hamburger/Hamburger";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import DateComponent from "../DateComponent/DateComponent";
import HomeMobile from "./HomeMobile/HomeMobile";
import QevoltButton from "../../QevoltButton/QevoltButton";
import QevoltInput from "../../QevoltInput/QevoltInput";
import QevoltLabel from "../../QevoltLabel/QevoltLabel";
const Home = () => {
  const reg = localStorage.getItem("reg");
  const roll = useRef();
  const changeId = (
    secret,
    name,
    accNumber,
    bankName,
    verified,
    processed,
    access
  ) => {
    localStorage.setItem("outlet_secret_id", secret);
    localStorage.setItem("access", access);
    localStorage.setItem(
      "OutletDetails",
      JSON.stringify({
        name,
        accNumber,
        bankName,
        verified,
        processed,
      })
    );
    getTransactions();
    setDetails(JSON.parse(localStorage.getItem("OutletDetails")));
    dispatch({ type: "FILTER_POP_UP", payload: false });
    dispatch({ type: "BUSINESS_DATA", payload: null });
    dispatch({ type: "OUTLET_RESET", payload: true });
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const [outlet_name, setOutletName] = useState("");
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");
  const upload = () => {
    document.querySelector(".upload").click();
  };
  const refreshPage = useRef();
  const [cac, setCac] = useState(null);
  const [success, setSuccess] = useState("");
  const [showTransaction, setShowTransaction] = useState(false);
  const [data, setData] = useState(null);
  const [term, setTerm] = useState("");
  const [pending, setPending] = useState(false);
  const [formPop, setFormPop] = useState(false);
  const [error, setError] = useState(null);
  const [second, setSecond] = useState(false);
  const [copied, setCopied] = useState(false);
  const [bank, setBank] = useState("3140791773");
  const [showRefund, setShowRefund] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [arr, setArr] = useState([]);
  const [refundButton, setRefundButton] = useState(true);
  const [refundValue, setRefundValue] = useState("");
  const [newt, setNewt] = useState("");
  const [refundsMobile, setRefundsMobile] = useState(false);
  const [rollerPending, setRollerPending] = useState(false);
  const [btnCheck, setBtnCheck] = useState(false);
  const [search, setSearch] = useState(false);
  const [fullName, setFullName] = useState(false);
  const [tranDate, setTranDate] = useState("");
  const [trigger, setTrigger] = useOutletContext();
  console.log(trigger);
  const getTransactions = async () => {
    setPending(true);
    try {
      const response = await axios.get(
        `${api}/main/view/transactions/${localStorage.getItem(
          "outlet_secret_id"
        )}`,
        config
      );
      if (response) {
        setPending(false);
        setError(null);
        setData(response.data.results);
        setArr(response.data.results);
      }

      if (data) {
        setArr(data);
      }
    } catch (err) {
      setError("");
    }
  };

  const navigate = useNavigate();

  const copy = useRef();

  const dashcontainer = document.querySelector(".dashboard-container");
  const cov = document.querySelector(".cover");
  const mod = document.querySelector("#modalodd");
  const addModal = (event) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.secret_id === event.secret_id;
      });
    });

    setShowModal(true);

    setShowTransaction(true);

    if (window.matchMedia("(max-width: 575px)").matches) {
      dashcontainer.style.cssText = `height: 100vh;`;
    }
  };

  const business_id = localStorage.getItem("business_secret_id");
  const [Odata, setOData] = useState(null);
  const [Opending, setOPending] = useState(false);
  const [Oerror, setOError] = useState("O");
  const getOutletList = async () => {
    setOPending(true);
    try {
      const response = await axios.get(
        `${api}/main/all/outlet/${business_id}`,
        config
      );
      if (response) {
        setOPending(false);
        setOError(null);
        setOData(response.data.results);
        console.log(response.data.results);
      }
    } catch (err) {
      setOPending(false);
      setOError(err.message);
    }
  };
  const role = localStorage.getItem("role");
  const [details, setDetails] = useState(null);
  console.log(details);
  // GETTING ALL BUSINESSES
  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const {
    dispatch,
    token,
    businessData,
    secret_id,
    api,
    business,
    outletReset,
    outletDetails,
    filterPop,
  } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  console.log(outletReset);
  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");

  //
  const removeitem = () => {
    if (data) {
      setArr(data);
    }
    setShowRefund(false);
    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
    if (window.matchMedia("(max-width: 575px)").matches) {
      dashcontainer.style.height = "90vh";
    }
  };

  const removeItemMobile = () => {
    if (data) {
      setArr(data);
    }
    setRefundsMobile(false);
    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
  };

  const refundmax = () => {
    arr.map((val) => {
      if (parseInt(refundValue) < parseInt(val.amount)) {
        setRefundButton(true);
      }
      if (parseInt(refundValue) === parseInt(val.amount)) {
        setRefundButton(true);
      }
      if (parseInt(refundValue) > parseInt(val.amount)) {
        setRefundButton(false);
        setBtnCheck(true);
      }
    });

    if (parseInt(refundValue) === "") {
      setRefundButton(false);
    }
  };

  const refunds = () => {
    setShowRefund(true);
    setShowTransaction(false);
  };

  const refundsMob = () => {
    setRefundsMobile(true);
    setShowTransaction(false);
  };

  const getWhiteData = async () => {
    business(
      data,
      setData,
      setPending,
      setError,
      setOutletRreshSecretId,
      setArr,
      setBusinessPending,
      setFilterState,
      setToday,
      setDropFilter,
      setPagRes,
      setRollerPending,
      roll
    );
  };
  const [virtualpending, setVirtualPending] = useState(false);
  const [virtualResponse, setVirtualResponse] = useState(null);

  const getVirtualAccount = async () => {
    setVirtualPending(true);
    try {
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: secret_id },
        config
      );
      const businessresponse = await axios.get(
        api + "/main/all/business",
        config
      );

      if (businessresponse) {
        dispatch({
          type: "ADMIN",
          payload: businessresponse.data.results[0].admin,
        });
        dispatch({
          type: "BUSINESS_DATA",
          payload: businessresponse.data.results[0],
        });
      }

      setVirtualPending(false);
      setVirtualResponse(virtualaccount);
    } catch (err) {
      setVirtualPending(false);
    }
  };
  const [filterState, setFilterState] = useState(false);
  const [today, setToday] = useState("Today");
  const [dropFilter, setDropFilter] = useState(false);
  const [pagRes, setPagRes] = useState([]);

  useEffect(() => {
    business(
      data,
      setData,
      setPending,
      setError,
      setOutletRreshSecretId,
      setArr,
      setBusinessPending,
      setFilterState,
      setToday,
      setDropFilter,
      setPagRes
    );
    console.log(data);
  }, [trigger]);

  const removeCopy = () => {
    // setTimeout(() => {
    //   copy.current.classList.add("fade-out");
    // }, 1500);
    setTimeout(() => {
      setCopied(false);
      // copy.current.classList.remove("fade-out");
    }, 4500);
  };

  const showcopied = () => {
    if (!copied) {
      setCopied(true);
    }

    setTimeout(() => {
      setCopied(false);
      // copy.current.classList.remove("fade-out");
    }, 4100);
  };

  const uploadCAC = (e) => {
    if (e.target.files[0]) {
      setCac(e.target.files[0]);
    }
  };

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [outletPending, setOutletPending] = useState(false);
  const [outletError, setOutletError] = useState("");
  const [outletSuccess, setOutletSuccess] = useState("");
  const createOutlet = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setOutletPending(true);
    try {
      const outletresponse = await axios.post(
        api + "/main/create/outlet",
        { business_secret_id, outlet_name },
        config
      );
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: outletresponse.data.secret_id },
        config
      );

      if (virtualaccount) {
        setOutletPending(false);
        setOutletError(virtualaccount.data.Error);
        if (virtualaccount.data.Success) {
          setOutletSuccess(
            "outlet successfully added please return to home page to switch outlets"
          );
          setOutletName("");
        }
      }
    } catch (err) {
      setOutletPending(false);
      setOutletError("couldnt handle your request at the moment");
    } finally {
      setOutletPending(false);
    }
  };

  return (
    <>
      <div className="dashboard-home-container">
        {window.innerWidth > 900 && (
          <>
            {/* starting of create outlet */}

            {/* <div className="home-mobile-top">
              {businessData && !outletReset && (
                <div className="home-mobile-top-left">
                  <div className="name-circle">
                    <p> {businessData.name.substring(0, 1)} </p>
                  </div>
                  <div className="business-name">
                    <p>{businessData.name} </p>
                    {role === "BOSS" && (
                      <img
                        src="/assets/md.svg"
                        alt=""
                        onClick={() => {
                          dispatch({ type: "FILTER_POP_UP", payload: true });
                          getOutletList();
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {details && outletReset && (
                <div className="home-mobile-top-left">
                  <div className="name-circle">
                    <p> {details.name.substring(0, 1)} </p>
                  </div>
                  <div className="business-name">
                    <p>{details.name} </p>
                    {role === "BOSS" && (
                      <img
                        src="/assets/md.svg"
                        alt=""
                        onClick={() => {
                          dispatch({ type: "FILTER_POP_UP", payload: true });
                          getOutletList();
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              <div
                className="home-mobile-top-right"
                style={{ display: "flex", gap: "28.2px" }}
              >
                <img
                  src="/assets/refreshh.svg"
                  alt=""
                  onClick={getWhiteData}
                  ref={roll}
                />
                <img
                  src="/assets/cd.svg"
                  alt=""
                  onClick={() => dispatch({ type: "POP_UP", payload: true })}
                />
              </div>
            </div> */}
            {virtualResponse && virtualResponse.data.Error !== "" && (
              <ErrorMessage message={virtualResponse.data.Error} />
            )}
            {arr.length > 0 && showTransaction && (
              <div id="modalodd">
                <div className="dashboard-transaction-modal-heading">
                  <p>Transaction Details</p>
                </div>
                {arr &&
                  arr.map((arr, idx) => (
                    <div className="dashboard-modal-flex-container" key={idx}>
                      <div className="dashboard-modal-flex">
                        <p>Date and Time</p>
                        <p>{new Date(arr.date_created).toDateString()}</p>
                      </div>
                      <div className="dashboard-modal-flex">
                        <p>Name</p>
                        <p>{arr.payer_account_name.toLowerCase()}</p>
                      </div>
                      <div className="dashboard-modal-flex">
                        <p>Amount</p>
                        <p> &#x20A6;{parseInt(arr.amount).toLocaleString()}</p>
                      </div>
                      <div className="dashboard-modal-flex">
                        <p>Bank</p>
                        <p>{arr.payer_bank_name}</p>
                      </div>

                      <div className="dashboard-modal-flex">
                        <p>Transaction ID</p>
                        <p>{arr.public_id}</p>
                      </div>
                    </div>
                  ))}

                <div className="dashboard-modal-buttons">
                  <button onClick={() => removeitem()}> Cancel</button>
                </div>
              </div>
            )}

            <div className="dashboard-home-navbar">
              <div className="dashboard-home-navbar-left">
                {!search && (
                  <>
                    <p>Dashboard</p>
                    <p className="dash-home-sub">See all your activites</p>{" "}
                  </>
                )}
              </div>
              <div className="dashboard-copied" ref={copy}>
                {copied && (
                  <>
                    <Success message={`Copied to clipboard`} />
                  </>
                )}
              </div>
              {!search && <Hamburger />}
              <div className="dashboard-home-navbar-right">
                {!search && (
                  <img
                    src="/assets/search.svg"
                    alt="search"
                    className="searchmobile"
                    onClick={() => setSearch(true)}
                  />
                )}
                {search && (
                  <div
                    className="mobilesearchdiv"
                    style={{ transform: "translate(15px , 0px )" }}
                  >
                    <img
                      src="/assets/left.svg"
                      alt=""
                      onClick={() => {
                        setSearch(false);
                        setTerm("");
                      }}
                    />
                    <input
                      type="text"
                      className="inputmobile"
                      value={term}
                      onChange={(e) => setTerm(e.target.value)}
                    />
                  </div>
                )}
                {/* <img src="/assets/search.svg" alt="" className="search" />

            <input
              className="inputlap"
              type="text"
              placeholder="Search a name"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            /> */}

                {!search && (
                  <div
                    className="dashboard-home-navbar-right-image1"
                    onClick={getWhiteData}
                    ref={roll}
                  >
                    <img src="/assets/q-refresh.svg" alt="" />
                  </div>
                )}
                {/* <div className="dashboard-home-navbar-right-image2">
                    <img src="/assets/Warning.svg" alt="" />
                </div> */}
              </div>
            </div>
            {/* {virtualResponse && (
          <p style={{ transform: "translate(0px , 20px)", color: "red" }}>
            {virtualResponse.data.Error}
          </p>
        )} */}

            <div className="dashboard-home-accountDetails">
              {businessData && !outletReset && (
                <div className="dashboard-home-accountDetails-boxes">
                  <div className="dashboard-home-boxes-container">
                    <div className="dashboard-home-box-tag">
                      Account details
                    </div>
                    <div className="dashboard-home-accountDetails-details">
                      {businessData && (
                        <p>
                          {businessData.outlet.virtual_account.account_number}
                        </p>
                      )}
                      {businessData && (
                        <p>{businessData.outlet.virtual_account.bank_name}</p>
                      )}
                    </div>
                  </div>

                  {businessData &&
                    businessData.outlet.virtual_account.account_number !==
                      "None" &&
                    businessData.outlet.virtual_account.bank_name !==
                      "None" && (
                      <div className="dashboard-home-accountDetails-copy">
                        <CopyToClipboard
                          text={
                            businessData.outlet.virtual_account.account_number
                          }
                          onCopy={() => {
                            showcopied();
                          }}
                        >
                          <img
                            className="copy-icon"
                            src="/assets/copy.svg"
                            alt=""
                          />
                        </CopyToClipboard>
                      </div>
                    )}

                  {businessData &&
                    businessData.outlet.virtual_account.account_number ===
                      "None" &&
                    businessData.outlet.virtual_account.bank_name ===
                      "None" && (
                      <div
                        className="dashboard-home-accountDetails-copy"
                        onClick={() => getVirtualAccount()}
                      >
                        {!virtualpending && (
                          <img
                            style={{ width: "25px" }}
                            src="/assets/load.svg"
                          />
                        )}
                        {virtualpending && (
                          <img style={{ width: "25px" }} src="/assets/lo.svg" />
                        )}
                      </div>
                    )}
                </div>
              )}
              {outletDetails && outletReset && (
                <div className="dashboard-home-accountDetails-boxes">
                  <div className="dashboard-home-boxes-container">
                    <div className="dashboard-home-box-tag">
                      Account details
                    </div>
                    <div className="dashboard-home-accountDetails-details">
                      {outletDetails && <p>{outletDetails.accNumber}</p>}
                      {outletDetails && <p>{outletDetails.bankName}</p>}
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="dashboard-home-accountDetails-boxes">
            <div className="dashboard-home-boxes-container">
              <div className="dashboard-home-accountDetails-image">
                <img className="dashImg" src="/assets/balanc.svg" alt="" />
              </div>
              <div className="dashboard-home-accountDetails-details">
                <p>Current Balance</p>
                {details && (
                  <p>
                    {" "}
                    &#x20A6;{" "}
                    {parseInt(
                      businessData.outlet.virtual_account.total
                    ).toLocaleString()}
                  </p>
                )}
              </div>
            </div>
          </div> */}

              <div className="dashboard-home-accountDetails-boxes">
                <div className="dashboard-home-boxes-container">
                  <div className="dashboard-home-box-tag dash-grey">
                    Transactions Verified
                  </div>

                  <div className="dashboard-home-accountDetails-details">
                    {businessData && <p>{businessData.total_transactions}</p>}
                    {outletDetails && <p>{outletDetails.processed}</p>}
                   
                  </div>
                </div>
              </div>
              <div className="dashboard-home-accountDetails-boxes">
                <div className="dashboard-home-boxes-container">
                  <div className="dashboard-home-box-tag dash-grey">
                    Total Verified
                  </div>
                  <div className="dashboard-home-accountDetails-details">
                    {businessData && (
                      <p>
                        &#x20A6;
                        {parseInt(businessData.total_amount).toLocaleString()}
                      </p>
                    )}
                    {outletDetails && <p> &#x20A6;{outletDetails.verified}</p>}
                    <p>100% Payout</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard-home-transaction-nav">
              <div className="dashboard-home-transaction-nav-heading">
                <p>Recent transactions</p>
              </div>
              {/* <p className="home-text-none">Displaying recent transactions</p> */}
            </div>

            <div className="dashboard-home-transactions">
              <div className="dashboard-home-transaction-header-flex">
                <p>Date and Time</p>
                <p>Name</p>
                <p>Amount</p>
                <p>Bank</p>
                <p>Transaction ID</p>
                {/* <p>Account Number</p> */}
              </div>

              <div className="preload">
                {error && (
                  <p className="error">There was an error getting your data </p>
                )}
                {pending && (
                  <img
                    className="pending"
                    src="/assets/spin.svg"
                    alt="loading..."
                  />
                )}
              </div>
              <div className="transactions-scroll">
                {!pending &&
                  data &&
                  data
                    .slice(0, 6)

                    .map((val, idx) => (
                      <div key={idx}>
                        <Fade duration={250}>
                          {" "}
                          <div
                            className="dashboard-home-fetch-flex"
                            onClick={() => addModal(val)}
                          >
                            <div className="dashboard-home-transactions-list" style={{display: 'flex' , gap: '8px'}}>
                              <DateComponent
                                date_created={val.date_created}
                                seen={val.seen}
                              />
                                {val.seen === "False" ? (
                                  <span
                                    style={{
                                      position: 'absolute',
                                      alignSelf: 'center',
                                      color: "white",
                                      backgroundColor: "#4BB543",
                                      width : "50px",
                                      height: '29px', 
                                      fontSize: '14px',
                                      display: 'grid' ,
                                      placeItems:'center',
                                      borderRadius: "20px",
                                    }}
                                  >
                                    New
                                  </span>
                                ) : (
                                  <></>
                                )}
                            </div>

                            <div className="dashboard-home-transactions-list">
                              {fullName && <p>{val.payer_account_name}</p>}
                              {
                                <p>
                                  {val.payer_account_name.toLocaleLowerCase()}
                                </p>
                              }
                            </div>
                            <div className="dashboard-home-transactions-list">
                              <p>
                                <span
                                  className="ellipsis"
                                  style={{ maxWidth: "14vw" }}
                                >
                                  &#x20A6;
                                  {parseInt(val.amount).toLocaleString()}
                                </span>
                              </p>
                            </div>
                            <div className="dashboard-home-transactions-list">
                              <p>
                                <span
                                  className="ellipsis"
                                  style={{ maxWidth: "14vw" }}
                                >
                                  {val.payer_bank_name}
                                </span>
                              </p>
                            </div>
                            <div className="dashboard-home-transactions-list">
                              <p>
                                {" "}
                                <span
                                  className="ellipsis"
                                  style={{ maxWidth: "14vw" }}
                                >
                                  {val.public_id}
                                </span>
                              </p>
                            </div>
                            {/* <div className="dashboard-home-transactions-list">
                    
                
                        <p>
                         
                        </p>
                    
                </div> */}
                          </div>
                        </Fade>
                      </div>
                    ))}

                {data && data.length === 0 && !pending && (
                  <p className="info-center">You Have No Transactions </p>
                )}
              </div>
            </div>
            {showModal && (
              <div className="cover" onClick={() => removeitem()}></div>
            )}

            {rollerPending && <div className="refreshbar"></div>}
            {rollerPending && <div className="refreshoverlay"></div>}
          </>
        )}
        {window.innerWidth < 900 && (
          <HomeMobile
            data={data}
            pending={pending}
            addModal={addModal}
            arr={arr}
            removeitem={removeitem}
            getOutletList={getOutletList}
            Odata={Odata}
            Opending={Opending}
            Oerror={Oerror}
            getTransactions={getTransactions}
          />
        )}
      </div>
    </>
  );
};

export default Home;
