import { Fade } from "react-reveal";
import { useState, useEffect } from "react";
import axios from "axios";
import "./Payouts.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import Success from "../../Success/Success";
import DateComponent from "../DateComponent/DateComponent";
import { QevoltData } from "../../QevoltData/QevoltData";
import PayoutsMobile from "./PayoutsMobile/PayoutsMobile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CsvDownloaderComponent from "../CsvDownloader/CsvDownloader";

const Payouts = () => {
  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");
  const [businessPending, setBusinessPending] = useState(false);
  const { dispatch, token, businessData, business, api } = useAuthContext();
  const [term, setTerm] = useState("");
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [arr, setArr] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [password, setPassword] = useState("");
  const { livecode } = QevoltData();

  const [accNumber, setAccNumber] = useState();

  let now = new Date();
  const [filterState, setFilterState] = useState(false);
  const [filterCount, setFilterCount] = useState("");
  const [filterResults, setFilterResults] = useState(null);

  const [sbtn, setsbtn] = useState(false);
  const [tbtn, settbtn] = useState(false);
  const [dataOne, setDataOne] = useState(null);

  const filter7 = () => {
    setsbtn(true);
    settbtn(false);
  };
  const filter30 = () => {
    setsbtn(false);
    settbtn(true);
  };

  const addModal = (payVal) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.ref === payVal.ref;
      });
    });
    // setArr((prev) => {
    //   return prev.filter((val) => {
    //     return val.secret_id === event.secret_id;
    //   });
    // });
    setShowModal(true);
    setShowPayout(true);
  };

  const checkacc = () => {
    livecode.map((val) => {
      if (businessData && val.bankCode === businessData.bank) {
        setAccNumber(val.bankName);
      }
    });
  };

  useEffect(() => {
    checkacc();
  }, []);

  const removeitem = () => {
    setShowModal(false);

    if (data) {
      setArr(data);
    }
    setShowPayout(false);
  };

  const Withdraw = () => {
    setShowModal(true);
  };

  const outlet_secret_id = localStorage.getItem("outlet_secret_id");

  const url = `${api}/main/view/withdraw/${outlet_secret_id}`;

  const [withdrawPending, setWithdrawPending] = useState(false);
  const [withdraError, setWithdrawError] = useState(false);

  const [payoutMessage, setPayoutMessage] = useState(null);
  const [payoutError, setPayoutError] = useState("");

  const withdraw = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");

    const form = { outlet_secret_id, business_secret_id, password };

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    setWithdrawPending(true);

    try {
      const response = await axios.post(
        api + "/main/create/withdraw",
        form,
        config
      );

      if (response) {
        setPayoutMessage(response.data.Success);
        setPayoutError(response.data.Error);
        setWithdrawPending(false);
      }
    } catch (err) {
      setWithdrawError("couldnt withdraw at the moment");
      setWithdrawPending(false);
      setPayoutError("couldnt withdraw at the moment");
    }
  };
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [payoutsData, setPayoutsData] = useState(null);
  const [csvModal, setCsvModal] = useState(false);
  const getData = async () => {
    setPending(true);
    try {
      const response = await axios.get(url, config);
      if (response) {
        setPending(false);
        setError(null);
      }
      console.log(response);
      setData(response.data.results);
      setDataOne(response.data.results);
      setArr(response.data.results);
      setPagRes(response.data);
      setPayoutsData(response.data);
      console.log(response.data.results);

      if (data) {
        setArr(data);
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };

  const filterNextPagination = async (start_date , end_date) => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    const form = { start_date, end_date };
    try {
      const nextpagination = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`, form ,
        config
      );
      console.log(nextpagination)
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
        setPaginationLink(nextpagination.data.previous);
      }
    } catch (err) {}
  };
  const filterPreviousPagination = async (start_date , end_date) => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
     const form = { start_date, end_date };
    try {
      const nextpagination = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationPrev}`, form, 
        config
      );
      console.log(nextpagination)
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        setPaginationLink(nextpagination.data.previous);
      }
    } catch (err) {}
  };  
  

  useEffect(() => {
    setPaginationCount(2);
    seetPaginationPrev(0);
    setPaginationFirst(1);
    setPaginationSecond(10);
    getData();

    // business(data , setData , setPending ,setError , setOutletRreshSecretId , setArr , setBusinessPending ,setFilterState, setToday, setDropFilter , setPagRes);
  }, [url]);

  const [pagRes, setPagRes] = useState([]);

  const [paginationLink, setPaginationLink] = useState("");

  const [paginationCount, setPaginationCount] = useState(2);
  const [paginationPrev, seetPaginationPrev] = useState(0);
  const [paginationFirst, setPaginationFirst] = useState(1);
  const [paginationSecond, setPaginationSecond] = useState(10);

  const [panLink, setPanLink] = useState("");
  const paginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/withdraw/${outlet_secret_id}?page=${paginationCount}`,
        config
      );
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setArr(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
        setPaginationLink(nextpagination.data.previous);
      }
    } catch (err) {}
  };

  const paginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/withdraw/${outlet_secret_id}?page=${paginationPrev}`,
        config
      );

      if (nextpagination) {
        setPending(false);
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        setData(nextpagination.data.results);
        setArr(nextpagination.data.results);
      }
    } catch (err) {}
  };

  const [dropFilter, setDropFilter] = useState(false);
  const [today, setToday] = useState("Today");
  const [sDays, setSDays] = useState("Last 7 Days");
  const [fDays, setFDays] = useState("Last 14 Days");
  const [tDays, setTDays] = useState("Last 30 Days");
  const [query, setQuery] = useState("");

  const [Radio1, setRadio1] = useState(false);
  const [Radio2, setRadio2] = useState(false);
  const [Radio3, setRadio3] = useState(false);
  const [Radio4, setRadio4] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateChanged, setDateChanged] = useState(false);
  const [showPayout, setShowPayout] = useState(false);
  const [search, setSearch] = useState(false);

  //search states for pagination
  const [searchResults, setSearchResults] = useState(null);
  const [searchPagi, setSearchPagi] = useState(false);
  const [searchPagCount, setSearchPagCount] = useState(2);
  const [searchPagPrev, setSearchPagPrev] = useState(0);
  const [searchPaginationFirst, setSearchPaginationFirst] = useState(1);
  const [searchPaginationSecond, setSearchPaginationSecond] = useState(10);

  const searchTransactions = async (event) => {
    event.preventDefault();
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    setSearchPagi(true);
    if (query === "") {
      setSearchPagi(false);
    }
    setSearchPagCount(2);
    setSearchPagPrev(0);
    setSearchPaginationFirst(1);
    setSearchPaginationSecond(10);
    try {
      const searchresponse = await axios.post(
        `${api}/main/search/withdraw/${outlet_secret_id}?query=${query} `,
        {},
        config
      );

      setData(searchresponse.data.results);
      setArr(searchresponse.data.results);
      setSearchResults(searchresponse.data);
      if (searchresponse) {
        setPending(false);
        console.log(searchresponse.data.results);
      }
    } catch (err) {}
  };

  const showRadio1 = () => {
    setRadio1(true);
    setRadio2(false);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio2 = () => {
    setRadio1(false);
    setRadio2(true);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio3 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(true);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio4 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(false);
    setRadio4(true);
    setDropFilter(false);
  };

  const filterDate = () => {
    setsbtn(false);
    settbtn(false);
  };

  const filterTransactions = async (start_date, end_date, change) => {
    setPending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setToday(change);
    const form = { start_date, end_date };

    try {
      const response = await axios.post(
        `${api}/main/filter/withdraw/${outlet_secret_id}`,
        form,
        config
      );
      if (response) {
        setPending(false);

        setData(response.data.results);
        setFilterResults(response.data)
        setFilterCount(response.data.count)
        setFilterState(true)
      }
    } catch (err) {}
  };

  const rn = new Date().toISOString().substring(0, 10);

  const dateModded = () => {
    if (endDate.toISOString().substring(0, 10) !== rn) {
      setDateChanged(true);
    } else {
      setDateChanged(false);
    }
  };
  const startDateModded = () => {
    if (startDate.toISOString().substring(0, 10) !== rn) {
      setDateChanged(true);
    } else {
      setDateChanged(false);
    }
  };

  //day before
  const startinputDate = new Date(now.toISOString().substring(0, 10));
  const dayBeforeDate = new Date(
    startinputDate.getTime() + 24 * 60 * 60 * 1000
  );
  const dayBeforeDateString = dayBeforeDate.toISOString().slice(0, 10);

  //day after
  const endinputDate1 = new Date(
    new Date(now.setDate(now.getDate() - 7)).toISOString().substring(0, 10)
  );
  const dayAfterDate1 = new Date(endinputDate1.getTime() - 24 * 60 * 60 * 1000);
  const dayAfterString1 = dayAfterDate1.toISOString().slice(0, 10);

  const endinputDate = new Date(
    new Date(now.setDate(now.getDate() - 30)).toISOString().substring(0, 10)
  );
  const dayAfterDate = new Date(endinputDate.getTime() - 24 * 60 * 60 * 1000);
  const dayAfterString = dayAfterDate.toISOString().slice(0, 10);

  //day before
  const startinputDate2 = new Date(startDate.toISOString().substring(0, 10));
  const dayBeforeDate2 = new Date(
    startinputDate2.getTime() - 24 * 60 * 60 * 1000
  );
  const dayBeforeDateString2 = dayBeforeDate2.toISOString().slice(0, 10);

  //day after
  const endinputDate2 = new Date(endDate.toISOString().substring(0, 10));
  const dayAfterDate2 = new Date(endinputDate2.getTime() + 24 * 60 * 60 * 1000);
  const dayAfterString2 = dayAfterDate2.toISOString().slice(0, 10);

  useEffect(() => {
    dateModded();
  }, [endDate]);

  useEffect(() => {
    startDateModded();
  }, [startDate]);



  return (
    <>
      <Fade duration={250}>
        <div className="dashboard-home-container">
          {window.innerWidth > 768 && (
            <>
              {payoutMessage !== null && <Success message={payoutMessage} />}
              {payoutError !== "" && <ErrorMessage message={payoutError} />}

              {showPayout && (
                <div id="modalodd">
                  <div className="dashboard-transaction-modal-heading">
                    <p>Payout Details</p>
                  </div>
                  {arr &&
                    arr.map((arr, idx) => (
                      <div className="dashboard-modal-flex-container" key={idx}>
                        <div className="dashboard-modal-flex">
                          <p>Date and Time</p>
                          <p>{new Date(arr.date_created).toDateString()}</p>
                        </div>
                        <div className="dashboard-modal-flex">
                          <p>Name</p>
                          <p>{arr.outlet.name}</p>
                        </div>
                        <div className="dashboard-modal-flex">
                          <p>Amount</p>
                          <p>
                            {" "}
                            &#x20A6;{parseInt(arr.amount).toLocaleString()}
                          </p>
                        </div>
                        <div className="dashboard-modal-flex">
                          <p>Fee</p>
                          <p> &#x20A6;{parseInt(arr.fee).toLocaleString()}</p>
                        </div>
                        {/* <div className="dashboard-modal-flex">
                          <p>Bank</p>
                          <p>{accNumber}</p>
                        </div> */}
                      </div>
                    ))}

                  <div className="dashboard-modal-buttons">
                    <button onClick={() => removeitem()}> Cancel</button>
                  </div>
                </div>
              )}

              {csvModal && (
                <CsvDownloaderComponent
                  type={"withdraw"}
                  setCsvModal={setCsvModal}
                />
              )}

              <div className="dashboard-payouts-navbar">
                <div className="dashboard-payouts-navbar-left">
                  <p>Payouts</p>
                  <p>We settle your account immediately</p>
                </div>
                <div className="dashboard-payouts-navbar-right">
                  <div style={{ display: "flex", gap: "30px" }}>
                    <form onSubmit={searchTransactions}>
                      <div className="dashboard-transactions-navbar-right">
                        <img
                          src="/assets/search.svg"
                          alt=""
                          className="search"
                          style={{ top: "13px" }}
                        />

                        <input
                          type="text"
                          placeholder="Search payouts"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="transactions-filter">
                <div className="transactions-filter-flex">
                  <div className="filter-selectors">
                    <div className="transactions-filter-heading">Filter by</div>
                    <div
                      className="transactions-filter-selection"
                      style={{
                        backgroundColor: sbtn ? "#121212" : "#F8F8F8",
                        color: sbtn ? "#FFF" : "#757575",
                      }}
                      onClick={() => {
                        filterTransactions(
                          dayAfterString1,
                          dayBeforeDateString,
                          sDays
                        );
                        filter7();
                      }}
                    >
                      Last 7 days
                    </div>
                    <div
                      className="transactions-filter-selection"
                      style={{
                        backgroundColor: tbtn ? "#121212" : "#F8F8F8",
                        color: tbtn ? "#FFF" : "#757575",
                      }}
                      onClick={() => {
                        filterTransactions(
                          dayAfterString,
                          dayBeforeDateString,
                          tDays
                        );
                        filter30();
                      }}
                    >
                      Last 30 days
                    </div>

                    <div className="or">or</div>

                    <form action="" className="filter-date-form">
                      <DatePicker
                        className="filter-date-input"
                        placeholder="Start date"
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                      />

                      <div className="to">to</div>
                      <DatePicker
                        className="filter-date-input"
                        placeholder="End date"
                        selected={endDate}
                        onChange={(date: Date) => {
                          setEndDate(date);
                          dateModded();
                        }}
                      />
                    </form>
                  </div>

                  <div className="filter-right">
                    <div
                      className="filter-apply"
                      style={{
                        backgroundColor: dateChanged ? "#121212" : "#F8F8F8",
                        color: dateChanged ? "#fff" : "#757575",
                        pointerEvents: dateChanged ? "all" : "none",
                      }}
                      onClick={() => {
                        filterTransactions(
                          dayBeforeDateString2,
                          dayAfterString2
                        );
                        filterDate();
                      }}
                    >
                      Apply date
                    </div>
                    <div
                      className="filter-clear"
                      onClick={() => {
                        setsbtn(false);
                        settbtn(false);
                        getData();
                      }}
                    >
                      Clear filters
                    </div>
                    <div
                      className="filter-apply"
                      style={{
                        marginRight: "30px",
                        backgroundColor: "#121212",
                        color: "#fff",
                        pointerEvents: "all",
                        marginRight: "0px",
                      }}
                      onClick={() => {
                        setCsvModal(true);
                      }}
                    >
                      Download Csv
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="dashboard-transactions-transactions"
                style={{ height: "75vh" }}
              >
                <div className="dashboard-payouts-transaction-header-flex">
                  <p>Date and Time</p>

                  <p>Name</p>

                  <p>Amount</p>

                  {/* <p>Bank</p> */}

                  <p>Fee</p>

                  <p>Status</p>
                </div>
                <div className="preload">
                  {error && (
                    <p className="error">
                      There was an error getting your data{" "}
                    </p>
                  )}
                  {pending && (
                    <img
                      src="/assets/spin.svg"
                      alt="loading..."
                      className="pending"
                    />
                  )}
                </div>
                {!pending &&
                  data &&
                  data.map((val, idx) => (
                    <div key={idx}>
                      <Fade duration={250}>
                        <div
                          className="dashboard-payouts-fetch-flex "
                          onClick={() => addModal(val)}
                        >
                          <div className="dashboard-payouts-transactions-list ellipsis">
                            <DateComponent
                              date_created={val.date_created}
                              seen={val.seen}
                            />
                          </div>
                          <div className="dashboard-payouts-transactions-list">
                            <p>
                              <span
                                className="ellipsis"
                                style={{ width: "14vw" }}
                              >
                                {val.outlet.name}
                              </span>
                            </p>
                          </div>
                          <div className="dashboard-payouts-transactions-list">
                            <p>
                              <span
                                className="ellipsis"
                                style={{ width: "14vw" }}
                              >
                                &#x20A6;{parseInt(val.amount).toLocaleString()}
                              </span>
                            </p>
                          </div>
                          {/* <div className="dashboard-payouts-transactions-list">
                            {livecode.map((va, idx) => (
                              <>
                                {va.bankCode === val.pay_to_bank_code && (
                                  <p>
                                    <span
                                      className="ellipsis"
                                      style={{ width: "14vw" }}
                                    >
                                      {va.bankName}
                                    </span>
                                  </p>
                                )}
                              </>
                            ))}
                          </div> */}
                          <div className="dashboard-payouts-transactions-list">
                            <p>
                              <span
                                className="ellipsis"
                                style={{ width: "14vw" }}
                              >
                                &#x20A6;{parseInt(val.fee).toLocaleString()}
                              </span>
                            </p>
                          </div>
                          <div className="dashboard-payouts-transactions-list">
                            <p>
                              <h4
                                className="status"
                                style={
                                  val.status === true
                                    ? {
                                        color: "#4BB543",
                                        background: "#F2FAF1",
                                        width: "50px",
                                      }
                                    : val.status === false
                                    ? {
                                        color: "#757575",
                                        background: "#f8f8f8",
                                        width: "62px",
                                      }
                                    : {
                                        color: "#757575",
                                        background: "#f8f8f8",
                                        width: "70px",
                                      }
                                }
                              >
                                {val.status === null && "Pending"}
                                {val.status === true && "Paid"}
                                {val.status === false && "Failed"}
                              </h4>
                            </p>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  ))}
                {data && data.length === 0 && !pending && (
                  <p className="info-center">You Have No Transactions </p>
                )}
              </div>

              {!filterState  && <div className="dashboard-transactions-pagination">
                {paginationPrev !== 0 && !pending && (
                  <div
                    className="previous"
                    onClick={() => {
                      paginationPrevious();
                    }}
                  >
                    <img src="/assets/right-arrow.svg" alt="" />
                  </div>
                )}
                {paginationPrev === 0 && !pending && (
                  <div
                    className="previous"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <img src="/assets/right-arrow.svg" alt="" />
                  </div>
                )}

                {pending && (
                  <div
                    className="previous"
                    style={{
                      pointerEvents: "none",
                    }}
                  >
                    <img src="/assets/right-arrow.svg" alt="" />
                  </div>
                )}
                {payoutsData && (
                  <div className="dashboard-transactions-pagination-text">
                    <p>
                      Results: {paginationFirst} -{" "}
                      {paginationSecond > +payoutsData.count
                        ? `${payoutsData.count}`
                        : ` ${paginationSecond}`}{" "}
                      of {payoutsData.count}
                    </p>
                  </div>
                )}

                {businessData &&
                  paginationCount <= Math.ceil(pagRes.count / 10) &&
                  !pending && (
                    <div
                      className="next"
                      onClick={() => {
                        paginationNext();
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" style={{}} />
                    </div>
                  )}
                {pending && (
                  <div className="next">
                    <img
                      src="/assets/right-arrow.svg"
                      alt=""
                      style={{
                        pointerEvents: "none",
                      }}
                    />
                  </div>
                )}
                {businessData &&
                  paginationCount > Math.ceil(pagRes.count / 10) &&
                  !pending && (
                    <div className="next">
                      <img
                        src="/assets/right-arrow.svg"
                        alt=""
                        style={{
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  )}
              </div>}

              {filterState && (
                <div className="dashboard-transactions-pagination">
                  {paginationPrev !== 0 && !pending && (
                    <div
                      className="previous"
                      onClick={() => {
                       sbtn  ? filterPreviousPagination(dayAfterString1 , dayBeforeDateString ):  filterPreviousPagination(dayAfterString , dayBeforeDateString );
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {paginationPrev === 0 && !pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}

                  {pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {filterResults && (
                    <div className="dashboard-transactions-pagination-text">
                      <p>
                        Results: {paginationFirst} -{" "}
                        {paginationSecond > +filterCount
                          ? `${filterCount}`
                          : ` ${paginationSecond}`}{" "}
                        of {filterCount}
                      </p>
                    </div>
                  )}

                  {filterResults &&
                    paginationCount <= Math.ceil(filterCount / 10) &&
                    !pending && (
                      <div
                        className="next"
                        onClick={() => {
                          sbtn  ? filterNextPagination(dayAfterString1 , dayBeforeDateString ):  filterNextPagination(dayAfterString , dayBeforeDateString );
                        }}
                      >
                        <img src="/assets/right-arrow.svg" alt="" style={{}} />
                      </div>
                    )}
                  {pending && (
                    <div className="next">
                      <img
                        src="/assets/right-arrow.svg"
                        alt=""
                        style={{
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }}
                      />
                    </div>
                  )}
                  {filterResults &&
                    paginationCount > Math.ceil(filterCount / 10) &&
                    !pending && (
                      <div className="next">
                        <img
                          src="/assets/right-arrow.svg"
                          alt=""
                          style={{
                            pointerEvents: "none",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                    )}
                </div>
              )}

              
              {showModal && (
                <div className="cover" onClick={() => removeitem()}></div>
              )}
              {csvModal && (
                <div className="cover" onClick={() => setCsvModal(false)}></div>
              )}
            </>
          )}
          {window.innerWidth < 768 && (
            <PayoutsMobile
              data={data}
              pending={pending}
              addModal={addModal}
              arr={arr}
              removeitem={removeitem}
              query={query}
              setQuery={setQuery}
              sDays={sDays}
              tDays={tDays}
              showRadio2={showRadio2}
              showRadio4={showRadio4}
              filterTransactions={filterTransactions}
              Radio2={Radio2}
              Radio4={Radio4}
            />
          )}
        </div>
      </Fade>
    </>
  );
};

export default Payouts;
