import './RefundsId.css'
import { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Fade } from 'react-reveal'
import axios from 'axios'
const RefundsId = () => {
  const { id} = useParams()
  const [data,setData] = useState(null)
const [pending,setPending] = useState(false)
const [error,setError] = useState(null)
  const [arr,setArr] = useState([])
  const [showTransaction,setShowTransaction] = useState(false)
  const [showModal,setShowModal] = useState(false)
  const addModal = (event)=>{
          
    setArr((prev)=>{
      return prev.filter((val)=>{
        return val.id === event.id
      })
    })
    
    setShowModal(true)
  
    setShowTransaction(true)
  
  
  }
  
  const removeitem  =()=>{
    if(data){
        setArr(data)
    }
  
    arr.splice(0,0)
    setShowModal(false)
    setShowTransaction(false)
  }
  const url = "https://my-json-server.typicode.com/Shapati/organisationdb/organizations/"+id
  console.log(data)

  const getData = async() =>{
   
    setPending(true)
    try{
        const response = await axios.get(url)
        if(response){
          setPending(false)
            setError(null)
           
        }
     
            setData(response.data.refund)
            setArr(response.data.refund)
            console.log(response.data)
        
    }catch(err){
        
        setPending(false)
        setError(err.message)
        console.warn(err)
       
    }
    
  }
  useEffect(()=>{
    getData()   
  },[url])
   
  console.log(arr)

  return (
    <div>


          <div className="dashboard-payouts-transactions" >

          {showTransaction && <div className="modal" data-aos="fade-up" >
                <div className="dashboard-transaction-modal-heading" >
                    <p>Transaction Details</p>
                </div>
                {arr && arr.map((val,idx)=>(
                    
                        
                        <div  className='dashboard-modal-flex-container' key={idx}>
                            <div className="dashboard-modal-flex">
                            <p>Date and Time</p>
                            <p>{val.dateandtime}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Name</p>
                            <p>{val.name}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Amount</p>
                            <p> &#x20A6;{val.amount}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Bank</p>
                            <p>{val.bank}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Account Number</p>
                            <p>{val.account}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Transaction ID</p>
                            <p>{val.amount}</p>
                            </div>  
                            </div>
                   
                       
               
              
                   
                ))}
                

                <div className="dashboard-modal-buttons">
                    <button onClick={()=>removeitem()} > Cancel</button>
                    
                </div>
                
                </div>
                }

      
      <div className="dashboard-payouts-transaction-header-flex">
          <p>Date and Time</p>
          <p>Name</p>
          <p>Amount</p>
          <p>Bank</p>
          <p>Account Number</p>
      </div>
      

{data && data.map((val,idx)=>(


          
        
 <Fade duration={250}><div className='dashboard-refunds-fetch-flex' onClick={()=>addModal(val)}  key={idx}>
        
 <div className="dashboard-refunds-transactions-list">

 
 <p>{val.dateandtime}</p>
     
 </div>
 <div className="dashboard-refunds-transactions-list">
    
     
         <p>
         {val.name}
           
         </p>
     
 </div>
 <div className="dashboard-refunds-transactions-list">

         <p>

         &#x20A6;{val.amount}     

         </p>
     
 </div>
 <div className="dashboard-refunds-transactions-list">
     
 
         <p>
             
             {val.bank}
         </p>
     
 </div>
 <div className="dashboard-refunds-transactions-list">
     
 
         <p>
             {val.account}
         </p>
     
 </div>
 </div>

 

 </Fade>



 ))}  
      

    </div>
    {showModal && <div className="cover" onClick={()=>removeitem()}>
            
            </div>}

            <div className='preload'>
            
            {error && <p className='error'>There was an error getting your data </p> }
            {pending && <img src='/assets/roller.svg' alt='loading...' className='pending' />}
         
           
          </div>
</div>
  )
}

export default RefundsId