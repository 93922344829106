import React from 'react'
import './QevoltLabel.css'
const QevoltLabel = ({text , style }) => {
  return (

        <label className='q-label'>{text}</label>
    
  )
}

export default QevoltLabel