import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./OrganisationId.css";
import TransactionsId from "./TransactionsId/TransactionsId";
import RefundsId from "./RefundsId/RefundsId";
import PayoutsId from "./PayoutsId/PayoutsId";
import MembersId from "./MembersId/MembersId";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import ExtraId from "./ExtraId/ExtraId";

const OrganisationId = () => {
  const access = localStorage.getItem("access");

  const [data, setData] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [arr, setArr] = useState([]);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [transactionShow, setTransactionShow] = useState(false);
  const [refundsShow, setRefundsShow] = useState(false);
  const [payoutsShow, setPayoutsShow] = useState(false);
  const [membersShow, setMembersShow] = useState(true);
  const [extrashow, setExtraShow] = useState(false);
  const business_id = localStorage.getItem("business_secret_id");
  let navigate = useNavigate();
  const { token, api } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const addModal = (event) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.id === event.id;
      });
    });

    setShowModal(true);

    setShowTransaction(true);
  };

  const removeitem = () => {
    if (data) {
      setArr(data);
    }

    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
  };

  const showTransactionsId = () => {
    setTransactionShow(true);
    setRefundsShow(false);
    setPayoutsShow(false);
    setMembersShow(false);
    setExtraShow(false);
  };
  const showRefundsId = () => {
    setTransactionShow(false);
    setRefundsShow(true);
    setPayoutsShow(false);
    setMembersShow(false);
    setExtraShow(false);
  };
  const showPayoutsId = () => {
    setTransactionShow(false);
    setRefundsShow(false);
    setPayoutsShow(true);
    setExtraShow(false);
    setMembersShow(false);
  };
  const showMembersId = () => {
    setTransactionShow(false);
    setRefundsShow(false);
    setPayoutsShow(false);
    setExtraShow(false);
    setMembersShow(true);
  };
  const showExtraId = () => {
    setTransactionShow(false);
    setRefundsShow(false);
    setPayoutsShow(false);
    setExtraShow(true);
    setMembersShow(false);
  };

  const url = `${api}/main/all/outlet/${business_id}`;

  const getData = async () => {
    setPending(true);

    try {
      const response = await axios.get(url, config);
      if (response.status === 200) {
        setPending(false);
        setError(null);
        setData(response.data.results);

        // data.map((val)=>{
        //   setConditionRole(val.role)
        // })
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };

  const conditionRole = localStorage.getItem("role");
  let outlet_name = localStorage.getItem("outlet_name");
  let outlet_public_id = localStorage.getItem("outlet_public_id");
  // useEffect(()=>{
  //   // getData()
  // },[])
  return (
    <Fade duration={250}>
      <div className="orgranisationId">
        <div className="dashboard-organisationid-nav">
          <div className="dashboard-organisationid-navbar-left">
            <div className="organisationid-back" onClick={() => navigate(-1)}>
              <img src="/assets/vuesax/linear/arrow-left.svg" alt="" />
            </div>
            <div className="organisation-branch">
              <div className="organisation-branch-name">{outlet_name}</div>
              <div className="organisation-branch-id">{outlet_public_id}</div>
            </div>
          </div>
        </div>

        <div className="orgranisationId-line-container">
          <div className="organisationId-line-items">
            {access === "False" && (
              <div className="organisation-line-box">
                <p
                  onClick={showTransactionsId}
                  style={
                    transactionShow
                      ? { color: "#8F00FF" }
                      : { color: "#c4c4c4" }
                  }
                >
                  Transactions
                </p>
                {transactionShow && <div className="organisationId-line"></div>}
              </div>
            )}
            {/* <div className="organisation-line-box">
            <p onClick={showRefundsId} style={ refundsShow ?{color: '#8F00FF'}: {color: '#c4c4c4'}}>Refunds</p> 
            {refundsShow && <div className="organisationId-line" ></div> }
          </div> */}
            {conditionRole === "BOSS" && access === "False" && (
              <div className="organisation-line-box">
                <p
                  onClick={showPayoutsId}
                  style={
                    payoutsShow ? { color: "#8F00FF" } : { color: "#c4c4c4" }
                  }
                >
                  Payout
                </p>
                {payoutsShow && <div className="organisationId-line"></div>}
              </div>
            )}
            {conditionRole === "MAGR" && access === "False" && (
              <div className="organisation-line-box">
                <p
                  onClick={showPayoutsId}
                  style={
                    payoutsShow ? { color: "#8F00FF" } : { color: "#c4c4c4" }
                  }
                >
                  Payout
                </p>
                {payoutsShow && <div className="organisationId-line"></div>}
              </div>
            )}
            {conditionRole === "BOSS" && (
              <div className="organisation-line-box">
                <p
                  onClick={showMembersId}
                  style={
                    membersShow ? { color: "#8F00FF" } : { color: "#c4c4c4" }
                  }
                >
                  Members
                </p>
                {membersShow && <div className="organisationId-line"></div>}
              </div>
            )}
            {conditionRole === "MAGR" && (
              <div className="organisation-line-box">
                <p
                  onClick={showMembersId}
                  style={
                    membersShow ? { color: "#8F00FF" } : { color: "#c4c4c4" }
                  }
                >
                  Members
                </p>
                {membersShow && <div className="organisationId-line"></div>}
              </div>
            )}
            {/* { access === "False" && <div className="organisation-line-box" >
            <p onClick={showExtraId} style={ extrashow ?{color: '#8F00FF'}: {color: '#c4c4c4'}}>Extra</p>
            { extrashow && <div className="organisationId-line"></div>}
          </div> } */}
          </div>
        </div>
        <div className="organisations-border-bottom"></div>
        <div className="organisationid-states">
          <div className="">
            {transactionShow && <TransactionsId />}
            {refundsShow && (
              <RefundsId
                removeitem={removeitem}
                showTransaction={showTransaction}
                arr={arr}
                showModal={showModal}
                addModal={addModal}
              />
            )}
            {conditionRole === "BOSS" && payoutsShow && <PayoutsId />}
            {conditionRole === "MAGR" && payoutsShow && <PayoutsId />}
            {membersShow && conditionRole === "BOSS" && (
              <MembersId roleposition={conditionRole} />
            )}
            {membersShow && conditionRole === "MAGR" && (
              <MembersId roleposition={conditionRole} />
            )}
            {extrashow && <ExtraId />}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default OrganisationId;
