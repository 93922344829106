import { useState, useEffect, useRef } from "react";
import "./Payouts.css";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";
import axios from "axios";
import Success from "../../../../Success/Success";
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage";
import { QevoltData } from "../../../../QevoltData/QevoltData";

const Payouts = () => {
  const bank = useRef();
  const accNum = useRef();
  const { dispatch, token, businessData, secret_id, api } = useAuthContext();
  // console.log(businessData);
  const { livecode } = QevoltData();

  const [accNumber, setAccNumber] = useState("");
  const [num, setNum] = useState("");
  const [username, setUsername] = useState("");
  const [checkPayouts, setCheckPayouts] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [bank_code, setBankCode] = useState("");
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [erase, setErase] = useState(false);
  const [arr, setArr] = useState([]);
  const [data, setData] = useState(null);
  const [erasePending, setErasePending] = useState(false);

  const onOptionClicked = (value, value2) => () => {
    setBankN(value);
    setBankCode(value2);
    setShowBank(false);
    setCheckPayouts(true);
    setErase(true);
  };

  // GETTING ALL BUSINESSES
  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");
  const getBusiness = async () => {
    setPending(true);
    setBusinessPending(true);
    console.log("GETTING BUINESSES");
    try {
      const businessresponse = await axios.get(
        api + "/main/all/business",
        config
      );

      if (businessresponse) {
        dispatch({
          type: "SECRET_ID",
          payload: businessresponse.data.results[0].outlet.secret_id,
        });

        dispatch({
          type: "BUSINESS_DATA",
          payload: businessresponse.data.results[0],
        });
        localStorage.setItem(
          "business_secret_id",
          businessresponse.data.results[0].secret_id
        );
        localStorage.setItem(
          "outlet_secret_id",
          businessresponse.data.results[0].outlet.secret_id
        );
        setOutletRreshSecretId(
          businessresponse.data.results[0].outlet.secret_id
        );
        reset();
        setErasePending(false);
        setCheckPayouts(false);
        setErase(false);
      }
    } catch (err) {}
  };
  const [valid, setValid] = useState(false);
  const [password, setPassword] = useState("");
  const [accountPending, setAccountPending] = useState(false);
  const [accountError, setAccountError] = useState("");
  const [accountSuccess, setAccountSuccess] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");
  const [updatePayoutSuccess, setUpdatePayoutSuccess] = useState("");
  const [updatePayoutError, setUpdatePayoutError] = useState("");

  const getAccountName = async () => {
    setAccountPending(true);
    setAccountError("");
    setAccountSuccess("");

    try {
      const form = { bank_code, account_number: accNumber };
      if (bank_code !== "" && accNumber !== "") {
        const accountName = await axios.post(
          api + "/main/verify/bank_account",
          form,
          config
        );

        console.log(accountName);
         if(!accountName.data.Account_name ) {setAccountError(accountName.data.Error)}
        if (accountName) {
          setUsername(accountName.data.Account_name);
          setAccountPending(false);
          console.log(accountName);
        }
      }
    } catch (err) {
      setAccountPending(false);
    }
  };

  const checkacc = () => {
    livecode.map((val) =>
      val.bankCode === businessData.bank ? setBankN(val.bankName) : null
    );
  };

  const reset = () => {
    setAccNumber(businessData.account_number);
    setUsername(businessData.account_name);
    livecode.map((val) =>
      val.bankCode === businessData.bank ? setBankN(val.bankName) : null
    );
    setBusinessPending(false);
  };

  const [showBank, setShowBank] = useState(false);
  const [bankN, setBankN] = useState("");
  const toggleBank = () => {
    setShowBank(!showBank);
  };

  useEffect(() => {
    checkacc();
    reset();
  }, []);

  const filled = () => {
    if (accNum.current.value.length === 10 && password !== "") {
      setCheckPayouts(true);
    } else {
      setCheckPayouts(false);
    }
  };

  // const checkPass = () => {
  //   if (password !== "") {
  //     setValid(true);
  //   } else {
  //     setValid(false);
  //   }
  // };

  useEffect(() => {
    filled();
  }, [bankN, accNumber, account_name, password]);

  // useEffect(() => {
  //   checkPass();
  // }, [password]);

  // UPDATE PAYOUTS

  const updatePayouts = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setSaveChanges(true);
    const form = {
      bank_code,
      account_number: accNumber,
      account_name: username,
      business_id: business_secret_id,
      password,
    };
    console.log(form);
    try {
      const response = await axios.post(
        `${api}/main/update/business`,
        form,
        config
      );
      if (response) {
        setSaveChanges(false);
        setUpdatePayoutError(response.data.Error);
        setAccountSuccess(response.data.Success);
        setErase(false);
        setPassword("");
        setAccountSuccess("Payout account updated successfully")
      }

      console.log(response);
    } catch (err) {
      setSaveChanges(false);
      setAccountError("Something Went Wrong");
    }
  };

  // const showChangesLoader = () => {
  //   setSaveChanges(true);
  //   setTimeout(() => {
  //     setSaveChanges(false);
  //   }, 3000);
  // };

  return (
    <Fade duration={250}>
      <div className="setting-payouts-container">
        {accountSuccess !== "" && !updatePayoutError && (
          <Success message={accountSuccess} />
        )}
        {updatePayoutError !== "" && !accountSuccess && (
          <ErrorMessage message={updatePayoutError} />
        )}
        {accountError !== "" && !accountSuccess && (
          <ErrorMessage message={accountError} />
        )}

        <form action="" className="setting-payouts-form" autocomplete="off">
          {/* <label>
            <div className="label">Bank</div>

            
           <input type="text"  className="settingsInputBox" value={accNumber}   />
            
          </label> */}
          <div className="form-group payouts-form-group">
            <label>
              <div className="settings-label">Bank</div>
            </label>
            {/* <img
                src="/assets/dropb.svg"
                alt="sv"
                className="label-img"
                onClick={() => toggleBank()}
              /> */}
            <input
              type="text"
              className="payouts-drop"
              value={bankN}
              name="bankN"
              onChange={(e) => {
                setBankN(e.target.value);
              }}
              required
              onClick={() => toggleBank()}
              onKeyPress={(e) => e.preventDefault()}
              style={{
                color: "#757575",
                paddingLeft: "16px",
                cursor: "pointer",
              }}
            />
            {showBank && (
              <ul>
                {livecode.map((val, idx) => (
                  <li
                    key={idx}
                    onClick={onOptionClicked(val.bankName, val.bankCode)}
                    style={{ cursor: "pointer" }}
                  >
                    {val.bankName}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="accNum-container">
            <div className="accNum-input-area">
              <label>
                <div className="settings-label mt-36">Account number</div>

                <input
                  className="settingsInputBox"
                  type="number"
                  name="account_number"
                  ref={accNum}
                  value={accNumber}
                  onChange={(e) => {
                    setAccNumber(e.target.value);
                  }}
                  onKeyUp={() => {
                    accNumber.length === 10
                      ? getAccountName()
                      : console.log("");
                  }}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  maxlength={10}
                  // onKeyDown = {()=> filled()}
                />
              </label>
            </div>
            <div className="accNum-loader">
              {accountPending && (
                <div className="getAccountLoader">
                  <img
                    src="/assets/spin.svg"
                    style={{ width: "40px", transform: "translateY(5px)" }}
                    className="gettingAcc"
                  />
                </div>
              )}
              {!accountPending && <></>}
            </div>
          </div>

          <label>
            <div className="settings-label">Account name</div>
            <input
              className="settingsInputBox"
              type="text"
              name="fullName"
              value={username}
              style={{
                color: "#757575",

                cursor: "not-allowed",
              }}
              disabled
              // onChange={(e)=>setFullNamee(e.target.value)}
              // onKeyDown = {()=> filled()}
            />
          </label>

          <label>
            <div className="settings-label">Password</div>
            <input
              className="settingsInputBox no-mb"
              type="password"
              name="password"
              required
              // value={eemail}
              // ref={password}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </label>
        </form>
        <div className="payouts-buttons">
          {!saveChanges && (
            <button
              className="payouts-save-button "
              style={{
                backgroundColor:
                  checkPayouts && !accountPending && !accountError
                    ? "#8F00FF "
                    : "#D9D9D9",
                color:
                  checkPayouts && !accountPending && !accountError
                    ? "white"
                    : "#FFF",
                pointerEvents:
                  checkPayouts && !accountPending && !accountError
                    ? "all"
                    : "none",
                cursor:
                  checkPayouts && !accountPending && !accountError
                    ? "pointer"
                    : "not-allowed",
              }}
              onClick={() => {
                updatePayouts();
              }}
              disabled={!checkPayouts}
            >
              Save Changes
            </button>
          )}
          {saveChanges && (
            <button className="payouts-save-button " disabled>
              <img src="/assets/spinner.svg" alt="" style={{ width: "40px" }} />
            </button>
          )}
          {erase && !erasePending && (
            <button
              className="erase-btn"
              onClick={() => {
                getBusiness();
                setErasePending(true);
                setCheckPayouts(false);
              }}
            >
              Erase Changes
            </button>
          )}
          {erasePending && (
            <button className="erase-btn-loading" disabled>
              <img src="/assets/spinner.svg" alt="" />
            </button>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default Payouts;
