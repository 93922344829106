import React from "react";

const DateComponent = ({ date_created, seen }) => {

  const today = new Date();
  const passedDate = new Date(date_created);
  const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
  return (
    <>
      {`${new Date()}`.substring(0, 15) ===
        `${new Date(date_created)}`.substring(0, 15) && (
        <p>
          {`${new Date(date_created)
            .toTimeString()
            .substring(0, 2)} : ${new Date(date_created)
            .toTimeString()
            .substring(3, 5)}  `}{" "}
          {new Date(date_created).toTimeString().substring(0, 2) > 12
            ? "pm"
            : "am"}
        </p>
      )}
      {passedDate.toDateString() === yesterday.toDateString() &&
         <p>Yesterday</p>}

      {Math.round(
        (new Date().getTime() - new Date(date_created).getTime()) /
          (1000 * 3600 * 24)
      ) == 2 && (
        <p>
          {new Date(date_created).toDateString().substring(0, 3) === "Mon"
            ? "Monday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Tue"
            ? "Tuesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Wed"
            ? "Wednesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Thu"
            ? "Thursday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Fri"
            ? "Friday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sat"
            ? "Saturday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sun"
            ? "Sunday"
            : null}
        </p>
      )}
      {Math.round(
        (new Date().getTime() - new Date(date_created).getTime()) /
          (1000 * 3600 * 24)
      ) == 3 && (
        <p>
          {new Date(date_created).toDateString().substring(0, 3) === "Mon"
            ? "Monday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Tue"
            ? "Tuesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Wed"
            ? "Wednesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Thu"
            ? "Thursday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Fri"
            ? "Friday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sat"
            ? "Saturday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sun"
            ? "Sunday"
            : null}
        </p>
      )}
      {Math.round(
        (new Date().getTime() - new Date(date_created).getTime()) /
          (1000 * 3600 * 24)
      ) == 4 && (
        <p>
          {new Date(date_created).toDateString().substring(0, 3) === "Mon"
            ? "Monday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Tue"
            ? "Tuesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Wed"
            ? "Wednesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Thu"
            ? "Thursday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Fri"
            ? "Friday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sat"
            ? "Saturday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sun"
            ? "Sunday"
            : null}
        </p>
      )}
      {Math.round(
        (new Date().getTime() - new Date(date_created).getTime()) /
          (1000 * 3600 * 24)
      ) == 5 && (
        <p>
          {new Date(date_created).toDateString().substring(0, 3) === "Mon"
            ? "Monday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Tue"
            ? "Tuesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Wed"
            ? "Wednesday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Thu"
            ? "Thursday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Fri"
            ? "Friday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sat"
            ? "Saturday"
            : null}
          {new Date(date_created).toDateString().substring(0, 3) === "Sun"
            ? "Sunday"
            : null}
        </p>
      )}

      {(new Date().getTime() - new Date(date_created).getTime()) /
        (1000 * 3600 * 24) >
        6 && <p>{new Date(date_created).toDateString().substring(4)}</p>}
    </>
  );
};

export default DateComponent;
