import React from "react";
import "./logoutcover.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";

const LogoutCover = () => {
  const { dispatch } = useAuthContext();

  const removeOutModal = () => {
    dispatch({ type: "LOGOUT_MODAL", payload: false });
  };

  return (
    <div
      className="logout-cover"
      // onClick={removeOutModal}
    ></div>
  );
};

export default LogoutCover;
