import React from "react";
import "./supportModal.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import Chatra from "@chatra/chatra";

const SupportModal = () => {
  const { dispatch } = useAuthContext();

  const openChatra = () => {
    Chatra("openChat", true);
    console.log("open chatra");
  };

  const removeSupportModal = () => {
    dispatch({ type: "SUPPORT_MODAL", payload: false });
  };
  return (
    <div className="supportModal-container">
      <div className="supportModal-header">Support</div>
      <div className="supportModal-body">
        <div className="supportModal-support">
          <img src="/assets/support.svg" alt="" />
          <p
            onClick={() => {
              openChatra();
              removeSupportModal();
            }}
            style={{ cursor: "pointer" }}
          >
            Live chat
          </p>
        </div>
        <div className="supportModal-support">
          <img src="/assets/support-phone.svg" alt="" />
          <a style={{ cursor: "pointer" }}>01-515-5113</a>
        </div>
        <div className="supportModal-support">
          <img src="/assets/support-mail.svg" alt="" />
          <a href="mailto:support@qevolt.com" target="_blank">
            support@qevolt.com
          </a>
        </div>
        <div className="supportModal-support">
          <img src="/assets/support-faq.svg" alt="" />
          <a href="https://qevolt.com/faqs" target="_blank">
            FAQs
          </a>
        </div>
        <div className="supportModal-buttons">
          <button className="supportModal-cancel" onClick={removeSupportModal}>
            <p>Cancel</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportModal;
