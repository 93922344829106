import "./App.css";
import { Dashboard, OnBoarding } from "./pages/index";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Forgot,
  OTP,
  Reset,
  Login,
  SignUp,
  Loginn,
} from "./components/onBoarding-components";
import {
  Home,
  Transactions,
  Payouts,
  Organisation,
  Settings,
} from "./components/Dashboard-components";
import QevoltPost from "./QevoltPost";
import { useAuthContext } from "./Hooks/useAuthContext";
import { useEffect, useState } from "react";
import OrganisationId from "./components/Dashboard-components/OrganisationId/OrganisationId";
import Cookies from "js-cookie";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { RingProgress } from "@mantine/core";
import Orgatest from "./components/OrgaTest/Orgatest";
import Invite from "./components/onBoarding-components/Invite/Invite";
import Success from "./components/Success/Success";
import { Fade } from "react-reveal";
import { useTransitionValue } from "react-transition-value";
import PopUp from "./components/Dashboard-components/PopUp/PopUp";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "./components/onBoarding-components/OnBoardingProgress/OnBoardingProgress";
import OnBoardingProgress from "./components/onBoarding-components/OnBoardingProgress/OnBoardingProgress";
import { images } from "./images";
export function App() {
  images.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
  const { email, token, dispatch } = useAuthContext();

  const b = localStorage.getItem("business");
  const v = localStorage.getItem("verified");
  const t = localStorage.getItem("user_token");

  useEffect(() => {
    dispatch({ type: "LOGIN", payload: localStorage.getItem("user_token") });
  }, [dispatch]);

  const role = localStorage.getItem("role");

  const [preLoader, setPreloader] = useState(true);
  const [cln, setCln] = useState("preLoader heartbeat");
  const [screen1, setScreen1] = useState(true);
  const [screen2, setScreen2] = useState(false);
  const [screen3, setScreen3] = useState(false);
  const [screen4, setScreen4] = useState(false);
  const [dot1, setDot1] = useState("control-dot active-dot");
  const [dot2, setDot2] = useState("control-dot ");
  const [dot3, setDot3] = useState("control-dot ");
  const [dot4, setDot4] = useState("control-dot ");
  const [newUser, setNewUser] = useState(true);
  const [newScreen, setNewScreen] = useState(true);
  const [circleValue, setCircleValue] = useState(30);
  const [transitionValue, setTransitionValue] = useTransitionValue(30);

  useEffect(() => {
    setTimeout(() => {
      setCln("preLoader puff-out-center");
    }, 2100);

    setTimeout(() => {
      setPreloader(false);
    }, 2350);
  }, []);

  let params = new URLSearchParams(window.location.search);

  const arrayPath = window.location.pathname
    .split("/")
    .filter((arr) => arr !== "");

  console.log(arrayPath[1]);

  useEffect(() => {
    window.innerWidth < 975 && arrayPath[1] === "resetpassword"
      ? window.location.replace(
          `https://site.qevolt.com/account/resetpassword?uid=${params.get(
            "uid"
          )}&token=${params.get("token")}`
        )
      : window.innerWidth < 975 && arrayPath[1] === "invite"
      ? window.location.replace(
          `https://site.qevolt.com/account/invite?invite=${params.get(
            "invite"
          )}&rid=${params.get("rid")}&email=${params.get("email")}`
        )
      : window.innerWidth < 975
      ? window.location.replace("https://site.qevolt.com")
      : console.log("");
  }, []);

  return (
    <>
      {preLoader && (
        <div className="preloaderContainer">
          <div className={cln}>
            <img src="/assets/qevolt-solo.svg" alt="" />
          </div>
        </div>
      )}
      {!preLoader && window.innerWidth > 975 && (
        <div>
          <OnBoardingProgress />
          <Router>
            <Routes>
              <Route path="*" element={<Navigate to="/account/login" />} />

              <Route path="/f" element={<OnBoarding />} />
              <Route path="/account" element={<OnBoarding />}>
                <Route path="/account" element={<Login />} />
                <Route path="/account/login" element={<Loginn />} />
                <Route path="/account/signup" element={<SignUp />} />
                <Route path="/account/resetpassword" element={<Reset />} />
                <Route
                  path="/account/loginotp"
                  element={email ? <OTP /> : <Navigate to="/account/login" />}
                />
                {/* <Route path='/account/verifyemail' element={<VerifyEmail />} /> */}
                <Route path="/account/forgotpassword" element={<Forgot />} />
                <Route path="/account/invite" element={<Invite />} />
                <Route
                  path="/account/*"
                  element={<Navigate to="/account/login" />}
                />
              </Route>
              <Route
                path="/organisation"
                element={
                  t && v === "true" && b === "false" ? (
                    <Orgatest />
                  ) : (
                    <Navigate to="/account" />
                  )
                }
              />
              <Route
                exact
                path="/dashboard"
                element={t ? <Dashboard /> : <Navigate to="/account/login" />}
              >
                <Route path="/dashboard/home" element={<Home />} />
                <Route
                  path="/dashboard/transactions"
                  element={<Transactions />}
                />
                {role === "BOSS" && (
                  <Route path="/dashboard/payouts" element={<Payouts />} />
                )}
                {role === "MAGR" && (
                  <Route path="/dashboard/payouts" element={<Payouts />} />
                )}
                {role === "BOSS" && (
                  <Route
                    path="/dashboard/organisation"
                    element={<Organisation />}
                  />
                )}
                {role === "MAGR" && (
                  <Route
                    path="/dashboard/organisation"
                    element={<Organisation />}
                  />
                )}
                {role === "BOSS" && (
                  <Route
                    path="/dashboard/organisation/:id"
                    element={<OrganisationId />}
                  />
                )}
                {role === "MAGR" && (
                  <Route
                    path="/dashboard/organisation/:id"
                    element={<OrganisationId />}
                  />
                )}

                {/* <Route  path='/dashboard/refunds' element={<Refunds />} /> */}
                <Route path="/dashboard/settings" element={<Settings />} />
                <Route
                  path="/dashboard"
                  element={
                    token ? (
                      <Navigate to="/dashboard/home" />
                    ) : (
                      <Navigate to="/account/login" />
                    )
                  }
                />
                <Route
                  path="/dashboard/*"
                  element={
                    token ? (
                      <Navigate to="/dashboard/home" />
                    ) : (
                      <Navigate to="/account/login" />
                    )
                  }
                />
              </Route>

              <Route path="/success" element={<Success />} />
              <Route path="/pop" element={<PopUp />} />
            </Routes>
          </Router>
        </div>
      )}
    </>
  );
}
