import { useState, useEffect, useRef } from "react";
import "./outlets.css";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";
import axios from "axios";
import Success from "../../../../Success/Success";
const Outlets = () => {
  const { token, businessData, dispatch, api } = useAuthContext();
  const bank = useRef();
  const phoneNum = useRef();
  const businessAdd = useRef();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [checkOutlets, setCheckOutlets] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);
  const [cac, setCac] = useState(null);
  const [cacPre, setCacPre] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const upload = () => {
    document.querySelector(".upload").click();
  };

  const updateOutlet = async () => {
    
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { pkone: phoneNumber, address: businessAddress, cac, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Business Graded Successfully please log in again to add outlets"
        );
        setPending(false);
          
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  const uploadCAC = (e) => {
    if (e.target.files[0]) {
      setCac(e.target.files[0]);
    }
  };

  const filled = () => {
    if (
      phoneNum.current.value.length >= 11 &&
      businessAdd.current.value !== ""&&
      cac !== null
    ) {
      setCheckOutlets(true);
    } else {
      setCheckOutlets(false);
    }
  };

  useEffect(() => {
    filled();
  }, [phoneNumber, businessAddress, cac]);

  console.log(cac)

  const showChangesLoader = () => {
    setSaveChanges(true);
    setTimeout(() => {
      setSaveChanges(false);
    }, 3000);
  };

  return (
    <Fade duration={250}>
   
      <div className="setting-payouts-container">
      {success !== "" && (
          <Success message={success} />
        )}
        <div className="settings-outlets-warning">
          <p>
            You can only edit your business phone number on this page. Send an
            email to support@qevolt.com to change your outlet information.
          </p>
        </div>
        <form action="" className="setting-payouts-form">
          <label>
            <div className="settings-label">Business name</div>
            <input
              className="settingsInputBox"
              type="text"
              name="fullName"
              value={businessData.outlet.name}
              style={{ color: "#757575", cursor: "not-allowed" }}
              // onChange={(e)=>setFullNamee(e.target.value)}
              // onKeyDown = {()=> filled()}
              disabled
            />
          </label>
          <label>
            <div className="settings-label">Business phone number</div>
            <input
              className="settingsInputBox"
              type="number"
              name="AccountNumber"
              ref={phoneNum}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              onKeyDown={(e) =>
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
              }
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
              }}
              maxlength={13}
              // onKeyDown = {()=> filled()}
            />
          </label>
          <label>
            <div className="settings-label">Business address</div>
            <input
              className="settingsInputBox"
              type="text"
              name="BusinessAddress"
              ref={businessAdd}
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              // onKeyDown = {()=> filled()}
            />
          </label>

          <div
            className="form-group"
            style={{ marginLeft: "0px" }}
            onClick={upload}
          >
            <label className="settings-label" style={{ fontWeight: "400" }}>
              {" "}
              CAC Certificate{" "}
            </label>
            {
              <input
                type="file"
                onChange={uploadCAC}
                name="Upload"
                className="upload"
                accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
              ></input>
            }
            <div className="kyc-dash-box" style={{ marginTop: "8px" }}>
              <p> Select file</p>
            </div>
            <p
              style={{
                fontSize: "14px",
                position: "absolute",
                transform: "translateY(90px)",
              }}
            >
              {" "}
              {cacPre}{" "}
            </p>
          </div>
          {cac && (
            <div
              className=""
              style={{ transform: "translateY(-15px)", color: "green" }}
            >
              Succesfully uploaded
            </div>
          )}
        </form>
        <div className="outlet-button-flex">
          {!pending && (
            <button
              className="payouts-save-button"
              style={{
                backgroundColor: checkOutlets ? "#8F00FF " : "#D9D9D9",
                color: checkOutlets ? "white" : "#FFF",
                pointerEvents: checkOutlets ? "all" : "none",
                cursor: checkOutlets ? "pointer" : "not-allowed",
              }}
              disabled={!checkOutlets}
              onClick={() => updateOutlet()}
            >
              Save Changes
            </button>
          )}
          {pending && (
            <button className="profile-loading-save-button" disabled>
              <img src="/assets/spinner.svg" alt="" style={{ width: "40px" }} />
            </button>
          )}
          {/* <button className="csv-download-btn">Download CSV file</button> */}
        </div>
      </div>
    </Fade>
  );
};

export default Outlets;
