export const images = [
    'assets/cd.svg',
    'assets/add.svg',
    'assets/amount.svg',
    'assets/arrow-left.svg',
    'assets/balanc.svg',
    'assets/balance.svg',
    'assets/bank.svg',
    'assets/bin.svg',
    'assets/c.svg',
    'assets/cc.svg',
    'assets/copy.svg',
    'assets/dashed-border.svg',
    'assets/dot.svg',
    'assets/dropb.svg',
    'assets/dropfilter.svg',
    'assets/erase-spinner.svg',
    'assets/errorc.svg',
    'assets/fee.svg',
    'assets/filter-mobile.svg',
    'assets/fm.svg',
    'assets/g1.svg',
    'assets/g2.svg',
    'assets/g3.svg',
    'assets/g4.svg',
    'assets/g5.svg',
    'assets/Home.svg',
    'assets/info.svg',
    'assets/inn.svg',
    'assets/left-arrow.svg',
    'assets/left.svg',
    'assets/lo.svg',
    'assets/load.svg',
    'assets/loader.svg',
    'assets/logout.svg',
    'assets/m.svg',
    'assets/m1.svg',
    'assets/m2.svg',
    'assets/m3.svg',
    'assets/m4.svg',
    'assets/m5.svg',
    'assets/md.svg',
    'assets/menu.svg',
    'assets/newload.svg',
    'assets/nx.svg',
    'assets/on i.gif',
    'assets/on ii.gif',
    'assets/on iii.gif',
    'assets/on iv.gif',
    'assets/organisation.svg',
    'assets/Payouts.svg',
    'assets/pops.svg',
    'assets/q-refresh.svg',
    'assets/qevolt-full.svg',
    'assets/Qevolt-logo-1.svg',
    'assets/qevolt-logopng.png',
    'assets/qevolt.svg',
    'assets/qevoltb.svg',
    'assets/qevoltcopy.svg',
    'assets/qevoltfullpng.png',
    'assets/question-mark.svg',
    'assets/re.svg',
    'assets/rec.svg',
    'assets/receipt-image.svg',
    'assets/redspin.svg',
    'assets/refresh.svg',
    'assets/refunds.svg',
    'assets/right-arrow.svg',
    'assets/role-drop.svg',
    'assets/roller.svg',
    'assets/RunTransfer-logo-1.svg',
    'assets/RunTransfer-logo-2.svg',
    'assets/search.svg',
    'assets/Settings.svg',
    'assets/settingsLoader.svg',
    'assets/sm.svg',
    'assets/sn.svg',
    'assets/sort.svg',
    'assets/spam.svg',
    'assets/spin.svg',
    'assets/spinload.svg',
    'assets/spinner.svg',
    'assets/success.svg',
    'assets/support-faq.svg',
    'assets/support-mail.svg',
    'assets/support-phone.svg',
    'assets/support.svg',
    'assets/Transactions.svg',
    'assets/verified-transactions.svg',
    'assets/verified.svg',
    'assets/Warning.svg',
    'assets/white-logo.svg',
    'assets/x1.svg',
    'assets/x2.svg',
    'assets/x3.svg',
    'assets/x4.svg',
    'assets/x5.svg',
    'assets/x6.svg',
    'assets/x7.svg',
]