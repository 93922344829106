import React from "react";

const QevoltWarning = ({ text, func }) => {
  return (
    <div className="q-message">
      <p>{text} </p>
    </div>
  );
};

export default QevoltWarning;
