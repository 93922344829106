import { Fade } from "react-reveal";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Settings.css";
import { Profile, Payouts, Outlets, Security, FeeCalc } from "./states/index";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import SettingsMobile from "./SettingsMobile/SettingsMobile";
import { useSearchParams } from "react-router-dom";

import CsvDownloaderComponent from "../CsvDownloader/CsvDownloader";

const Settings = () => {
  const { api, token, dispatch } = useAuthContext();
  const [isProfile, setIsProfile] = useState(true);
  const [isPayouts, setIsPayouts] = useState(false);
  const [isOutlets, setIsOutlets] = useState(false);
  const [isSecurity, setIsSecurity] = useState(false);
  const [isFee, setIsFee] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const showOutlet = searchParams.get("outlet") === "true" ? true : false;

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const role = localStorage.getItem("role");
  const ProfileActive = () => {
    // setSettingState(Profile)
    setIsProfile(true);
    setIsPayouts(false);
    setIsOutlets(false);
    setIsSecurity(false);
    setIsFee(false);
  };
  const PayoutsActive = () => {
    // setSettingState(Payouts)
    setIsProfile(false);
    setIsPayouts(true);
    setIsOutlets(false);
    setIsSecurity(false);
    setIsFee(false);
  };
  const OutletsActive = () => {
    // setSettingState(Outlets)
    setIsProfile(false);
    setIsPayouts(false);
    setIsOutlets(true);
    setIsSecurity(false);
    setIsFee(false);
  };
  const SecurityActive = () => {
    // setSettingState(Outlets)
    setIsProfile(false);
    setIsPayouts(false);
    setIsOutlets(false);
    setIsSecurity(true);
    setIsFee(false);
  };
  const FeeActive = () => {
    // setSettingState(Outlets)
    setIsProfile(false);
    setIsPayouts(false);
    setIsOutlets(false);
    setIsSecurity(false);
    setIsFee(true);
  };

  useEffect(() => {
    if (showOutlet) {
      OutletsActive();
    }
  }, []);

  const livecode = [
    { bankCode: "090267", bankName: "Kuda." },
    { bankCode: "000014", bankName: "Access Bank" },
    { bankCode: "000005", bankName: "Access Bank PLC (Diamond)" },
    { bankCode: "090134", bankName: "ACCION MFB" },
    { bankCode: "090188", bankName: "Baines Credit MFB" },
    { bankCode: "090148", bankName: "Bowen MFB" },
    { bankCode: "100026", bankName: "Carbon" },
    { bankCode: "100005", bankName: "Cellulant" },
    { bankCode: "000009", bankName: "Citi Bank" },
    { bankCode: "100032", bankName: "Contec Global" },
    { bankCode: "060001", bankName: "Coronation" },
    { bankCode: "090156", bankName: "e-BARCs MFB" },
    { bankCode: "000010", bankName: "Ecobank Bank" },
    { bankCode: "100008", bankName: "Ecobank Xpress Account" },
    { bankCode: "090097", bankName: "Ekondo MFB" },
    { bankCode: "000003", bankName: "FCMB" },
    { bankCode: "000007", bankName: "Fidelity Bank" },
    { bankCode: "000016", bankName: "First Bank of Nigeria" },
    {
      bankCode: "110002",
      bankName: "Flutterwave Technology solutions Limited",
    },
    { bankCode: "100022", bankName: "GoMoney" },
    { bankCode: "000013", bankName: "GTBank Plc" },
    { bankCode: "000020", bankName: "Heritage" },
    { bankCode: "090175", bankName: "HighStreet MFB" },
    { bankCode: "000006", bankName: "JAIZ Bank" },
    { bankCode: "090003", bankName: "JubileeLife" },
    { bankCode: "090191", bankName: "KCMB MFB" },
    { bankCode: "000002", bankName: "Keystone Bank" },
    { bankCode: "090171", bankName: "Mainstreet MFB" },
    { bankCode: "100002", bankName: "Paga" },
    { bankCode: "100033", bankName: "PALMPAY" },
    { bankCode: "100003", bankName: "Parkway-ReadyCash" },
    { bankCode: "110001", bankName: "PayAttitude Online" },
    { bankCode: "100004", bankName: "Paycom(OPay)" },
    { bankCode: "000008", bankName: "POLARIS BANK" },
    { bankCode: "000023", bankName: "Providus Bank " },
    { bankCode: "000024", bankName: "Rand Merchant Bank" },
    { bankCode: "000012", bankName: "StanbicIBTC Bank" },
    { bankCode: "100007", bankName: "StanbicMobileMoney" },
    { bankCode: "000021", bankName: "StandardChartered" },
    { bankCode: "000001", bankName: "Sterling Bank" },
    { bankCode: "000022", bankName: "SUNTRUST BANK" },
    { bankCode: "000018", bankName: "Union Bank" },
    { bankCode: "000004", bankName: "United Bank for Africa" },
    { bankCode: "000011", bankName: "Unity Bank" },
    { bankCode: "090110", bankName: "VFD MFB" },
    { bankCode: "000017", bankName: "Wema Bank" },
    { bankCode: "000015", bankName: "ZENITH BANK PLC" },
    { bankCode: "100025", bankName: "Zinternet - KongaPay" },
  ];
  const [bank_code, setBankCode] = useState("");
  const [showBank, setShowBank] = useState(false);
  const [bankN, setBankN] = useState("");
  const toggleBank = () => {
    setShowBank(!showBank);
  };
  const onOptionClicked = (value, value2) => () => {
    setBankN(value);
    setBankCode(value2);
    setShowBank(false);
  };

  const updatePayouts = async () => {
    const form = {};
    const response = axios.post(`${api}/main/update/business`, form, config);
  };
  const getAccountName = async () => {
    const form = {};
    const response = await axios.post(
      api + "/main/verify/bank_account",
      form,
      config
    );
  };

  return (
    <>
      <Fade duration={250}>
        <div className="dashboard-home-container">
          {window.innerWidth > 900 && (
            <>
              <div className="dashboard-settings-navbar">
                <div className="dashboard-settings-navbar-left">
                  <p>Settings</p>
                  <p>Edit your outlet settings.</p>
                </div>
              </div>
              <div className="settings-nav">
                <div className="setting-flex" style={{ marginRight: "96px" }}>
                  <p
                    className="settings-profile"
                    onClick={ProfileActive}
                    style={{ color: isProfile ? "#757575" : "#757575" }}
                  >
                    Profile
                  </p>
                  {isProfile && <div className="settings-line"></div>}
                </div>
                <div className="setting-flex" style={{ marginRight: "96px" }}>
                  <p
                    className="settings-profile"
                    onClick={OutletsActive}
                    style={{ color: isOutlets ? "#757575" : "#757575" }}
                  >
                    Outlet information
                  </p>
                  {isOutlets && <div className="settings-line"></div>}
                </div>
                {role !== "STAF" && (
                  <div className="setting-flex">
                    <p
                      className="settings-payouts"
                      onClick={PayoutsActive}
                      style={{ color: isPayouts ? "#757575" : "#757575" }}
                    >
                      Payouts
                    </p>
                    {isPayouts && <div className="settings-line"></div>}
                  </div>
                )}

                <div
                  className="setting-flex"
                  style={{ marginLeft: role !== "BOSS" ? "0px" : "96px" }}
                >
                  <p
                    className="settings-Security"
                    onClick={SecurityActive}
                    style={{
                      color: isSecurity ? "#757575" : "#757575",
                      marginLeft: role !== "BOSS" ? "0px" : "",
                    }}
                  >
                    Security
                  </p>
                  {isSecurity && <div className="settings-line"></div>}
                </div>
                <div className="setting-flex" style={{ marginLeft: "96px" }}>
                  <p
                    className="settings-fee"
                    onClick={FeeActive}
                    style={{ color: isFee ? "#757575" : "#757575" }}
                  >
                    Fee Calculator
                  </p>
                  {isFee && <div className="settings-line"></div>}
                </div>
              </div>
              <div className="settings-border-bottom"></div>
              <div className="settings-state">
                {isProfile && <Profile />}
                {isPayouts && <Payouts />}
                {isOutlets && <Outlets />}
                {isSecurity && <Security />}
                {isFee && <FeeCalc />}
              </div>
            </>
          )}
          {window.innerWidth < 900 && (
            <>
              {" "}
              <SettingsMobile />{" "}
            </>
          )}
        </div>

        
      </Fade>
    </>
  );
};

export default Settings;
