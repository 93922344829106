import { useState, useEffect, useRef } from "react";
import { Fade } from "react-reveal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";
import "./Transactions.css";
import { init } from "aos";

import Hamburger from "../Hamburger/Hamburger";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import DateComponent from "../DateComponent/DateComponent";
import TransactionsMobile from "./TransactionsMobile/TransactionsMobile";
import CsvDownloaderComponent from "../CsvDownloader/CsvDownloader";

const Transactions = () => {
  const stat = useRef();
  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");
  const [term, setTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [arr, setArr] = useState([]);
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [initialSlice, setInitialSlice] = useState(0);
  const [finalSlice, setFinalSlice] = useState(15);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [search, setSearch] = useState(false);
  const [btnCheck, setBtnCheck] = useState(false);
  const [refundButton, setRefundButton] = useState(true);
  const [refundValue, setRefundValue] = useState("");
  const [apply, setApply] = useState(true);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const totalTransactions = newArr.length;

  const dashcontainer = document.querySelector(".dashboard-container");

  const addModal = (event) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.secret_id === event.secret_id;
      });
    });

    setShowModal(true);

    setShowTransaction(true);
    if (window.matchMedia("(max-width: 575px)").matches) {
      dashcontainer.style.cssText = `
       
       height: 100vh;
   
       `;
    }
  };

  const [pagRes, setPagRes] = useState([]);
  //  console.log(totalTransactions)

  const [to, setTo] = useState(0);
  const test = () => {
    if (totalTransactions > 15) {
      setTo(finalSlice);
    } else {
      setTo(totalTransactions);
    }
  };

  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const {
    dispatch,
    token,
    businessData,
    business,
    api,
    secret_id,
    outletReset,
    filterPop,
  } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [paginationLink, setPaginationLink] = useState("");

  const [paginationCount, setPaginationCount] = useState(2);
  const [paginationPrev, seetPaginationPrev] = useState(0);
  const [paginationFirst, setPaginationFirst] = useState(1);
  const [paginationSecond, setPaginationSecond] = useState(10);

  const [Odata, setOData] = useState(null);
  const [Opending, setOPending] = useState(false);
  const [Oerror, setOError] = useState("O");
  const [panLink, setPanLink] = useState("");
  const business_id = localStorage.getItem("business_secret_id");
  const [details, setDetails] = useState(null);

  const paginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationCount}`,
        config
      );
      console.log(nextpagination)
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setArr(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
        setPaginationLink(nextpagination.data.previous);
      }
    } catch (err) {}
  };

  const role = localStorage.getItem("role");
  const getOutletList = async () => {
    setOPending(true);
    try {
      const response = await axios.get(
        `${api}/main/all/outlet/${business_id}`,
        config
      );
      if (response) {
        setOPending(false);
        setOError(null);
        setOData(response.data.results);
        console.log(response.data.results);
      }
    } catch (err) {
      setOPending(false);
      setOError(err.message);
    }
  };
  const paginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.get(
        `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationPrev}`,
        config
      );

      if (nextpagination) {
        setPending(false);
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        setData(nextpagination.data.results);
        setArr(nextpagination.data.results);
      }
    } catch (err) {}
  };

  useEffect(() => {
    //   getData()
    setPaginationCount(2);
    seetPaginationPrev(0);
    setPaginationFirst(1);
    setPaginationSecond(10);

    setSearchPagCount(2);
    setSearchPagPrev(0);
    setSearchPaginationFirst(1);
    setSearchPaginationSecond(10);
    business(
      data,
      setData,
      setPending,
      setError,
      setOutletRreshSecretId,
      setArr,
      setBusinessPending,
      setFilterState,
      setToday,
      setDropFilter,
      setPagRes
    );
  }, []);

  const removeitem = () => {
    if (data) {
      setArr(data);
    }
    setShowRefund(false);
    arr.splice(0, 0);
    setShowModal(false);
    setShowTransaction(false);
    if (window.matchMedia("(max-width: 575px)").matches) {
      dashcontainer.style.height = "90vh";
    }
  };

  //Search transactions

  const [query, setQuery] = useState("");

  const searchTransactions = async (event) => {
    event.preventDefault();
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    setSearchPagi(true);
    if (query === "") {
      setSearchPagi(false);
    }
    setSearchPagCount(2);
    setSearchPagPrev(0);
    setSearchPaginationFirst(1);
    setSearchPaginationSecond(10);
    try {
      const searchresponse = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?query=${query} `,
        {},
        config
      );

      setData(searchresponse.data.results);
      setArr(searchresponse.data.results);
      setSearchResults(searchresponse.data);
      if (searchresponse) {
        setPending(false);
      }
    } catch (err) {}
  };

  //search states for pagination
  const [searchResults, setSearchResults] = useState(null);
  const [searchPagi, setSearchPagi] = useState(false);
  const [searchPagCount, setSearchPagCount] = useState(2);
  const [searchPagPrev, setSearchPagPrev] = useState(0);
  const [searchPaginationFirst, setSearchPaginationFirst] = useState(1);
  const [searchPaginationSecond, setSearchPaginationSecond] = useState(10);

  console.log(searchPagPrev)

  const searchPaginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);

    try {
      const nextpagination = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?page=${searchPagCount}&query=${query} `,
        {},
        config
      );

      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setArr(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setSearchPagCount((prev) => prev + 1);
        setSearchPagPrev(searchPagPrev + 1);
        setSearchPaginationFirst((prev) => prev + 10);
        setSearchPaginationSecond((prev) => prev + 10);
      }
    } catch (err) {}
  };

  const searchPaginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    try {
      const nextpagination = await axios.post(
        `${api}/main/search/trans/${outlet_secret_id}?page=${searchPagPrev}&query=${query} `,
        {},
        config
      );

      if (nextpagination) {
        setPending(false);
        setSearchPagCount((prev) => prev - 1);
        setSearchPagPrev((prev) => prev - 1);
        setSearchPaginationFirst((prev) => prev - 10);
        setSearchPaginationSecond((prev) => prev - 10);
        setData(nextpagination.data.results);
        setArr(nextpagination.data.results);
      }
    } catch (err) {}
  };

  //FILTER TRANSACTIONS

  let now = new Date();
  const [filterState, setFilterState] = useState(false);
  const [filterCount, setFilterCount] = useState("");
  const [filterResults, setFilterResults] = useState(null);
  const filterTransactions = async (start_date , end_date, change) => {
    setPending(true);
    setPaginationFirst(1)
    setPaginationSecond(10)
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setToday(change);
    const form = { start_date, end_date };


    console.log(form)


    try {
      const response = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}`,
        form,
        config
      );
      if (response) {
        setPending(false);

        setData(response.data.results);
        setArr(response.data.results);
        setFilterResults(response.data);
        setFilterCount(response.data.count);
        setFilterState(true);
        console.log(response);
      }
    } catch (err) {}
  };

  // filter input state

  const [dropFilter, setDropFilter] = useState(false);

  const [filterInput, setFilterInput] = useState("");
  const [today, setToday] = useState("Today");
  const [sDays, setSDays] = useState("Last 7 Days");
  const [fDays, setFDays] = useState("Last 14 Days");
  const [tDays, setTDays] = useState("Last 30 Days");

  const [sbtn, setsbtn] = useState(false);
  const [tbtn, settbtn] = useState(false);

  const [dateChanged, setDateChanged] = useState(false);

  const filter7 = () => {
    setsbtn(true);
    settbtn(false);
  };
  const filter30 = () => {
    setsbtn(false);
    settbtn(true);
  };

  const filterDate = () => {
    setsbtn(false);
    settbtn(false);
  };

  const [Radio1, setRadio1] = useState(false);
  const [Radio2, setRadio2] = useState(false);
  const [Radio3, setRadio3] = useState(false);
  const [Radio4, setRadio4] = useState(false);

  const showRadio1 = () => {
    setRadio1(true);
    setRadio2(false);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio2 = () => {
    setRadio1(false);
    setRadio2(true);
    setRadio3(false);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio3 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(true);
    setRadio4(false);
    setDropFilter(false);
  };
  const showRadio4 = () => {
    setRadio1(false);
    setRadio2(false);
    setRadio3(false);
    setRadio4(true);
    setDropFilter(false);
  };

   //day before
   const startinputDate = new Date(now.toISOString().substring(0, 10));
   const dayBeforeDate = new Date(
     startinputDate.getTime() + 24 * 60 * 60 * 1000
   );
   const dayBeforeDateString = dayBeforeDate.toISOString().slice(0, 10);
 
   //day after
   const endinputDate1 = new Date(new Date(now.setDate(now.getDate() - 7)).toISOString().substring(0, 10));
   const dayAfterDate1 = new Date(endinputDate1.getTime() - 24 * 60 * 60 * 1000);
   const dayAfterString1 = dayAfterDate1.toISOString().slice(0, 10);


   const endinputDate = new Date(new Date(now.setDate(now.getDate() - 30)).toISOString().substring(0, 10));
   const dayAfterDate = new Date(endinputDate.getTime() - 24 * 60 * 60 * 1000);
   const dayAfterString = dayAfterDate.toISOString().slice(0, 10);

    //day before
  const startinputDate2 = new Date(startDate.toISOString().substring(0, 10));
  const dayBeforeDate2 = new Date(
    startinputDate2.getTime() - 24 * 60 * 60 * 1000
  );
  const dayBeforeDateString2 = dayBeforeDate2.toISOString().slice(0, 10);

  //day after
  const endinputDate2 = new Date(endDate.toISOString().substring(0, 10));
  const dayAfterDate2 = new Date(endinputDate2.getTime() + 24 * 60 * 60 * 1000);
  const dayAfterString2 = dayAfterDate2.toISOString().slice(0, 10);

  const filterNextPagination = async (start_date , end_date) => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
    const form = { start_date, end_date };
    try {
      const nextpagination = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`, form ,
        config
      );
      console.log(nextpagination)
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setPaginationCount((prev) => prev + 1);
        seetPaginationPrev((prev) => prev + 1);
        setPaginationFirst((prev) => prev + 10);
        setPaginationSecond((prev) => prev + 10);
        setPaginationLink(nextpagination.data.previous);
      }
    } catch (err) {}
  };
  const filterPreviousPagination = async (start_date , end_date) => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true);
     const form = { start_date, end_date };
    try {
      const nextpagination = await axios.post(
        `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationPrev}`, form, 
        config
      );
      console.log(nextpagination)
      if (nextpagination) {
        setPending(false);
        setData(nextpagination.data.results);
        setPanLink(nextpagination.data.previous);
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev) => prev - 1);
        setPaginationFirst((prev) => prev - 10);
        setPaginationSecond((prev) => prev - 10);
        setPaginationLink(nextpagination.data.previous);
      }
    } catch (err) {}
  };  
  
  const [csvModal , setCsvModal] = useState(false)

  const rn = new Date().toISOString().substring(0, 10);

  const dateModded = () => {
    if (endDate.toISOString().substring(0, 10) !== rn) {
      setDateChanged(true);
    } else {
      setDateChanged(false);
    }
  };
  const startDateModded = () => {
    if (startDate.toISOString().substring(0, 10) !== rn) {
      setDateChanged(true);
    } else {
      setDateChanged(false);
    }
  };



  useEffect(() => {
    dateModded();
  }, [endDate]);

  useEffect(() => {
    startDateModded();
  }, [startDate]);

  return (
    <>
      {/* <div className="home-mobile-top">
        {businessData && !outletReset && (
          <div className="home-mobile-top-left" style={{ marginTop: "40px" }}>
            <div className="name-circle">
              <p> {businessData.name.substring(0, 1)} </p>
            </div>
            <div className="business-name">
              <p>{businessData.name} </p>
              {role === "BOSS" && (
                <img
                  src="/assets/md.svg"
                  alt=""
                  onClick={() => {
                    dispatch({ type: "FILTER_POP_UP", payload: true });
                    getOutletList();
                  }}
                />
              )}
            </div>
          </div>
        )}
        {details && outletReset && (
          <div className="home-mobile-top-left">
            <div className="name-circle">
              <p> {details.name.substring(0, 1)} </p>
            </div>
            <div className="business-name">
              <p>{details.name} </p>
              {role === "BOSS" && (
                <img
                  src="/assets/md.svg"
                  alt=""
                  onClick={() => {
                    dispatch({ type: "FILTER_POP_UP", payload: true });
                    getOutletList();
                  }}
                />
              )}
            </div>
          </div>
        )}
      </div> */}
      <Fade duration={250}>
        <div className="dashboard-transactions-container">
          {window.innerWidth > 768 && (
            <>
              {showTransaction && (
                <div id="modalodd">
                  <div className="dashboard-transaction-modal-heading">
                    <p>Transaction Details</p>
                  </div>
                  {arr &&
                    arr.map((arr, idx) => (
                      <div className="dashboard-modal-flex-container" key={idx}>
                        <div className="dashboard-modal-flex">
                          <p>Date and Time</p>
                          <p>{new Date(arr.date_created).toDateString()}</p>
                        </div>
                        <div className="dashboard-modal-flex">
                          <p>Name</p>
                          <p>{arr.payer_account_name.toLocaleLowerCase()}</p>
                        </div>
                        <div className="dashboard-modal-flex">
                          <p>Amount</p>
                          <p>
                            {" "}
                            &#x20A6;{parseInt(arr.amount).toLocaleString()}
                          </p>
                        </div>
                        <div className="dashboard-modal-flex">
                          <p>Bank</p>
                          <p>{arr.payer_bank_name}</p>
                        </div>

                        <div className="dashboard-modal-flex">
                          <p>Transaction ID</p>
                          <p>{arr.public_id}</p>
                        </div>
                      </div>
                    ))}

                  <div className="dashboard-modal-buttons">
                    <button onClick={() => removeitem()}> Cancel</button>
                  </div>
                </div>
              )}

              {csvModal  && <CsvDownloaderComponent type={'trans'}  setCsvModal={setCsvModal}   />}

              <div className="dashboard-fixed-top"></div>

              <div className="dashboard-transactions-navbar">
                <div className="dashboard-transactions-navbar-left">
                  {!search && (
                    <>
                      <p>Transactions</p>
                      <p>See all your transactions</p>
                    </>
                  )}
                </div>

                {!search && <Hamburger stat={stat} />}

                <div className="mobile-right">
                  {!search && (
                    <img
                      src="/assets/search.svg"
                      alt="search"
                      className="searchmobile"
                      onClick={() => setSearch(true)}
                    />
                  )}
                  {search && (
                    <div
                      className="mobilesearchdiv"
                      style={{ transform: "translate(15px , 0px )" }}
                    >
                      <img
                        src="/assets/left.svg"
                        alt=""
                        onClick={() => {
                          setSearch(false);
                          setTerm("");
                        }}
                      />
                      <form action="" onSubmit={searchTransactions}>
                        <input
                          className="inputmobile"
                          type="text"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      </form>
                    </div>
                  )}
                </div>

                <div style={{ display: "flex", gap: "30px" }}>
                  <form onSubmit={searchTransactions}>
                    <div className="dashboard-transactions-navbar-right">
                      <img
                        src="/assets/search.svg"
                        alt=""
                        className="search"
                        style={{ top: "13px" }}
                      />

                      <input
                        type="text"
                        placeholder="Search transactions"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>
                  </form>
                </div>
              </div>

              <div className="transactions-filter">
                <div className="transactions-filter-flex">
                  <div className="filter-selectors">
                    <div className="transactions-filter-heading">Filter by</div>
                    <div
                      className="transactions-filter-selection"
                      style={{
                        backgroundColor: sbtn ? "#121212" : "#F8F8F8",
                        color: sbtn ? "#FFF" : "#757575",
                      }}
                      onClick={() => {
                        filterTransactions(
                          dayAfterString1,
                          dayBeforeDateString,                          
                          sDays
                        );
                        filter7();
                      }}
                    >
                      Last 7 days
                    </div>
                    <div
                      className="transactions-filter-selection"
                      style={{
                        backgroundColor: tbtn ? "#121212" : "#F8F8F8",
                        color: tbtn ? "#FFF" : "#757575",
                      }}
                      onClick={() => {
                        filterTransactions(
                          dayAfterString,
                          dayBeforeDateString,                         
                          tDays
                        );
                        filter30();
                      }}
                    >
                      Last 30 days
                    </div>

                    <div className="or">or</div>

                    <form action="" className="filter-date-form">
                      {/* <label>
                    <input 
                    type="text" 
                    placeholder="Start date"
                    name = "fromDate"
                    className="filter-date-input"
                    />
                  </label> */}
                      <DatePicker
                        className="filter-date-input"
                        placeholder="Start date"
                        selected={startDate}
                        onChange={(date: Date) => setStartDate(date)}
                      />

                      <div className="to">to</div>
                      {/* <label>
                    <input 
                    type="text" 
                    placeholder="End date"
                    name = "fromDate"
                    className="filter-date-input"
                    /> 
                  </label> */}
                      <DatePicker
                        className="filter-date-input"
                        placeholder="End date"
                        selected={endDate}
                        onChange={(date: Date) => {
                          setEndDate(date);
                          dateModded();
                        }}
                      />
                    </form>
                  </div>

                  <div className="filter-right">
                    
                    <div
                      className="filter-apply"
                      style={{
                        backgroundColor: dateChanged ? "#121212" : "#F8F8F8",
                        color: dateChanged ? "#fff" : "#757575",
                        pointerEvents: dateChanged ? "all" : "none",
                      }}
                      onClick={() => {
                        filterTransactions(
                          dayBeforeDateString2 , dayAfterString2 
                        );
                        filterDate();
                      }}
                    >
                      Apply date
                    </div>
                    <div
                      className="filter-clear"
                      onClick={() =>
                        business(
                          data,
                          setData,
                          setPending,
                          setError,
                          setOutletRreshSecretId,
                          setArr,
                          setBusinessPending,
                          setFilterState,
                          setToday,
                          setDropFilter,
                          setPagRes,
                          setsbtn(false),
                          settbtn(false)
                        )
                      }
                    >
                      Clear filters
                    </div>
                    <div
                      className="filter-apply"
                      style={{
                        marginRight: '30px',
                        backgroundColor:  "#121212" ,
                        color:  "#fff" ,
                        pointerEvents:  "all",
                        marginRight: '0px'
                      }}
                      onClick={() => {
                         setCsvModal(true)
                      }}
                    >
                      Download Csv
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="dashboard-transactions-transactions"
                style={{ height: "74vh" }}
              >
                <div className="dashboard-transactions-transaction-header-flex">
                  <p>Date and Time</p>
                  <p>Name</p>
                  <p>Amount</p>
                  <p>Bank</p>
                  <p>Transaction ID</p>
                  {/* <p>Account Number</p> */}
                </div>
                <div className="preload">
                  {error && (
                    <p className="error">
                      There was an error getting your data
                    </p>
                  )}

                  {pending && (
                    <img
                      src="/assets/spin.svg"
                      alt="loading..."
                      className="pending"
                    ></img>
                  )}
                </div>
                <div className="transactions-scroll">
                  {!pending &&
                    data &&
                    data.map((val, idx) => (
                      <div key={idx}>
                        <Fade duration={250}>
                          <div
                            className="dashboard-transactions-fetch-flex"
                            onClick={() => addModal(val)}
                          >
                            <div className="dashboard-transactions-transactions-list">
                              <DateComponent
                                date_created={val.date_created}
                                seen={val.seen}
                              />
                            </div>
                            <div className="dashboard-transactions-transactions-list">
                              <p>
                                {val.payer_account_name.toLocaleLowerCase()}
                              </p>
                            </div>
                            <div className="dashboard-transactions-transactions-list">
                              <p>
                                &#x20A6;{parseInt(val.amount).toLocaleString()}
                              </p>
                            </div>
                            <div className="dashboard-transactions-transactions-list">
                              <p>{val.payer_bank_name}</p>
                            </div>
                            <div className="dashboard-transactions-transactions-list">
                              <p>{val.public_id}</p>
                            </div>
                          </div>
                        </Fade>
                      </div>
                    ))}
                  {data && data.length === 0 && !pending && (
                    <p className="info-center">You Have No Transactions </p>
                  )}
                </div>
              </div>

              {/* start of normal pagination */}
              {!searchPagi && !filterState && (
                <div className="dashboard-transactions-pagination">
                  {paginationPrev !== 0 && !pending && (
                    <div
                      className="previous"
                      onClick={() => {
                        paginationPrevious();
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {paginationPrev === 0 && !pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}

                  {pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {businessData && (
                    <div className="dashboard-transactions-pagination-text">
                      <p>
                        Results: {paginationFirst} -{" "}
                        {paginationSecond > +businessData.total_transactions
                          ? `${businessData.total_transactions}`
                          : ` ${paginationSecond}`}{" "}
                        of {businessData.total_transactions}
                      </p>
                    </div>
                  )}

                  {businessData &&
                    paginationCount <= Math.ceil(pagRes.count / 10) &&
                    !pending && (
                      <div
                        className="next"
                        onClick={() => {
                          paginationNext();
                        }}
                      >
                        <img
                          src="/assets/right-arrow.svg"
                          alt=""
                          style={{
                            filter:
                              finalSlice === businessData.total_transactions
                                ? "invert(100%)"
                                : "invert(0%)",
                          }}
                        />
                      </div>
                    )}
                  {pending && (
                    <div className="next">
                      <img
                        src="/assets/right-arrow.svg"
                        alt=""
                        style={{
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }}
                      />
                    </div>
                  )}
                  {businessData &&
                    paginationCount > Math.ceil(pagRes.count / 10) &&
                    !pending && (
                      <div className="next">
                        <img
                          src="/assets/right-arrow.svg"
                          alt=""
                          style={{
                            pointerEvents: "none",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                    )}
                </div>
              )}
              {/* end of normal pagination */}

              {/* start of filtered pagination */}

              {filterState && (
                <div className="dashboard-transactions-pagination">
                  {paginationPrev !== 0 && !pending && (
                    <div
                      className="previous"
                      onClick={() => {
                       sbtn  ? filterPreviousPagination(dayAfterString1 , dayBeforeDateString ):  filterPreviousPagination(dayAfterString , dayBeforeDateString );
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {paginationPrev === 0 && !pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}

                  {pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {filterResults && (
                    <div className="dashboard-transactions-pagination-text">
                      <p>
                        Results: {paginationFirst} -{" "}
                        {paginationSecond > +filterCount
                          ? `${filterCount}`
                          : ` ${paginationSecond}`}{" "}
                        of {filterCount}
                      </p>
                    </div>
                  )}

                  {filterResults &&
                    paginationCount <= Math.ceil(filterCount / 10) &&
                    !pending && (
                      <div
                        className="next"
                        onClick={() => {
                          sbtn  ? filterNextPagination(dayAfterString1 , dayBeforeDateString ):  filterNextPagination(dayAfterString , dayBeforeDateString );
                        }}
                      >
                        <img src="/assets/right-arrow.svg" alt="" style={{}} />
                      </div>
                    )}
                  {pending && (
                    <div className="next">
                      <img
                        src="/assets/right-arrow.svg"
                        alt=""
                        style={{
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }}
                      />
                    </div>
                  )}
                  {filterResults &&
                    paginationCount > Math.ceil(filterCount / 10) &&
                    !pending && (
                      <div className="next">
                        <img
                          src="/assets/right-arrow.svg"
                          alt=""
                          style={{
                            pointerEvents: "none",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                    )}
                </div>
              )}

              {/* end of filtered pagination */}

              {/* search pagination */}

              {searchPagi && (
                <div className="dashboard-transactions-pagination">
                  {searchPagPrev !== 0 && !pending && (
                    <div
                      className="previous"
                      onClick={() => {
                        searchPaginationPrevious();
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {searchPagPrev === 0 && !pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}

                  {pending && (
                    <div
                      className="previous"
                      style={{
                        pointerEvents: "none",
                        cursor: "not-allowed",
                      }}
                    >
                      <img src="/assets/right-arrow.svg" alt="" />
                    </div>
                  )}
                  {searchResults && (
                    <div className="dashboard-transactions-pagination-text">
                      <p>
                        Results: {searchPaginationFirst} -{" "}
                        {searchPaginationSecond > +searchResults.count
                          ? `${searchResults.count}`
                          : ` ${searchPaginationSecond}`}{" "}
                        of {searchResults.count}
                      </p>
                    </div>
                  )}

                  {searchResults &&
                    searchPagCount <= Math.ceil(searchResults.count / 10) &&
                    !pending && (
                      <div
                        className="next"
                        onClick={() => {
                          searchPaginationNext();
                        }}
                      >
                        <img src="/assets/right-arrow.svg" alt="" style={{}} />
                      </div>
                    )}

                  {pending && (
                    <div className="next">
                      <img
                        src="/assets/right-arrow.svg"
                        alt=""
                        style={{
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }}
                      />
                    </div>
                  )}
                  {searchResults &&
                    searchPagCount > Math.ceil(searchResults.count / 10) &&
                    !pending && (
                      <div className="next">
                        <img
                          src="/assets/right-arrow.svg"
                          alt=""
                          style={{
                            pointerEvents: "none",
                            cursor: "not-allowed",
                          }}
                        />
                      </div>
                    )}
                </div>
              )}

              {/* end of search pagination */}
              <div className="preload"></div>
              {showModal && (
                <div className="cover" onClick={() => removeitem()}></div>
              )}
              {csvModal && <div className="cover" onClick={() => setCsvModal(false)}>
                
                </div>}
            </>
          )}
          {window.innerWidth < 768 && (
            <TransactionsMobile
              data={data}
              pending={pending}
              addModal={addModal}
              arr={arr}
              removeitem={removeitem}
              query={query}
              setQuery={setQuery}
              searchTransactions={searchTransactions}
              sDays={sDays}
              tDays={tDays}
              showRadio2={showRadio2}
              showRadio4={showRadio4}
              filterTransactions={filterTransactions}
              Radio2={Radio2}
              Radio4={Radio4}
            />
          )}
        </div>
      </Fade>
    </>
  );
};

export default Transactions;
