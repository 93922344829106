import React from 'react'
import Hamburger from '../../Hamburger/Hamburger'
import { useAuthContext } from '../../../../Hooks/useAuthContext'
import PopUp from '../../PopUp/PopUp'
import DateComponent from '../../DateComponent/DateComponent'
import { useState } from 'react'
import { QevoltData } from '../../../QevoltData/QevoltData'
const PayoutsMobile = ({data, pending , addModal , arr , removeitem , query , setQuery , searchTransactions , sDays , tDays , showRadio2 , showRadio4 , filterTransactions , Radio2 , Radio4 }) => {
      const {livecode} = QevoltData()
    const {businessData , pop , dispatch , transactionPop , filterPop }  = useAuthContext()
    let now = new Date()
    const [search , setSearch] = useState(true)
  return (
    <div>
        
        <div className='mobile-container-transactions'>
        {filterPop &&
            <PopUp style={{height: '55vh'}} removeItem={removeitem}>
                    <div className="pop-body">
                        <h1>Filter</h1>
                    </div>
                    <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '25px' }}>    
                            <p>Last 7 Days</p>
                            <div className="filter-radio" style={Radio2 ? {border: '1px solid #8F00FF'  , transform: 'translateY(11px)'} : {border: '1px solid #c4c4c4' , transform: 'translateY(11px)'}}  onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 7)).toISOString().substring(0,10) , sDays  ) ; showRadio2()}} >
                                <div className="filter-radio-circle" style={ Radio2 ? {background: '#8f00ff'} : {background: 'none'}} >

                                </div>
                            </div>
                    </div>
                    <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' }}>    
                            <p>Last 30 Days</p>
                            <div className="filter-radio" style={Radio4 ? {border: '1px solid #8F00FF' , transform: 'translateY(11px)'} : {border: '1px solid #c4c4c4' , transform: 'translateY(11px)'}}  onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 30)).toISOString().substring(0,10) , tDays ) ; showRadio4()}} >
                                <div className="filter-radio-circle" style={ Radio4 ? {background: '#8f00ff'} : {background: 'none'}} >

                                </div>
                            </div>
                    </div>
                    <div className="pop-body" style={{marginBottom: '12px'}}>
                        <p>Custom Date</p>
                    </div>

                    <div className="pop-start-end">
                        <input type="text" placeholder='Start date' />
                        <input type="text" placeholder='End date' />
                    </div>
                
                    <div className="pop-body">
                        <button>Filter transactions</button>
                    </div>
            </PopUp>
        }
        {search && <div className="mobile-transactions-top">
            <div className="m-transactions-top-left">
                <h1>Payouts</h1>
            </div>
            <div className="m-transactions-top-right">
                <img src="/assets/sm.svg" alt="" onClick={()=>setSearch(false)} />
                <img src="/assets/fm.svg" alt="" onClick={()=> { dispatch({type: 'TRANSACTION_POP_UP' , payload: false}) ; dispatch({type: 'POP_UP' , payload:false}) ; dispatch({type: 'FILTER_POP_UP' , payload: true}) }} />
            </div>
        </div> }

        {!search &&
          <form action="" onSubmit={searchTransactions}>
            <div className="mobile-search">
              
                    <input 
                    type="text"
                    placeholder='Search transactions'
                    value={query}
                    onChange={(e)=>setQuery(e.target.value)}
                    />
                    <img src="/assets/nx.svg" alt="" onClick={()=>setSearch(true)} />
               
            </div>
             </form>
        }






         {transactionPop && 

                    <PopUp style={{height: '45vh'}} removeItem={removeitem}>
                    <div className="pop-body">
                        <h1>Payout Details</h1>
                    </div>
                    {arr.map((arr , idx)=>(
                        <>
                        <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '13px'}}>    
                            <p>Bank</p>
                            {livecode.map((val , idx)=>(
                                <> {val.bankCode === arr.pay_to_bank_code && <h2>{val.bankName}</h2>} </>
                            ))}
                            
                        </div>
                        <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '13px'}}>    
                            <p>Name</p>
                            <h2>{arr.pay_to_account_lastname.toLowerCase()} {arr.pay_to_account_firtname.toLowerCase()}</h2>
                        </div>
                        <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '13px'}}>    
                            <p>Amount</p>
                            <h2>&#x20A6;{parseInt(arr.amount.toLocaleString())}</h2>
                        </div>
                        <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '13px'}}>    
                            <p>Date and Time</p>
                            <h2>{new Date(arr.date_created).toDateString().substring(4)} ,   { `${new Date(arr.date_created).toTimeString().substring(0,2) } : ${new Date(arr.date_created).toTimeString().substring(3,5) }  ` } {new Date(arr.date_created).toTimeString().substring(0,2) > 12 ? 'pm' : 'am' }</h2>
                        </div>
                        <div className="pop-body" style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '13px'}}>    
                            <p>Transaction ID</p>
                            <h2>{arr.public_id}</h2>
                        </div>
                        </>
                    ))}

                    </PopUp>
        }
         <div className="transactions-mobile-table">
         {pending && <div style={{display: 'flex' , justifyContent: 'center'}}>
        <img src="/assets/spin.svg" style={{width: '70px' , height: '70px'}} alt="" />
        
      </div>}
            {data && !pending && data.map((val , idx)=>(
                <div className='table-container'  key={idx}>
                <div className='table-flex' onClick={()=>{addModal(val) ; dispatch({type: 'TRANSACTION_POP_UP' , payload: true}) ; dispatch({type: 'POP_UP' , payload:false}) ;  dispatch({type: 'FILTER_POP_UP' , payload: false}) }}>
                    <div className="left-table">
                        <div className="left-table-circle">

                        </div>
                        <div className="left-table-details">
                            <h3 style={{marginBottom: '6px' , fontSize: '16px'}}>&#x20A6;{val.amount}</h3>
                            <DateComponent date_created={val.date_created} seen={val.seen} />
                        </div>
                    </div>
                    <div className="right-table" style={{alignSelf:'center'}}>
                        <p>  <h4
                        className="status"
                        style={
                          val.status === true
                            ? {
                                color: "#4BB543",
                                background: "#eefcf3",
                                width: "74px",
                                borderRadius:'20px',
                                display: 'grid',
                                placeItems: 'center'
                              }
                            : val.status === false? {
                              color: "#757575",
                                background: "#f8f8f8",
                                width: "74px",
                                borderRadius:'20px',
                                display: 'grid',
                                placeItems: 'center'
                            }
                            : {
                                color: "#757575",
                                background: "#f8f8f8",
                                width: "80px",
                                borderRadius:'20px',
                                display: 'grid',
                                placeItems: 'center'
                              }
                        }
                      >
                        {val.status === null && "Pending"}
                        {val.status === true && "Paid"}
                        {val.status === false && "Failed"}
                      </h4></p>
                    </div>
                </div>
                </div>
            ))

            }
        </div>

     

        <Hamburger />

    </div>
    </div>
  )
}

export default PayoutsMobile