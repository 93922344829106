import { useState, useRef, useEffect } from "react";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";
import "./feecalc.css";
import { Fade } from "react-reveal";
import axios from "axios";

const FeeCalc = () => {
  const { pop, dispatch, transactionPop, token, api, filterPop } =
    useAuthContext();
  const amt = useRef();
  const [amount, setAmount] = useState("");
  const [fee, setFee] = useState("0");
  const [pay, setPay] = useState("0");
  const [pending, setPending] = useState();
  const [valid, setValid] = useState(false);

  const calculateFee = (e) => {
    e.preventDefault();
    feeCalculator();
  };

  const checkFee = () => {
    if (amount !== "") {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  // const countdown = () => {
  //   const old = new Date("Dec 31,  2023 00:00:00").getTime();
  //   const now = new Date().getTime();
  //   const diff = old - now;
  //   console.log(old, now, diff);
  // };

  useEffect(() => {
    checkFee();
  }, [amount]);

  const feeCalculator = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setPending(true);
    const outlet_id = localStorage.getItem("outlet_secret_id");
    const form = { amount, outlet_id };
    try {
      const response = await axios.post(`${api}/main/calculator`, form, config);
      if (response) {
        setFee(response.data.charges);
        setPay(response.data.payout_amount);
        setPending(false);
        console.log(response);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  return (
    <Fade duration={250}>
      <div className="setting-profile-container">
        <form className="setting-profile-form">
          <label>
            <div className="settings-label">You receive</div>
            <div className="naira-symbol">&#x20A6;</div>
            <input
              className="settingsInputBox"
              type="number"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              placeholder="0"
              name={"amount"}
              style={{ paddingLeft: "25px" }}
              //   style={{
              //     color: "transparent",
              //     textShadow: "0 0 0 black",
              //     cursor: "not-allowed",

              //   }}
            />
          </label>
          <label>
            <div className="settings-label">Qevolt takes</div>
            <div className="naira-symbol">&#x20A6;</div>
            <input
              className="settingsInputBox"
              type="text"
              value={fee}
              disabled
              style={{ cursor: "not-allowed", paddingLeft: "26px" }}
            />
          </label>
          <label>
            <div className="settings-label">We settle into your account</div>
            <div className="naira-symbol">&#x20A6;</div>
            <input
              className="settingsInputBox no-mb"
              type="text"
              value={parseInt(pay).toLocaleString()}
              disabled
              style={{ cursor: "not-allowed", paddingLeft: "25px" }}
            />
          </label>
          {!pending && (
            <button
              className="profile-save-button"
              onClick={calculateFee}
              disabled={!valid}
              style={{
                background: valid ? "#8F00FF" : "#D9D9D9",
                pointerEvent: valid ? "all" : "none",
                cursor: valid ? "pointer" : "",
              }}
            >
              Calculate
            </button>
          )}
          {pending && (
            <button className="profile-save-button" disabled>
              <img src="/assets/spinner.svg" alt="" style={{ width: "40px" }} />
            </button>
          )}
        </form>
      </div>
    </Fade>
  );
};

export default FeeCalc;
