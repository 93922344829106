import { useState, useEffect, useRef } from "react";
import "./profile.css";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../../../Hooks/useAuthContext";

const Profile = () => {
  const { businessData } = useAuthContext();

  const name = useRef();
  const businessName = useRef();
  const email = useRef();
  const password = useRef();

  const [emailError, setEmailError] = useState(false);

  const [businessNamee, setBusinessNamee] = useState("Qevolt");

  const [fullNamee, setFullNamee] = useState(
    `${localStorage.getItem("fullname")}`
  );
  const [eemail, setEemail] = useState(`${localStorage.getItem("mail")}`);
  const [passwordd, setPasswordd] = useState("password");

  const [checkProfile, setCheckProfile] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);

  const filled = () => {
    // if (fullNamee !== "" && eemail !== "" && !emailError) {
    //   setCheckProfile(true);
    // } else if (fullNamee === "") {
    //   setCheckProfile(false);
    // } else if (emailError !== "") {
    //   setCheckProfile(false);
    //   console.log("em,ail err");
    // }
    if (emailError || eemail === "") {
      setCheckProfile(false);
    }
  };

  const showChangesLoader = () => {
    setSaveChanges(true);
    setTimeout(() => {
      setSaveChanges(false);
    }, 3000);
  };

  // const filled = () => {
  //   if (
  //     name.current.value !== "" &&
  //     email.current.value !== "" &&
  //     password.current.value !== "" &&
  //     !emailError &&
  //     !passwordError
  //   ) {
  //     setCheckSignUp(true);
  //   } else {
  //     setCheckSignUp(false);
  //   }
  // };

  const emailRegEx =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailKeyUp = (_email) => {
    if (emailRegEx.test(_email)) {
      console.log(_email);
      setEmailError(false);
    } else {
      setEmailError(true);
    }

    // if (email.current.value === "") {
    //   setEmailError(false);
    // }
  };

  const emailChange = (e) => {
    setEemail(e.target.value);
    emailKeyUp(e.target.value);
    filled();
  };

  useEffect(() => {
    emailKeyUp(eemail);
    filled();
  }, [eemail]);

  // useEffect(() => {email.current.focus()}, []);

  return (
    <Fade duration={250}>
      <div className="setting-profile-container">
        <form className="setting-profile-form" action="">
          <label>
            <div className="settings-label">Full name</div>
            <input
              className="settingsInputBox"
              type="text"
              name="fullName"
              ref={name}
              style={{
                color: "#757575",

                cursor: "not-allowed",
              }}
              disabled
              value={fullNamee}
              // onChange={(e) => setFullNamee(e.target.value)}
              onKeyDown={() => filled()}
            />
          </label>
          <label>
            <div className="settings-label">Email address</div>
            <input
              className="settingsInputBox no-mb"
              type="text"
              name="email"
              value={eemail}
              ref={email}
              onChange={(e) => {
                emailChange(e);
                filled();
                setCheckProfile(true);
              }}
              disabled
            />
          </label>
          {emailError && (
            <p className="emailError settingEmailError">
              Please enter a valid email address
            </p>
          )}
        </form>
        {!saveChanges && (
          <button
            className="profile-save-button scale-solo"
            style={{
              backgroundColor: checkProfile ? "#8F00FF " : "#D9D9D9",
              color: checkProfile ? "white" : "#FFF",
              pointerEvents: checkProfile ? "all" : "none",
              cursor: checkProfile ? "pointer" : "not-allowed",
            }}
            onClick={() => showChangesLoader()}
            disabled={!checkProfile}
          >
            Save Changes
          </button>
        )}
        {saveChanges && (
          <button className="profile-save-button" disabled>
            <img src="/assets/spinner.svg" alt="" />
          </button>
        )}
      </div>
    </Fade>
  );
};

export default Profile;
