import { useState, useEffect } from "react";
import "./onboardingProgress.css";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { Fade } from "react-reveal";

const OnBoardingProgress = () => {
  const [one, setOne] = useState(false);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);

  const [onSign, setOnSign] = useState(true);
  const [onlog, setOnLog] = useState(true);
  const [oncreate, setOnCreate] = useState(true);
  const [contemp, setContemp] = useState(false);

  const [onb, setOnb] = useState(true);

  setInterval(() => {
    if (/signup/.test(window.location.href)) {
      setOne(true);
      setTwo(false);
      setThree(false);
      setFour(false);
      setContemp(true);
      setOnb(true);
    } else if (/invite/.test(window.location.href)) {
      setContemp(false);
      setOnb(true);
    } else if (/loginotp/.test(window.location.href)) {
      setOne(false);
      setTwo(true);
      setThree(false);
      setFour(false);
      setContemp(true);
      setOnb(true);
    } else if (/forgot/.test(window.location.href)) {
      setContemp(false);
      setOnb(true);
    } else if (/dashboard/.test(window.location.href)) {
      setOnb(false);
    } else if (/reset/.test(window.location.href)) {
      setContemp(false);
      setOnb(true);
    } else if (/login/.test(window.location.href)) {
      setContemp(false);
      setOnb(true);
    } else if (/organisation/.test(window.location.href)) {
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(true);
      setContemp(true);
      setOnb(true);
    } else if (!one && !two && !four) {
      setOne(false);
      setTwo(false);
      setThree(true);
      setFour(false);
      setContemp(true);
      setOnb(true);
    }

    // else if(/account/ .test(window.location.href)){
    //   setOne(false)
    //   setTwo(false)
    //   setThree(true)
    // }
  }, 10);

  return (
    <Fade duration={250}>
      {onb && (
        <div className="top-bar">
          <div className="top-bar-content">
            <div className="top-bar-left">
              <img src="/assets/qevolt-full.svg" alt="" />
            </div>
            <div className="top-bar-right">
              {/* Don’t have an account? <Link to="/account/signup" className="app-purple">
              Sign Up
            </Link> */}

              {contemp && (
                <>
                  <div className="contemp-container">
                    <div className="contemp">
                      <div
                        className="contemp-no"
                        style={{
                          background: one ? "#8f00ff" : "#F8F8F8",
                          color: one ? "#fff" : "#757575",
                        }}
                      >
                        1
                      </div>
                      <p>Create account</p>
                      <ChevronRightRoundedIcon
                        className="chevyicon"
                        sx={{ fontSize: "1.5rem" }}
                      />
                    </div>
                    <div className="contemp">
                      <div
                        className="contemp-no"
                        style={{
                          background: two ? "#8f00ff" : "#F8F8F8",
                          color: two ? "#fff" : "#757575",
                        }}
                      >
                        2
                      </div>
                      <p>Verify OTP</p>
                      <ChevronRightRoundedIcon
                        className="chevyicon"
                        sx={{ fontSize: "1.5rem" }}
                      />
                    </div>
                    <div className="contemp">
                      <div
                        className="contemp-no"
                        style={{
                          background: three ? "#8f00ff" : "#F8F8F8",
                          color: three ? "#fff" : "#757575",
                        }}
                      >
                        3
                      </div>
                      <p>Login </p>
                      <ChevronRightRoundedIcon
                        className="chevyicon"
                        sx={{ fontSize: "1.5rem" }}
                      />
                    </div>
                    <div className="contemp">
                      <div
                        className="contemp-no"
                        style={{
                          background: four ? "#8f00ff" : "#F8F8F8",
                          color: four ? "#fff" : "#757575",
                        }}
                      >
                        4
                      </div>
                      <p>Create outlet</p>
                    </div>
                  </div>
                </>
              )}

              <div className="top-bar-right-holder"></div>
            </div>
            <div className="top-bar-mid"></div>
          </div>
        </div>
      )}
    </Fade>
  );
};

export default OnBoardingProgress;
