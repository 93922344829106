import './TransactionsId.css'
import { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Fade } from 'react-reveal'
import axios from 'axios'
import { useAuthContext } from '../../../../Hooks/useAuthContext'
import DateComponent from '../../DateComponent/DateComponent'


const TransactionsId = () => {
  const {public_id} = useParams()
  const [data,setData] = useState(null)
const [pending,setPending] = useState(false)
const [error,setError] = useState(null)
  const [arr,setArr] = useState([])
  const [showTransaction,setShowTransaction] = useState(false)
  const [showModal,setShowModal] = useState(false)
  const [initialSlice,setInitialSlice] = useState(0)
  const [finalSlice,setFinalSlice] = useState(15)
  const [newArr ,setNewArr] = useState([])
  
  const addModal = (event)=>{
          
    setArr((prev)=>{
      return prev.filter((val)=>{
        return val.secret_id === event.secret_id
      })
    })
    
    setShowModal(true)
  
    setShowTransaction(true)
  
  
  }

  
  
  const removeitem  =()=>{
    if(data){
        setArr(data)
    }
  
    arr.splice(0,0)
    setShowModal(false)
    setShowTransaction(false)
  }

  const getTransactions = async () =>{
    setPending(true)
    try{
      const response = await axios.get(
        `${api}/main/view/transactions/${outlet_secret_id}`,
        config
      );
    
      setData(response.data.results)
      setArr(response.data.results)
      setPagRes(response.data)
  

  
      if (response) {
           setPending(false);
          setError(null);
          setFilterState(false)
          setToday('Filter')
          setDropFilter(false)  
      }
     
      if (data) {
        setArr(data);
      }
    }catch(err){

    }
    
  }


  
      const [businessPending , setBusinessPending] = useState(false)
      const [businessError , setBusinessError] = useState('')
      const  {dispatch , token , businessData , business , api} = useAuthContext()
      const config = {
        headers:{
            "Content-Type"  : "multipart/form-data", 
            Authorization: `Bearer ${token}`
        }
      }
   
      const [outletRefreshSecretId , setOutletRreshSecretId] = useState('')


    


  const [paginationLink, setPaginationLink] = useState("");
 
  const [paginationCount, setPaginationCount] = useState(2);
  const [paginationPrev , seetPaginationPrev] = useState(0)
  const [paginationFirst , setPaginationFirst] = useState(1)
  const [paginationSecond , setPaginationSecond] = useState(10)



const [panLink, setPanLink] = useState("");
const paginationNext = async () => {
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");
  setPending(true)
  try{

  
  const nextpagination = await axios.get(
    `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationCount}`,
    config
  );
  if(nextpagination){
    setPending(false)
    setData(nextpagination.data.results);
    setPanLink(nextpagination.data.previous); 
    setPaginationCount((prev) => prev + 1);
    seetPaginationPrev((prev)=> prev + 1 )
    setPaginationFirst((prev) => prev + 10)
    setPaginationSecond((prev)=> prev + 10)
    setPaginationLink(nextpagination.data.previous)
  }
  
}
catch(err){

}
};



const paginationPrevious = async () => {
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");
  setPending(true)
  try{
  
    const nextpagination =  await axios.get(
      `${api}/main/view/transactions/${outlet_secret_id}?page=${paginationPrev}`,
      config
    );

    if(nextpagination){
      setPending(false)
      setPaginationCount((prev) => prev - 1);
      seetPaginationPrev((prev)=> prev - 1 )
      setPaginationFirst((prev) => prev - 10)
      setPaginationSecond((prev)=> prev - 10)
      setData(nextpagination.data.results);
      
    }

   
  
   


   
  }catch(err){

  }

   
  
 

};


useEffect(() => {
  //   getData()
  setPaginationCount(2)
  seetPaginationPrev(0)
  setPaginationFirst(1)
  setPaginationSecond(10)

  setSearchPagCount(2)
  setSearchPagPrev(0)
  setSearchPaginationFirst(1)
  setSearchPaginationSecond(10)
  getTransactions()
 }, []);

// const increaseSlice = () => {
//   if (initialSlice + 15 >= totalTransactions) {
//     setInitialSlice(initialSlice);
//   } else {
//     setInitialSlice(initialSlice + 15);
//   }

//   if (finalSlice + 15 >= totalTransactions) {
//     setFinalSlice(totalTransactions);
//   } else {
//     setFinalSlice(finalSlice + 15);
//   }
// };

// const decreaseSlice = () => {
//   if (initialSlice !== 0) {
//     setInitialSlice(initialSlice - 15);
//     setFinalSlice(finalSlice - 15);
//   }

//   if (finalSlice - 15 <= 15) {
//     setFinalSlice(15);
//   }
// };

// const removeitem = () => {
//   if (data) {
//     setArr(data);
//   }
//   setShowRefund(false);
//   arr.splice(0, 0);
//   setShowModal(false);
//   setShowTransaction(false);
//   if (window.matchMedia("(max-width: 575px)").matches) {
//     dashcontainer.style.height = "90vh";
//   }
// };

//Search transactions
const outlet_secret_id = localStorage.getItem('outlet_secret_id')
const [query , setQuery] = useState('')

const searchTransactions = async (event) =>{
  event.preventDefault()
  const outlet_secret_id = localStorage.getItem('outlet_secret_id')
  setPending(true)
  setSearchPagi(true)
  if(query ===  ''){
    setSearchPagi(false)
  }
  setSearchPagCount(2)
  setSearchPagPrev(0)
  setSearchPaginationFirst(1)
  setSearchPaginationSecond(10)
  try{  
      const searchresponse = await axios.post(`${api}/main/search/trans/${outlet_secret_id}?query=${query} `,{}, config)
    
      setData(searchresponse.data.results)
      setArr(searchresponse.data.results)
    
      setSearchResults(searchresponse.data)
      if(searchresponse){
        setPending(false)
      }
      
  }catch(err){
    
  }
}

//search states for pagination
const [searchResults , setSearchResults] = useState(null)
const [searchPagi , setSearchPagi] = useState(false)
const [searchPagCount , setSearchPagCount] = useState(2)
const [searchPagPrev , setSearchPagPrev] = useState(0)
const [searchPaginationFirst , setSearchPaginationFirst] = useState(1)
const [searchPaginationSecond , setSearchPaginationSecond] = useState(10)


   const searchPaginationNext = async () => {

          const outlet_secret_id = localStorage.getItem("outlet_secret_id");
          setPending(true)
          
          try{

          
          const nextpagination = await  axios.post(`${api}/main/search/trans/${outlet_secret_id}?page=${searchPagCount}&query=${query} `,{}, config)
          
          if(nextpagination){
          
            setPending(false)
            setData(nextpagination.data.results);
            setPanLink(nextpagination.data.previous); 
            setSearchPagCount((prev) => prev + 1);
            setSearchPagPrev((prev)=> prev + 1 )
            setSearchPaginationFirst((prev) => prev + 10)
            setSearchPaginationSecond((prev)=> prev + 10)
          
          }
          
        }
        catch(err){

        }
};



const searchPaginationPrevious = async () => {
const outlet_secret_id = localStorage.getItem("outlet_secret_id");
setPending(true)
try{

  const nextpagination = await  axios.post(`${api}/main/search/trans/${outlet_secret_id}?page=${searchPagPrev}&query=${query} `,{}, config)

  if(nextpagination){
    setPending(false)
    setSearchPagCount((prev) => prev - 1);
    setSearchPagPrev((prev)=> prev - 1 )
    setSearchPaginationFirst((prev) => prev - 10)
    setSearchPaginationSecond((prev)=> prev - 10)
    setData(nextpagination.data.results);
   
  }

 

 
}catch(err){

}


};

//FILTER TRANSACTIONS


let now = new Date()
const [filterCount , setFilterCount] = useState('')
const [filterResults , setFilterResults] = useState(null)
const [filterState , setFilterState] = useState(false)
const filterTransactions = async ( end_date , start_date , change) =>{
 setPending(true)  
 const outlet_secret_id = localStorage.getItem('outlet_secret_id')
setToday(change)
 const form = {start_date , end_date}


 try{  
     const response = await axios.post(`${api}/main/filter/trans/${outlet_secret_id}` , form , config)
     if(response){
         setPending(false)
    
         setData(response.data.results)
         setFilterResults(response.data)
         setFilterCount(response.data.count)
         setFilterState(true)
     } 

 }
 catch(err){

 }

}


// filter input state

const [dropFilter , setDropFilter] = useState(false)

const [filterInput , setFilterInput] = useState('')
const [today , setToday] = useState('Today')
const [sDays , setSDays] = useState('Last 7 Days')
const [fDays , setFDays] = useState('Last 14 Days')
const [tDays , setTDays] = useState('Last 30 Days')



const [Radio1 , setRadio1] = useState(false)
const [Radio2 , setRadio2] = useState(false)
const [Radio3 , setRadio3] = useState(false)
const [Radio4 , setRadio4] = useState(false)

const showRadio1 = () =>{
  setRadio1(true)
  setRadio2(false)
  setRadio3(false)
  setRadio4(false)
  setDropFilter(false)
} 
const showRadio2 = () =>{
  setRadio1(false)
  setRadio2(true)
  setRadio3(false)
  setRadio4(false)
  setDropFilter(false)
} 
const showRadio3 = () =>{
  setRadio1(false)
  setRadio2(false)
  setRadio3(true)
  setRadio4(false)
  setDropFilter(false)
} 
const showRadio4 = () =>{
  setRadio1(false)
  setRadio2(false)
  setRadio3(false)
  setRadio4(true)
  setDropFilter(false)
} 


const filterNextPagination = async () =>{
const outlet_secret_id = localStorage.getItem("outlet_secret_id");
setPending(true)
try{


const nextpagination = await axios.get(
  `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`,config
);
if(nextpagination){
  setPending(false)
  setData(nextpagination.data.results);
  setPanLink(nextpagination.data.previous); 
  setPaginationCount((prev) => prev + 1);
  seetPaginationPrev((prev)=> prev + 1 )
  setPaginationFirst((prev) => prev + 10)
  setPaginationSecond((prev)=> prev + 10)
  setPaginationLink(nextpagination.data.previous)
}

}catch(err){

}
}
const filterPreviousPagination = async () =>{
const outlet_secret_id = localStorage.getItem("outlet_secret_id");
setPending(true)
try{


const nextpagination = await axios.get(
  `${api}/main/filter/trans/${outlet_secret_id}?page=${paginationCount}`,config
);
if(nextpagination){
  setPending(false)
  setData(nextpagination.data.results);
  setPanLink(nextpagination.data.previous); 
  setPaginationCount((prev) => prev - 1);
  seetPaginationPrev((prev)=> prev - 1 )
  setPaginationFirst((prev) => prev - 10)
  setPaginationSecond((prev)=> prev - 10)
  setPaginationLink(nextpagination.data.previous)
}

}catch(err){

}
}


  // useEffect(()=>{
  //   // getData()  
  //   getBusiness() 
  // },[])


  // useEffect(()=>{
  //   setupSlice()
  // })
   
  


  const [pagRes , setPagRes] = useState([])
  // const increaseSlice = () =>{
  //   if(initialSlice+15 >= totalTransactions){
  //     setInitialSlice(initialSlice)
  //   }else{
  //     setInitialSlice(initialSlice+15)
  //   }
    
  //   if(finalSlice+15>=totalTransactions){
  //     setFinalSlice(totalTransactions)
  //   }else{
  //     setFinalSlice(finalSlice+15)
  //   }
  // }
  
  // const decreaseSlice = () =>{
  //   if(initialSlice !== 0 ){
  //     setInitialSlice(initialSlice-15)
  //     setFinalSlice(finalSlice-15)
  
  //   }
  
  //   if (finalSlice-15<=15){
  //     setFinalSlice(15)
  //   }
    
  
  // }


  return (
    <div >

      {/* <div className="transactionsid-pagination">
      <div className="dashboard-transactions-pagination">
              <div className="previous" onClick = {decreaseSlice}>
              <img src="/assets/right-arrow.svg" alt="" style = {{filter: initialSlice===0? "invert(100%)": "invert(0%)" }}/>
              </div>
                <div className="dashboard-transactions-pagination-text">
                  <p>Results : {initialSlice + 1} - {finalSlice} of {totalTransactions}</p>
                </div>
                 
                 <div className="next" onClick={increaseSlice}>
                   <img src="/assets/right-arrow.svg" alt="" style = {{filter: finalSlice===totalTranbersactions? "invert(100%)": "invert(0%)" }}/>
                 </div>
            </div>
      </div> */}


          <div className="" >

          {showTransaction && <div className="modalid" data-aos="fade-up" >
                <div className="dashboard-transaction-modal-heading" >
                    <p>Transaction Details</p>
                </div>
                {arr && arr.map((val,idx)=>(
                    
                        
                        <div  className='dashboard-modal-flex-container' key={idx}>
                            <div className="dashboard-modal-flex">
                            <p>Date and Time</p>
                            <p>{val.date_created.substring(0,11)}  {val.seen === "False" ? <span style={{color: 'white' , transform:'translateX(20px)'}} >New</span> : <></>}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Name</p>
                            <p>{val.payer_account_name.toLocaleLowerCase()}</p>
                         
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Amount</p>
                            <p> &#x20A6;{val.amount}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Bank</p>
                            <p>{val.payer_bank_name}</p> 
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Account Number</p>
                            <p>{val.account}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Transaction ID</p>
                            <p>{val.amount}</p>
                            </div>  
                            </div>
                   
                       
               
              
                   
                ))}
                

                <div className="dashboard-modal-buttons">
                    <button onClick={()=>removeitem()} > Cancel</button>
                    
                </div>
                
                </div>
                }

  <div className="transaction-id-nav">
    <div >
      {/* start of normal pagination */}
 {!searchPagi && !filterState && <div className="dashboard-transactions-pagination">
         { paginationPrev !== 0 && !pending && <div
            className="previous"
            onClick={() => {
              
              paginationPrevious();
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
         { paginationPrev === 0 && !pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}

          {pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
          { businessData && <div className="dashboard-transactions-pagination-text">
            <p>
              Results: {paginationFirst} - {  paginationSecond > +businessData.total_transactions ?   `${businessData.total_transactions}`: ` ${ paginationSecond}`} of{" "} 
              {businessData.total_transactions}
            </p>
          </div> }

        { businessData && (paginationCount <= Math.ceil(pagRes.count/10)) && !pending &&  <div
            className="next"
            onClick={() => {
              paginationNext();
            }}
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                filter:
                  finalSlice === businessData.total_transactions
                    ? "invert(100%)"
                    : "invert(0%)",
              }}
            />
          </div>}
          {pending &&  <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        {!pending && businessData.total_transactions === 0 && 
        <div
        className="next"
      
      >
        <img
          src="/assets/right-arrow.svg"
          alt=""
          style={{
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }}
        />
      </div>
        }
        { businessData && (paginationCount > Math.ceil(pagRes.count/10)) && !pending &&   <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        </div>}
        {/* end of normal pagination */}

        {/* start of filtered pagination */}
              
        {filterState && <div className="dashboard-transactions-pagination">
         { paginationPrev !== 0 && !pending && <div
            className="previous"
            onClick={() => {
              
             filterPreviousPagination();
            }}
          >
         <img src="/assets/right-arrow.svg" alt="" />
          </div>}
         { paginationPrev === 0 && !pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}

          {pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
          { filterResults && <div className="dashboard-transactions-pagination-text">
            <p>
              Results: {paginationFirst} - {  paginationSecond > +filterCount ?   `${filterCount}`: ` ${ paginationSecond}`} of{" "} 
              {filterCount}
            </p>
          </div> }

        { filterResults && ( paginationCount <= Math.ceil(filterCount/10)) && !pending &&  <div
            className="next"
            onClick={() => {
              filterNextPagination();
            }}
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
               
              }}
            />
          </div>}
          {pending &&  <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        { filterResults && (paginationCount > Math.ceil(filterCount/10)) && !pending &&   <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        </div>}

        {/* end of filtered pagination */}

        {/* search pagination */}

        {searchPagi && <div className="dashboard-transactions-pagination">
         { searchPagPrev !== 0 && !pending && <div
            className="previous"
            onClick={() => {
              
              searchPaginationPrevious();
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
         { searchPagPrev === 0 && !pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}

          {pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
          { searchResults && <div className="dashboard-transactions-pagination-text">
            <p>
              Results: {searchPaginationFirst} - {  searchPaginationSecond > +searchResults.count ?   `${searchResults.count}`: ` ${ searchPaginationSecond}`} of{" "} 
              {searchResults.count}
            </p>
          </div> }

        { searchResults && ( searchPagCount <= Math.ceil(searchResults.count/10)) && !pending &&  <div
            className="next"
            onClick={() => {
              searchPaginationNext();
            }}
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
               
              }}
            />
          </div>}
          {pending &&  <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        { searchResults && (searchPagCount > Math.ceil(searchResults.count/10)) && !pending &&   <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        </div>}

        {/* end of search pagination */}
    </div>
  <div style={{display: 'flex' , gap: '30px' , marginTop: '28px'}}>

{filterState && <div className="clear-filter" onClick={()=>business(data , setData , setPending ,setError , setOutletRreshSecretId , setArr , setBusinessPending ,setFilterState, setToday, setDropFilter , setPagRes)}>
        <p>Clear Filter</p>
</div>}
<div className="filter-container-dropdown">
{!pending && <div className="filter-drop" onClick={()=>setDropFilter(!dropFilter)}>
    <input 
    type="text" 
    value={today}
    style={{
      color: "transparent",
      textShadow: "0 0 0 black",
      cursor: "pointer",
    }}
    />
    <img src="/assets/dropfilter.svg" alt="" />
</div>}
{pending && <div className="filter-drop" style={{pointerEvents: 'none'}}>
    <input 
    type="text" 
    value={today}
    style={{
      color: "transparent",
      textShadow: "0 0 0 black",
      cursor: "pointer",
    }}
    />
    <img src="/assets/dropfilter.svg" alt="" />
</div>}
{dropFilter && <div className="filter-drop-value">
  <div className="filter-drop-container" onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 0)).toISOString().substring(0,10) , 'Today'  ); showRadio1() }}>
  <div className="filter-radio" style={Radio1 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}} >
    <div className="filter-radio-circle"  style={ Radio1 ? {background: '#8f00ff'} : {background: 'none'}} >

    </div>
  </div>
<p  >
 
 Today
</p>
</div>
  <div className="filter-drop-container" onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 7)).toISOString().substring(0,10) , sDays  ) ; showRadio2()}}>
  <div className="filter-radio" style={Radio2 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}}  >
    <div className="filter-radio-circle" style={ Radio2 ? {background: '#8f00ff'} : {background: 'none'}} >

    </div>
  </div>
<p  >
 
{sDays}
</p>
</div>
  <div className="filter-drop-container" onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 14)).toISOString().substring(0,10) , fDays  ) ; showRadio3()}}>
  <div className="filter-radio" style={Radio3 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}} >
    <div className="filter-radio-circle" style={ Radio3 ? {background: '#8f00ff'} : {background: 'none'}}  >

    </div>
  </div>
<p  >
 
{fDays}
</p>
</div>
  <div className="filter-drop-container" onClick={()=> {filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 30)).toISOString().substring(0,10) , tDays ) ; showRadio4()}}>
  <div className="filter-radio" style={Radio4 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}} >
    <div className="filter-radio-circle" style={ Radio4 ? {background: '#8f00ff'} : {background: 'none'}} >

    </div>
  </div>
<p  >
 
{tDays}
</p>
</div>

</div>}
</div>

</div>
  </div>
   <div className="dashboard-transactions-transactions" style={{height: '55vh'}}>
      <div className="dashboard-transactions-transaction-header-flex">
          <p>Date and Time</p>
          <p>Name</p>
          <p>Amount</p>
          <p>Bank</p>
          {/* <p>Account Number</p> */}
      </div>


      <div className="preload">
            {error && (
              <p className="error">There was an error getting your data</p>
            )}
            {pending && (
              <img
                src="/assets/roller.svg"
                alt="loading..."
                className="pending"
              ></img>
            )}
          </div>
      
<div className="transactions-scroll">
            {!pending &&
              data &&
              data
                .slice(initialSlice, finalSlice)
                
                .map((val, idx) => (
                  <Fade duration={250}>
                    <div
                      className="dashboard-transactions-fetch-flex"
                      key={idx}
                      onClick={() => addModal(val)}
                    >
                      <div className="dashboard-transactions-transactions-list">
                      
                        <DateComponent date_created={val.date_created} seen={val.seen} />

                      </div>
                      <div className="dashboard-transactions-transactions-list">
                        <p>{val.payer_account_name.toLocaleLowerCase()}</p>
                      </div>
                      <div className="dashboard-transactions-transactions-list">
                        <p>&#x20A6;{parseInt(val.amount).toLocaleString()}</p>
                      </div>
                      <div className="dashboard-transactions-transactions-list">
                        <p>{val.payer_bank_name}</p>
                      </div>
                      {/* <div className="dashboard-transactions-transactions-list">
                    
                
                        <p>
                            {val.account}
                        </p>
                    
                </div> */}
                    </div>
                  </Fade>
                ))}
          </div>

 </div>
 
      {/* {data && data.length === 0 && <p className='info-center'>You Have No Transactions </p>} */}

   
    {showModal && <div className="cover" onClick={()=>removeitem()}>
            
            </div>}

</div>
</div>
  )
}

export default TransactionsId