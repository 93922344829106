import { useState } from "react";
import "./logoutModal.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LogoutModal = () => {
  const { dispatch, api, token } = useAuthContext();
  const navigate = useNavigate();
  const logoutUrl = api + "/accounts/auth/logout/";
  const logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setPending(true);
    console.log(config)
    try {
      const response = await axios.post(logoutUrl, config);

      if (response) {
        dispatch({ type: "LOGIN", payload: null });
        localStorage.removeItem("user_token");
        localStorage.removeItem("verified");
        localStorage.removeItem("business");
        localStorage.removeItem("business_secret_id");
        localStorage.removeItem("outlet_secret_id");
        localStorage.removeItem("role");
        localStorage.removeItem("fullname");
        localStorage.removeItem("reg");
        localStorage.removeItem("mail");
        setPending(false);
        navigate("/account/login");
        dispatch({ type: "LOGOUT_MODAL", payload: false });
        dispatch({ type: "BUSINESS_DATA", payload: null });
      }
    } catch (err) {
      setPending(false);
      navigate("/account/login");
      dispatch({ type: "LOGOUT_MODAL", payload: false });
      dispatch({ type: "BUSINESS_DATA", payload: null });
    }
  };

  const removeOutModal = () => {
    dispatch({ type: "LOGOUT_MODAL", payload: false });
  };
  const [pending, setPending] = useState(false);

  return (
    <div className="logoutModal-container">
      <div className="logoutModal-header">Log out</div>
      <div className="logoutModal-body">
        <div className="logoutModal-question">
          Are you sure you want to log out?
        </div>
        <div className="logoutModal-buttons">
          <button
            className="logoutModal-cancel"
            onClick={removeOutModal}
            disabled={pending}
            style={{ cursor: pending ? "default" : "pointer" }}
          >
            <p>Cancel</p>
          </button>
          <button
            className="logoutModal-logout"
            onClick={() => {
              logout();
              // removeOutModal();
            }}
          >
            {!pending && <p>Log out</p>}
            {pending && <img src="/assets/spinner.svg" alt="" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
