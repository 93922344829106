import axios from "axios";

import "./Orgatest.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useEffect } from "react";
import Registered from "./Registered/Registered";
import { QevoltData } from "../QevoltData/QevoltData";
import PopUp from "../Dashboard-components/PopUp/PopUp";
const Orgatest = () => {
  const { livecode } = QevoltData();

  const { token, dispatch, api, pop } = useAuthContext();
  const [business_phonenumber, setBussinessPhonenumber] = useState("");
  const [address, setAddress] = useState("");
  const [bank_code, setBankCode] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [account_name, setAccountName] = useState("");
  const [referrence, setReferrence] = useState("");
  const [pending1, setPending1] = useState(false);
  const [pending2, setPending2] = useState(false);
  const [pending3, setPending3] = useState(false);

  const fullname = localStorage.getItem("fullname");

  const form = {
    business_name: fullname,
    business_phonenumber,
    bank_code,
    address,
    account_number,
    account_name,
    registered: "False",
    referrence,
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [bodyPreload, setBodyPreload] = useState(false);
  const [pending4, setPending4] = useState(false);
  const checkuserr = async () => {
    const mail = localStorage.getItem("mail");
    const checkuser = await axios.get(`${api}/accounts/check_user/${mail}`);
    localStorage.setItem("verified", checkuser.data.email_verified);
    localStorage.setItem("business", checkuser.data.business);

    dispatch({ type: "VERIFIED", payload: localStorage.getItem("verified") });
    dispatch({ type: "BUSINESS", payload: localStorage.getItem("business") });

    if (checkuser.data.business === true) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    checkuserr();
  }, []);

  const [accountPending, setAccountPending] = useState(false);
  const [accountError, setAccountError] = useState("");
  const getAccountName = async () => {
    setAccountPending(true);
    setAccountError("");
    try {
      const form = { bank_code, account_number };
      if (bank_code !== "" && account_number !== "") {
        const accountName = await axios.post(
          api + "/main/verify/bank_account",
          form,
          config
        );

        if (accountName) {
          setAccountName(accountName.data.Account_name);
          setAccountError(accountName.data.Error);
          setAccountPending(false);
        }
      }
    } catch (err) {
      setAccountPending(false);
    }
  };

  const getData = async (e) => {
    e.preventDefault();

    setPending1(true);
    setBodyPreload(true);

    try {
      const response = await axios.post(
        api + "/main/create/business",
        form,
        config
      );

      if (response) {
        setPending1(false);
        setPending2(true);
      }

      const outletForm = {
        business_secret_id: response.data.secret_id,
        outlet_name: response.data.name,
      };
      const secretresponse = await axios.post(
        api + "/main/create/outlet",
        outletForm,
        config
      );

      dispatch({ type: "SECRET_ID", payload: secretresponse.data.secret_id });
      if (secretresponse) {
        setPending2(false);
        setPending3(true);
      }

      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: secretresponse.data.secret_id },
        config
      );

      if (window.matchMedia("(max-width: 575px)").matches && virtualaccount) {
        setPending3(false);
        setPending4(true);
      }

      const mail = localStorage.getItem("mail");
      const checkuser = await axios.get(`${api}/accounts/check_user/${mail}`);

      if (
        checkuser.data.email_verified === true &&
        checkuser.data.business === true
      ) {
        navigate("/dashboard");
      }
    } catch (err) {
      // if(err.response && err.response.statusText === "Unauthorized"){
      //     localStorage.removeItem('user_token')
      //     localStorage.removeItem('business')
      //     localStorage.removeItem('verified')
      //     setTimeout(()=>{
      //         dispatch({type: 'LOGIN' , payload: null })
      //     }, 3000 )
      //     setError('Please Log in again Redirecting to LOGIN')
      // }
    }
  };

  const [showBank, setShowBank] = useState(false);
  const [bankN, setBankN] = useState("");
  const toggleBank = () => {
    setShowBank(!showBank);
  };
  const onOptionClicked = (value, value2) => () => {
    setBankN(value);
    setBankCode(value2);
    setShowBank(false);
  };

  const [type1, setType1] = useState(true);
  const [type2, setType2] = useState(false);
  const [type3, setType3] = useState(false);
  const [type4, setType4] = useState(false);

  const showType1 = () => {
    setType1(true);
    setType2(false);
  };

  const showType2 = () => {
    setType1(false);
    setType2(true);
  };
  const showType3 = () => {
    setType3(true);
    setType4(false);
  };
  const showType4 = () => {
    setType4(true);
    setType3(false);
  };

  const openModalDiff = () => {
    const kyc = document.querySelector(".kyc");
    setDiff(true);
  };

  const [diff, setDiff] = useState(false);
  const [whyBv, setWhyBv] = useState(false);
  const [need, setNeed] = useState(false);
  const [checkFilled, setCheckFilled] = useState(false);
  const [bvn, setBvn] = useState("");

  const [mobilePop, setMobilePop] = useState(true);

  const filled = () => {
    if (
      business_phonenumber !== "" &&
      address !== "" &&
      account_number !== "" &&
      account_name !== "" &&
      !accountPending
      // accountError !== ""
    ) {
      setCheckFilled(true);
    } else {
      setCheckFilled(false);
    }
  };

  useEffect(() => {
    filled();
  }, [
    account_number,
    account_name,
    business_phonenumber,
    address,
    accountPending,
  ]);

  return (
    <div className="kyc">
      {diff && (
        <div className="modal" style={{ width: "492px", height: "465px" }}>
          <div className="business-heading2">
            <h2>Outlet info</h2>
          </div>
          <div className="business-body">
            <h1>Unregistered business name</h1>
            <p>
              Your business name is the same as your full name because you don’t
              have legal documentation displaying business name or company
              registration
            </p>
            <h1>CAC certificate</h1>

            <p>
              A certificate of incorporation is a document given by the
              Corporate Affairs Commission (CAC) as evidence of the existence of
              a company, and its right to do business. We use this to verify you
              own the business name you've provided
            </p>
            <h1>Payout account </h1>
            <p>
              The account details provided is where your funds will be instantly
              paid into once they have been verified. Qevolt doesn't retain your
              funds
            </p>
          </div>

          {/* <button className="business-btn" onClick={() => setDiff(false)}>
            Cancel
          </button> */}
        </div>
      )}
      {pop && (
        <PopUp>
          <div className="pop-body">
            <h1>Info</h1>
          </div>
          <div className="pop-body">
            <h1>Unregistered business name</h1>
            <p>
              Your business name is the same as your full name because you don’t
              have legal documentation displaying business name or company
              registration
            </p>
          </div>
          <div className="pop-body">
            <h1>CAC certificate</h1>
            <p>
              Your business name is the same as your full name because you don’t
              have legal documentation displaying business name or company
              registration
            </p>
          </div>
          <div className="pop-body">
            <h1>Account Details</h1>
            <p>
              Your business name is the same as your full name because you don’t
              have legal documentation displaying business name or company
              registration
            </p>
          </div>
        </PopUp>
      )}
      {need && (
        <div className="modal" style={{ width: "324px", height: "291px" }}>
          <div className="business-heading2">
            <h2>Business name</h2>
          </div>
          <div className="business-body">
            <p>
              Your business name is the same as your full name because you don’t
              have legal documentation displaying business name or company
              registration
            </p>
          </div>

          <button className="business-btn" onClick={() => setNeed(false)}>
            Cancel
          </button>
        </div>
      )}
      {whyBv && (
        <div className="modal" style={{ width: "324px", height: "270px" }}>
          <div className="business-heading2">
            <h2>BVN</h2>
          </div>
          <div className="business-body">
            <p>
              We collect BVN for user identity verification in order to prevent
              fraudulent activities and ensure financial safety.{" "}
            </p>
          </div>

          <button className="business-btn" onClick={() => setWhyBv(false)}>
            Cancel
          </button>
        </div>
      )}
      {!bodyPreload && (
        <div className="kyc-container">
          <div className="info-flex">
            <div className="business-heading">
              <h2>Create an outlet</h2>
              <p>Set up your outlet</p>
            </div>
            <div className="business-info" onClick={openModalDiff}>
              <img src="/assets/info.svg" alt="" />
              <div className="business-info-text">info</div>
            </div>
          </div>

          <div className="business-heading-mobile">
            <div className="business-heading-mobile-text">
              <h2>Create an outlet</h2>
              <p>Text goes here lol</p>
            </div>
            <p
              onClick={() => {
                dispatch({ type: "POP_UP", payload: true });
              }}
              style={{ color: "#757575" }}
            >
              {" "}
              <img src="/assets/inn.svg" alt="" /> info
            </p>
          </div>

          <div className="business-type-flex">
            <p>Account type</p>
            {/* {window.innerWidth > 768 && (
            <span onClick={openModalDiff}>What's the difference?</span>
          )} */}
          </div>

          <div className="business-type-container">
            <div className="business-type" onClick={showType1}>
              <div
                className="radio-container"
                style={
                  type1
                    ? { border: "1px solid #8F00FF" }
                    : { border: "1px solid #C4C4C4" }
                }
              >
                <div
                  className="radio"
                  style={
                    type1 ? { background: "#8F00FF" } : { background: "white" }
                  }
                ></div>
              </div>

              <p>Unregistered</p>
            </div>

            <div className="business-type" onClick={showType2}>
              <div
                className="radio-container"
                style={
                  type2
                    ? { border: "1px solid #8F00FF" }
                    : { border: "1px solid #C4C4C4" }
                }
              >
                <div
                  className="radio"
                  style={
                    type2 ? { background: "#8F00FF" } : { background: "white" }
                  }
                ></div>
              </div>

              <p>Registered</p>
            </div>
          </div>

          {type1 && (
            <form onSubmit={getData} className="business-form">
              {/* <div className="business-type-flex">
              <p>Are you a driver?</p>
            </div>
            <div className="business-type-container" style={{ width: "127px" }}>
              <div className="business-type" onClick={showType3}>
                <div
                  className="radio-container"
                  style={
                    type3
                      ? { border: "1px solid #8F00FF" }
                      : { border: "1px solid #C4C4C4" }
                  }
                >
                  <div
                    className="radio"
                    style={
                      type3
                        ? { background: "#8F00FF" }
                        : { background: "white" }
                    }
                  ></div>
                </div>

                <p>Yes</p>
              </div>

              <div className="business-type" onClick={showType4}>
                <div
                  className="radio-container"
                  style={
                    type4
                      ? { border: "1px solid #8F00FF" }
                      : { border: "1px solid #C4C4C4" }
                  }
                >
                  <div
                    className="radio"
                    style={
                      type4
                        ? { background: "#8F00FF" }
                        : { background: "white" }
                    }
                  ></div>
                </div>

                <p>No</p>
              </div>
            </div> */}

              <div className="form-group">
                <div className="label-flex">
                  <label className="kyc-label">Business name </label>
                  {/* {window.innerWidth > 768 && (
                  <span onClick={() => setNeed(true)}>
                    why can't i edit this?
                  </span>
                )} */}
                </div>
                <input
                  type="text"
                  value={fullname}
                  name="business_name"
                  disabled
                />
              </div>
              <div className="form-group">
                <div className="label-flex">
                  <label className="kyc-label">Business address </label>
                </div>
                <input
                  type="text"
                  value={address}
                  name="business_address"
                  autocomplete="off"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="kyc-label">Business phone number </label>
                <input
                  type="text"
                  value={business_phonenumber}
                  name="business_phonenumber"
                  autocomplete="off"
                  onChange={(e) => {
                    setBussinessPhonenumber(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="form-group">
                <label className="kyc-label"> Bank </label>
                <img
                  src="/assets/dropb.svg"
                  alt="sv"
                  className="label-img"
                  onClick={() => toggleBank()}
                />

                <input
                  type="text"
                  value={bankN}
                  name="bankN"
                  onChange={(e) => {
                    setBankN(e.target.value);
                  }}
                  autocomplete="off"
                  required
                  onClick={() => toggleBank()}
                  onKeyPress={(e) => e.preventDefault()}
                  style={{
                    color: "transparent",
                    textShadow: "0 0 0 black",
                    cursor: "pointer",
                  }}
                  placeholder="Select"
                />
                {showBank && (
                  <ul>
                    {livecode.map((val, idx) => (
                      <li
                        key={idx}
                        onClick={onOptionClicked(val.bankName, val.bankCode)}
                        style={{ cursor: "pointer" }}
                      >
                        {val.bankName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="form-group">
                <label className="kyc-label">Account number </label>
                <input
                  type="number"
                  value={account_number}
                  name="account_number"
                  autocomplete="off"
                  onChange={(e) => {
                    setAccountNumber(e.target.value);
                  }}
                  onInput={(e) => {
                    if (e.target.value.length > e.target.maxLength)
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                  }}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  required
                  maxlength={10}
                  onKeyUp={() => {
                    account_number.length === 10
                      ? getAccountName()
                      : console.log("");
                  }}
                />
                {accountError !== "" && (
                  <p
                    style={{
                      fontSize: "13px",
                      color: "red",
                      transform: "translateY(-20px)",
                    }}
                  >
                    {accountError}
                  </p>
                )}
                {accountPending && (
                  <img
                    src="/assets/spin.svg"
                    style={{ width: "30px" }}
                    className="getAccount"
                  />
                )}
                {!accountPending && <></>}
              </div>

              <div className="form-group">
                <label className="kyc-label"> Account name </label>

                <input
                  type="text"
                  value={account_name}
                  name="account_name"
                  disabled
                  required
                  style={{ cursor: "not-allowed" }}
                />
              </div>

              <div className="form-group">
                <label className="kyc-label">
                  <p>
                    Referal code{" "}
                    <span style={{ color: "#a5a3a3" }}> (optional) </span>{" "}
                  </p>
                </label>

                <input
                  value={referrence}
                  className="inputboxSignup"
                  type="text"
                  name="referrence"
                  onChange={(e) => setReferrence(e.target.value)}
                />
              </div>
              <button
                type="submit"
                value="Next"
                className="kyc-submit-button"
                disabled={!checkFilled}
                style={{
                  backgroundColor: checkFilled ? "#8F00FF" : "#d9d9d9",
                  color: "#fff",
                  cursor: checkFilled ? "pointer" : "not-allowed",
                  marginBottom: "30%",
                }}
              >
                Create outlet
              </button>

              {/* {!checkFilled && (
              <button
                type="submit"
                value="Next"
                className="kyc-submit-button"
                style={{
                  backgroundColor: "#F8F8F8",
                  color: "#757575",
                  cursor: "default",

                  marginBottom: "160px",
                }}
                disabled="true"
              >
                Create outlet
              </button>
            )} */}
            </form>
          )}
          {type2 && <Registered />}
          {error !== "" && (
            <>
              {" "}
              <p>{error}</p>{" "}
              <button onClick={() => navigate("/account")}>
                Go back to login
              </button>{" "}
            </>
          )}

          {diff && <div className="cover" onClick={() => setDiff(false)}></div>}
          {whyBv && (
            <div className="cover" onClick={() => setWhyBv(false)}></div>
          )}
          {need && <div className="cover" onClick={() => setNeed(false)}></div>}
        </div>
      )}

      {bodyPreload && (
        <div className="business-preload">
          <div className="preload-body">
            <img src="/assets/spin.svg" alt="" style={{ width: "100px" }} />
            {pending1 && <p>Creating an organisation </p>}
            {pending2 && <p>Creating an Outlet</p>}
            {pending3 && <p>Creating a virtual account</p>}
            {pending4 && <p>Use Qevolt on the web to access all pages</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Orgatest;
