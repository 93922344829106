import React from "react";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import DateComponent from "../../DateComponent/DateComponent";
import Hamburger from "../../Hamburger/Hamburger";
import { useState } from "react";
import "./HomeMobile.css";
import PopUp from "../../PopUp/PopUp";
import QevoltWarning from "../../../QevoltWarning/QevoltWarning";
import { useEffect } from "react";
import QevoltLabel from "../../../QevoltLabel/QevoltLabel";
import QevoltInput from "../../../QevoltInput/QevoltInput";
import QevoltButton from "../../../QevoltButton/QevoltButton";
import axios from "axios";
const HomeMobile = ({
  data,
  pending,
  addModal,
  arr,
  removeitem,
  getOutletList,
  Odata,
  Opending,
  Oerror,
  getTransactions,
}) => {
  const {
    businessData,
    pop,
    dispatch,
    transactionPop,
    filterPop,
    outletReset,
    api,
    token,
  } = useAuthContext();
  const reg = localStorage.getItem("reg");
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");
  const [details, setDetails] = useState(null);
  const [outlet_name, setOutletName] = useState("");
  const [mail, setMail] = useState("");
  const [formPop, setFormPop] = useState(false);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const changeId = (secret, name, accNumber, bankName, verified, processed) => {
    localStorage.setItem("outlet_secret_id", secret);

    localStorage.setItem(
      "OutletDetails",
      JSON.stringify({
        name,
        accNumber,
        bankName,
        verified,
        processed,
      })
    );
    getTransactions();
    setDetails(JSON.parse(localStorage.getItem("OutletDetails")));
    dispatch({ type: "FILTER_POP_UP", payload: false });
    dispatch({ type: "BUSINESS_DATA", payload: null });
    dispatch({ type: "OUTLET_RESET", payload: true });
  };
  const [outletPending, setOutletPending] = useState(false);
  const [outletError, setOutletError] = useState("");
  const [outletSuccess, setOutletSuccess] = useState("");
  const createOutlet = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setOutletPending(true);
    try {
      const outletresponse = await axios.post(
        api + "/main/create/outlet",
        { business_secret_id, outlet_name },
        config
      );
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: outletresponse.data.secret_id },
        config
      );

      if (virtualaccount) {
        setOutletPending(false);
        setOutletError(virtualaccount.data.Error);
        if (virtualaccount.data.Success) {
          setOutletName("");
        }
      }
    } catch (err) {
      setOutletPending(false);
      setOutletError("couldnt handle your request at the moment");
    }
  };
  useEffect(() => {
    setDetails(JSON.parse(localStorage.getItem("OutletDetails")));
  }, []);

  return (
    <div className="mobile-container-transactions">
      {!formPop && (
        <>
          {pop && (
            <PopUp style={{ height: "45vh" }}>
              <div className="pop-body">
                <h1>Reach out to us</h1>
                <p>We’re available from 9AM - 5PM every weekday</p>
              </div>
              <div
                className="pop-body"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "35px",
                }}
              >
                <p>
                  {" "}
                  <img src="/assets/cd.svg" alt="" /> Live chat
                </p>
                <p>
                  {" "}
                  <img src="/assets/c.svg" alt="" /> Call 01-880-0277
                </p>
                <p>
                  {" "}
                  <img src="/assets/m.svg" alt="" /> Email support@qevolt.com
                </p>
              </div>
            </PopUp>
          )}

          {filterPop && (
            <>
              <PopUp style={{ height: "40vh" }}>
                {reg === "true" && (
                  <>
                    <>
                      <div className="pop-body">
                        <div className="pop-body">
                          <h1>Switch Outlets</h1>
                        </div>
                        <div
                          className="pop-content"
                          style={{ height: "200px" }}
                        >
                          {Opending && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src="/assets/spin.svg"
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  transform: "translateX(8px)",
                                }}
                                alt=""
                              />
                            </div>
                          )}
                          <div
                            style={{
                              height: "300px",
                              marginTop: "200px",
                              overflowY: "auto",
                            }}
                          >
                            {!Opending &&
                              Odata &&
                              Odata.map((val, idx) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>
                                    <div
                                      style={{ display: "flex", gap: "6px" }}
                                      onClick={() =>
                                        changeId(
                                          val.outlet.secret_id,
                                          val.outlet.name,
                                          val.outlet.virtual_account
                                            .account_number,
                                          val.outlet.virtual_account.bank_name,
                                          val.outlet.total_transactions,
                                          val.outlet.virtual_account.total
                                        )
                                      }
                                    >
                                      <div className="name-circle ">
                                        <p
                                          style={{
                                            marginTop: "0px",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          {val.outlet.name.substring(0, 1)}
                                        </p>
                                      </div>
                                      <p
                                        style={{
                                          transform: "translateY(-6px)",
                                        }}
                                      >
                                        {val.outlet.name}{" "}
                                      </p>
                                    </div>
                                    <p
                                      style={{
                                        marginLeft: "42px",
                                        transform: "translateY(-8px)",
                                        color: "#c4c4c4",
                                      }}
                                    >
                                      Outlet ID:{" "}
                                    </p>
                                  </div>
                                </div>
                              )).reverse()}
                          </div>
                        </div>
                      </div>

                      <div
                        className="create-out"
                        onClick={() => {
                          setFormPop(true);
                          dispatch({ type: "FILTER_POP_UP", payload: false });
                        }}
                      >
                        <img src="/assets/cc.svg" alt="" />
                        <p>Create a new outlet</p>
                      </div>
                    </>
                  </>
                )}
                {reg === "false" && (
                  <>
                    <div className="pop-body">
                      <div className="pop-body">
                        <h1>Switch Outlets</h1>
                      </div>
                      {Opending && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src="/assets/spin.svg"
                            style={{
                              width: "70px",
                              height: "70px",
                              transform: "translateX(8px)",
                            }}
                            alt=""
                          />
                        </div>
                      )}
                      {!Opending &&
                        Odata &&
                        Odata.map((val, idx) => (
                          <div>
                            <div style={{ display: "flex", gap: "6px" }}>
                              <div className="name-circle">
                                <p style={{ marginTop: "0px", color: "white" }}>
                                  {" "}
                                  {val.outlet.name.substring(0, 1)}
                                </p>
                              </div>
                              <p style={{ transform: "translateY(-5px)" }}>
                                {val.outlet.name}{" "}
                              </p>
                            </div>
                            <p
                              style={{
                                marginLeft: "42px",
                                transform: "translateY(-8px)",
                                color: "#c4c4c4",
                              }}
                            >
                              Outlet ID:{" "}
                            </p>
                          </div>
                        ))}
                    </div>
                    <QevoltWarning
                      text={
                        "You’re unable to add an outlet because your business is unregistered. Tap to upgrade"
                      }
                    />
                  </>
                )}
              </PopUp>
            </>
          )}
          {transactionPop && (
            <PopUp style={{ height: "45vh" }} removeItem={removeitem}>
              <div className="pop-body">
                <h1>Transaction Details</h1>
              </div>
              {arr.map((arr, idx) => (
                <>
                  <div
                    className="pop-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "13px",
                    }}
                  >
                    <p>Bank</p>
                    <h2
                      style={{
                        alignSelft: "center",
                        transform: "translateY(6px)",
                      }}
                    >
                      {arr.payer_bank_name}
                    </h2>
                  </div>
                  <div
                    className="pop-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "13px",
                    }}
                  >
                    <p>Name</p>
                    <h2
                      style={{
                        alignSelft: "center",
                        transform: "translateY(6px)",
                      }}
                    >
                      {arr.payer_account_name.toLowerCase()}
                    </h2>
                  </div>
                  <div
                    className="pop-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "13px",
                    }}
                  >
                    <p>Amount</p>
                    <h2
                      style={{
                        alignSelft: "center",
                        transform: "translateY(6px)",
                      }}
                    >
                      &#x20A6;{parseInt(arr.amount.toLocaleString())}
                    </h2>
                  </div>
                  <div
                    className="pop-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "13px",
                    }}
                  >
                    <p>Date and Time</p>
                    <h2
                      style={{
                        alignSelft: "center",
                        transform: "translateY(6px)",
                      }}
                    >
                      {new Date(arr.date_created).toDateString().substring(4)} ,{" "}
                      {`${new Date(arr.date_created)
                        .toTimeString()
                        .substring(0, 2)} : ${new Date(arr.date_created)
                        .toTimeString()
                        .substring(3, 5)}  `}{" "}
                      {new Date(arr.date_created)
                        .toTimeString()
                        .substring(0, 2) > 12
                        ? "pm"
                        : "am"}
                    </h2>
                  </div>
                  <div
                    className="pop-body"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "13px",
                    }}
                  >
                    <p>Transaction ID</p>
                    <h2
                      style={{
                        alignSelft: "center",
                        transform: "translateY(6px)",
                      }}
                    >
                      {arr.public_id}
                    </h2>
                  </div>
                </>
              ))}
            </PopUp>
          )}
          <div className="home-mobile-top">
            {businessData && !outletReset && (
              <div className="home-mobile-top-left">
                <div className="name-circle">
                  <p> {businessData.name.substring(0, 1)} </p>
                </div>
                <div
                  className="business-name"
                  onClick={() => {
                    dispatch({ type: "FILTER_POP_UP", payload: true });
                    getOutletList();
                  }}
                >
                  <p>{businessData.name}</p>
                  <img src="/assets/md.svg" alt="" />
                </div>
              </div>
            )}
            {details && outletReset && (
              <div className="home-mobile-top-left">
                <div className="name-circle">
                  <p> {details.name.substring(0, 1)} </p>
                </div>
                <div
                  className="business-name"
                  onClick={() => {
                    dispatch({ type: "FILTER_POP_UP", payload: true });
                    getOutletList();
                  }}
                >
                  <p>{details.name} </p>
                  <img src="/assets/md.svg" alt="" />
                </div>
              </div>
            )}
            {/* <div className="home-mobile-top-right" onClick={()=> dispatch({type: 'POP_UP' , payload: true}) }>
                <img src="/assets/cd.svg" alt=""  />
            </div> */}
          </div>

          {businessData && !outletReset && (
            <div className="home-mobile-middle">
              <div
                className="home-mobile-middle-box"
                style={{ background: "#8f00ff", border: "none" }}
              >
                <h3 style={{ color: "#f4f4f4" }}>Account details</h3>
                {businessData && (
                  <h2 style={{ color: "#fff" }}>
                    {businessData.outlet.virtual_account.account_number}
                  </h2>
                )}
                {businessData && (
                  <p style={{ color: "#f4f4f4" }}>
                    {businessData.outlet.virtual_account.bank_name}
                  </p>
                )}
              </div>
              <div className="home-mobile-middle-box">
                {businessData && <h2>Transactions Verified</h2>}
                {businessData && <p>{businessData.total_transactions}</p>}
              </div>
              <div className="home-mobile-middle-box">
                {businessData && <h2>Amount Verified</h2>}
                {businessData && (
                  <p>
                    &#x20A6;
                    {parseInt(businessData.total_amount).toLocaleString()}
                  </p>
                )}
              </div>
            </div>
          )}
          {details && outletReset && (
            <div className="home-mobile-middle">
              <div
                className="home-mobile-middle-box"
                style={{ background: "#8f00ff", border: "none" }}
              >
                <h3 style={{ color: "#f4f4f4" }}>Account details</h3>
                {details && (
                  <h2 style={{ color: "#fff" }}>{details.accNumber}</h2>
                )}
                {details && (
                  <p style={{ color: "#f4f4f4" }}>{details.bankName}</p>
                )}
              </div>
              <div className="home-mobile-middle-box">
                {details && <h2>Transactions Verified</h2>}
                {details && <p>{details.processed}</p>}
              </div>
              <div className="home-mobile-middle-box">
                {details && <h2>Amount Verified</h2>}
                {details && (
                  <p>
                    &#x20A6;
                    {details.verified}
                  </p>
                )}
              </div>
            </div>
          )}

          <div className="home-middle-close">
            <p>Recent transactions</p>
          </div>

          <div className="transactions-mobile-table" style={{ height: "60vh" }}>
            {pending && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/assets/spin.svg"
                  style={{ width: "70px", height: "70px" }}
                  alt=""
                />
              </div>
            )}

            {data &&
              !pending &&
              data
                .map((val, idx) => (
                  <div className="table-container" key={idx}>
                    <div
                      className="table-flex"
                      onClick={() => {
                        addModal(val);
                        dispatch({ type: "TRANSACTION_POP_UP", payload: true });
                        dispatch({ type: "POP_UP", payload: false });
                      }}
                    >
                      <div className="left-table">
                        <div className="left-table-circle"></div>
                        <div className="left-table-details">
                          <h3 style={{ marginBottom: "6px" }}>
                            {val.payer_account_name.toLocaleLowerCase()}
                          </h3>
                          <DateComponent
                            date_created={val.date_created}
                            seen={val.seen}
                          />
                        </div>
                      </div>
                      <div className="right-table">
                        <p>
                          {" "}
                          <p>&#x20A6;{parseInt(val.amount).toLocaleString()}</p>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
                .slice(0, 5)}
          </div>

          <Hamburger />
        </>
      )}

      {formPop && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                {/* <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",

                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setFormPop(false)}
                />{" "} */}
                Create a new outlet
              </h1>
            </div>
          </div>
          <QevoltWarning
            text={
              "You’re about to add an outlet to your organization. You can monitor all activities of this outlet"
            }
          />

          <QevoltLabel text={"Outlet"} />
          <QevoltInput
            value={outlet_name}
            onChange={(e) => setOutletName(e.target.value)}
            name={"outlet_name"}
          />

          <QevoltLabel text={"Custom message (optional)"} />
          <QevoltInput />
          {outlet_name !== "" && !outletPending && (
            <QevoltButton
              text={"Create outlet"}
              onClick={() => createOutlet()}
            />
          )}
          {outlet_name === "" && !outletPending && (
            <QevoltButton
              text={"Create outlet"}
              style={{ background: "#d9d9d9" }}
            />
          )}
          {outletPending && (
            <button className="q-button" style={{ background: "#d9d9d9" }}>
              <img
                src="/assets/spinner.svg"
                alt=""
                style={{
                  width: "35px",
                  height: "35px",
                  transform: "translateY(3px)",
                }}
                disabled
              />
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default HomeMobile;
