import React from "react";
import "./OTP.css";
import { useState, useRef, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import Success from "../../Success/Success";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import OtpInput from "react-otp-input";

const OTP = () => {
  const navigate = useNavigate();

  const { email, token, dispatch, business, verifiedemail, api } =
    useAuthContext();

  const [checkOTP, setCheckOTP] = useState(false);
  const [otp, setOtp] = useState("");

  const otpp = useRef();

  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);
  const [data, setData] = useState(null);
  const form = { email, otp };

  const submitOtp = async (e) => {
    e.preventDefault();
    setPending(true);
    setError("");
    setOtpSuccess("");
    try {
      const response = await axios.post(api + "/accounts/verify/email/", form);

      if (Object.keys(response.data).length > 1) {
        setPending(false);
      }
      if (Object.keys(response.data).length === 1) {
        setError(response.data.error);
        setPending(false);
        setData(response);
      }

      const verified = await axios.get(`${api}/accounts/check_user/${email}`);
      localStorage.setItem("verified", verified.data.email_verified);
      localStorage.setItem("business", verified.data.business);
      dispatch({ type: "VERIFIED", payload: localStorage.getItem("verified") });
      dispatch({ type: "BUSINESS", payload: localStorage.getItem("business") });

      if (verified.data.email_verified === true) {
        navigate("/account/");
      }
    } catch (err) {
      setPending(false);
      setError(err.response.data.error);
    }
  };

  //resend otp vairables

  const [otpPending, setOtpPending] = useState(false);
  const [otpSuccess, setOtpSuccess] = useState("");
  const array = otp.split("");
  console.log(array);
  const [otpError, setOtpError] = useState("");
  const [otpData, setOtpData] = useState(null);
  const resendOtp = async () => {
    setError("");
    const email = localStorage.getItem("mail");
    setOtpPending(true);
    setOtpSuccess("");
    try {
      const response = await axios.post(`${api}/accounts/resend/otp/`, {
        email,
      });
      if (response) {
        setOtpPending(false);
        setOtpSuccess(response.data.success);
        setOtpError(response.data.error);
        setOtpData(response);
      }
    } catch (err) {}
  };

  const filled = () => {
    if (otpp.current.value.length < 5) {
      setCheckOTP(false);
    } else {
      setCheckOTP(true);
    }
  };

  console.log(otp);
  return (
    <>
      <Fade duration={250}>
        <div className="login-to-signup top-bar-right">
          <div className="top-right-inner">
            <p>
              Have an account?{" "}
              <Link to="/account/login" className="app-purple un">
                Login
              </Link>
            </p>
          </div>
        </div>

        <div className="otp-container">
          {!otpPending && otpData && otpSuccess !== "" && otpSuccess && (
            <Success message={"OTP has been sent to your email address"} />
          )}
          {!otpPending && otpData && otpError !== "" && otpError && (
            <ErrorMessage message={otpError} />
          )}
          {data && error !== "" && error && <ErrorMessage message={error} />}
          {otpPending && <Success message={"Requesting OTP..."} />}

          <div className="onb-main-text">Verify OTP</div>
          <div className="onb-sub-text">We’ve sent an OTP to your email</div>
          <form className="Login-form" onSubmit={submitOtp}>
            <label>
              {/* {window.innerWidth >= 768 && <input
              className="inputBoxOtp"
              type="number"
              name="otp"
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0,e.target.maxLength);
              }}
              onKeyDown={e => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
            
              maxlength = {5}
              value={otp}
              placeholder="5 digit code"
              ref={otpp}
              onKeyUp={filled}
              onChange={(e)=>setOtp(e.target.value)}
            /> } */}
              <div className="otp-parent">
                <>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    isInputNum={true}
                    numInputs={5}
                    separator={<span style={{ width: "24px" }}></span>}
                    focusStyle={{ border: "1px solid #8F00FF" }}
                    inputStyle={{
                      width: "50px",
                      height: "49px",
                      background: "#F7EDFF",
                      borderRadius: "8px",
                      border: "none",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  />
                </>
              </div>
            </label>

            <div className="LoginToForgot">
              <p>
                
                <Link
                  to="/account/signup"
                  className="onb-purple-link"
                  onClick={() => resendOtp()}
                  style={{textDecoration: 'underline' , color: '#757575'}}
                  
                >
                  Change email address
                </Link>
              </p>
            </div>

            {!pending && array.length === 5 && (
              <button
                type="submit"
                value="Next"
                className="SignUp-button otp-button"
                style={{
                  backgroundColor: "#8F00FF",
                  color: "#fff",
                  cursor: "pointer",
                  cursor: "pointer",
                }}
              >
                Verify
              </button>
            )}
            {!pending && !checkOTP && array.length !== 5 && (
              <button
                type="submit"
                value="Next"
                className="SignUp-button otp-button"
                style={{
                  backgroundColor: "#d9d9d9",
                  color: "#fff",
                  cursor: "default",

                  cursor: "not-allowed",
                }}
                disabled="true"
              >
                Verify
              </button>
            )}

            {pending && (
              <button
                type="submit"
                value="Login"
                className="otp-button"
                style={{
                  backgroundColor: "#8F00FF",
                  color: "#fff",

                  cursor: "pointer",
                  pointerEvents: "all",
                }}
                disabled
              >
                <img
                  src="/assets/spinner.svg"
                  alt=""
                  style={{ width: "35px" }}
                />
              </button>
            )}

            {!otpPending && (
              <div className="LoginToForgot">
                <p>
                  Didn’t get the code?{" "}
                  <Link className="onb-purple-link" onClick={() => resendOtp()}>
                    Resend here
                  </Link>
                </p>
              </div>
            )}
            {otpPending && (
              <div className="LoginToForgot">
                <p>
                  Didn’t get the code?{" "}
                  <Link
                    className="onb-purple-link"
                    style={{ cursor: "not-allowed" }}
                  >
                    Resend here
                  </Link>
                </p>
              </div>
            )}
          </form>
        </div>
      </Fade>
    </>
  );
};

export default OTP;
