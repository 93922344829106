import { useAuthContext } from "../../../Hooks/useAuthContext";
import { useState, useEffect } from "react";
import { NavLink } from "react-bootstrap";
import { Fade } from "react-reveal";
import axios from "axios";
import "./Organisation.css";
import { Link, useNavigate } from "react-router-dom";
import MembersId from "../OrganisationId/MembersId/MembersId";
import OrganisationMobile from "./OrganisationMobile/OrganisationMobile";
import Success from "../../Success/Success";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const Organisation = () => {
  const navigate = useNavigate();
  const { token, api , transactionPop , dispatch } = useAuthContext();

  const [data, setData] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showInvites, setShowInvites] = useState(false);
  const [showModalInvites, setShowModalInvites] = useState(false);
  const [outlet_name, setOutletName] = useState("");
  const business_id = localStorage.getItem("business_secret_id");
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const Assign = () => {
    setShowAssign(true);
    setShowModal(true);
  };
  const removeitem = () => {
    setShowAssign(false);
    setShowModal(false);
  };

  const invites = () => {
    setShowInvites(true);
    setShowModalInvites(true);
  };
  const removeInvites = () => {
    setShowInvites(false);
    setShowModalInvites(false);
  };

  const [rolePending, setRolePending] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [arr, setArr] = useState([]);
  const [outletPending, setOutletPending] = useState(false);
  const [outletError, setOutletError] = useState("");
  const [outletSuccess, setOutletSuccess] = useState("");
  const [RolesModal, setRolesModal] = useState(false);
  const [outletRoleData, setOutletRoleData] = useState(null);
  const [outletRolePending, setOutletRolePending] = useState(false);
  const [outletRoleError, setOutletRoleError] = useState("");
  const [InvitesPending, setInvitesPending] = useState(false);
  const [invitesError, setInviteError] = useState("");
  const [invitesSuccess, setInvitesSuccess] = useState(null);
  const [email, setEmail] = useState("");
  const [resendData, setResendData] = useState(null);
  const [resendPending, setResendPending] = useState(false);
  const [resendSuccess, setResenSuccess] = useState("");
  const [resendError, setResendError] = useState("");
  const [pendingData, setPendingData] = useState(null);
  const [memShow, setMemShow] = useState(false);
  const [frole, setFrole] = useState("");
  const [toggleRole, setToggleRole] = useState(false);
  const [role, setRole] = useState("");
  const [valid, setValid] = useState(false);
  const roleS = localStorage.getItem("role");
  const userole = localStorage.getItem("role");
  const outlet_id = localStorage.getItem("outlet_secret_id");

  const revokeInvite = async (roleid) => {
    setResendPending(true);
    setResenSuccess("");
    setResendError("");
    try {
      const response = await axios.post(
        `${api}/main/revoke/invite`,
        { roleid },
        config
      );

      if (response) {
        setResendData(response);
        setResendPending(false);
        console.log(response);
        setResenSuccess(response.data);
        setResendError(response.data.error);
        resendInvite();
      }
    } catch (err) {
      setResendPending(false);
    }
  };

  const resendInvite = async (roleid) => {
    setResendPending(true);
    setResenSuccess("");
    setResendError("");
    try {
      const response = await axios.post(
        `${api}/main/resend/invite`,
        { roleid },
        config
      );

      if (response) {
        setResendData(response);
        setResendPending(false);

        setResenSuccess("Email has been sent");
        setResendError(response.data.Error);
      }
    } catch (err) {
      setResendPending(false);
    }
  };

  const roleoptions = (value, value2) => () => {
    setToggleRole(false);
    setFrole(value);
    setRole(value2);
  };

  const roleoptionsalone = (value) => () => {
    setToggleRole(false);
    setRole(value);
  };
  const rolearr = [
    {
      roleB: "MAGR",
      roleFr: "Outlet Manager",
    },

    { roleB: "STAF", roleFr: "Cashier" },
  ];

  const toggleRoles = () => {
    setToggleRole(!toggleRole);
  };

  const createOutletRole = async (e) => {
    setOutletRolePending(true);
    setInvitesSuccess("");
    setOutletRoleError("");
    try {
      e.preventDefault();
      const response = await axios.post(
        api + "/main/create/roles",
        { outlet_secret_id: outlet_id, role, email },
        config
      );

      if (response) {
        setOutletRolePending(false);
        setOutletRoleData(response.data);
      }

      if (response.data.Error !== "") {
        setOutletRoleError(response.data.Error);
      }

      if (response.data.Success !== "") {
        setInvitesSuccess(response.data.Success);
      }
    } catch (err) {
      setOutletRoleError(
        "There was an error sending your request , please check your internet connection"
      );
      setOutletRolePending(false);
    }
  };
  const showRolesModal = () => {
    setRolesModal(true);
  };
  const pendingInvites = async () => {
    setInvitesPending(true);
    try {
      const response = await axios.get(
        `${api}/main/view/pending/roles/${outlet_id}`,
        config
      );

      if (response) {
        setInvitesPending(false);
      }
      setPendingData(response.data.results);
    } catch (err) {
      setInviteError("There was an error getting your data");
    }
  };
  const deleteRoles = async (role_secret_id) => {
    setRolePending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");

    const form = { outlet_secret_id, role_secret_id };
    try {
      const response = await axios.post(
        `${api}/main/delete/roles`,
        form,
        config
      );

      if (response) {
        setRolePending(false);
        getData();
        setShowDelModal(false);
      }
    } catch (err) {
      setRolePending(false);
      alert("Couldnt perform request at the moment");
    }
  };
  const createOutlet = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setOutletPending(true);
    try {
      const outletresponse = await axios.post(
        api + "/main/create/outlet",
        { business_secret_id, outlet_name },
        config
      );
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: outletresponse.data.secret_id },
        config
      );

      if (virtualaccount) {
        setOutletPending(false);
        setOutletError(virtualaccount.data.Error);
        if (virtualaccount.data.Success) {
          setShowAssign(false);
          setShowModal(false);
          getData();
          setOutletName("");
        }
      }
    } catch (err) {
      setOutletPending(false);
      setOutletError("couldnt handle your request at the moment");
    }
  };

  const url = `${api}/main/all/outlet/${business_id}`;

  const navigateToOutlet = (
    secret,
    pub,
    access,
    outlet_name,
    outlet_public_id,
    outlet_accNumber,
    outlet_bankName
  ) => {
    navigate(`/dashboard/organisation/${pub}`);
    localStorage.setItem("outlet_secret_id", secret);
    localStorage.setItem("access", access);
    localStorage.setItem("outlet_name", outlet_name);
    localStorage.setItem("outlet_public_id", outlet_public_id);
    localStorage.setItem("outlet_accNumber", outlet_accNumber);
    localStorage.setItem("outlet_bankName", outlet_bankName);
  };

  const getData = async () => {
    setPending(true);
    try {
      const response = await axios.get(url, config);
      if (response.status === 200) {
        setPending(false);
        setError(null);
        setData(response.data.results);
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };

  const reg = localStorage.getItem("reg");

  useEffect(() => {
    getData();
  }, []);

  const filled = () => {
    if (email === "") {
      setValid(false);
    } else setValid(true);
  };

  return (
    <div className="dashboard-home-container">
      {showDelModal && (
        <>
          {arr.map((val, idx) => (
            <div className="logoutModal-container" key={idx}>
              <div className="logoutModal-header">Delete Role</div>
              <div className="logoutModal-body">
                <div className="logoutModal-question">
                  Are you sure you want to Delete?
                </div>
                <div className="logoutModal-buttons">
                  <button
                    className="logoutModal-cancel"
                    onClick={() => setShowDelModal(false)}
                  >
                    <p>No</p>
                  </button>
                  {!rolePending && (
                    <button
                      className="logoutModal-logout"
                      onClick={() => {
                        deleteRoles(val.role_secret_id);
                      }}
                    >
                      <p>Yes</p>
                    </button>
                  )}
                  {rolePending && (
                    <button className="logoutModal-logout">
                      <p>loading...</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {RolesModal && (
        <div className="rolesModal">
          <div className="rolesModal-header">
            <p>Roles</p>
          </div>
          <div className="rolesModal-body">
            <div className="rolesModal-object">
              <div className="rolesModal-object-header ">Admin</div>
              <div className="rolesModal-object-body">
                Admins have the ability to monitor the activities of every
                outlet they add to their organisation, including their own. They
                have the ability to change members and remove outlets. Admins
                are unable to request payouts or update information for other
                outlets. You must have more than one outlet to become an Admin.
                Only registered businesses may have multiple outlets
              </div>
            </div>
            <div className="rolesModal-object">
              <div className="rolesModal-object-header mt-24">
                Outlet Managers
              </div>
              <div className="rolesModal-object-body">
                Outlet Managers are in charge of overseeing the operations of a
                specific outlet. They have the ability to add cashiers, request
                payouts, and update outlet information. Outlet Managers, unlike
                Admins, are unable to add outlets
              </div>
            </div>
            <div className="rolesModal-object">
              <div className="rolesModal-object-header mt-24">Cashiers</div>
              <div className="rolesModal-object-body mb-30">
                Cashiers can only view and verify bank transfers of a specific
                outlet. Cashiers are unable to view an outlet's Payouts or
                Organization page. Cashiers only have access to update personal
                information in Settings
              </div>
            </div>
          </div>
          <button
            className="rolesModal-close-btn"
            onClick={() => setRolesModal(false)}
          >
            Cancel
          </button>
        </div>
      )}
      {outletRoleData && invitesSuccess !== "" && invitesSuccess && (
        <Success message={`${invitesSuccess.substring(0, 27)} ${email}`} />
      )}
      {outletRoleData && outletRoleError !== "" && outletRoleError && (
        <ErrorMessage message={outletRoleError} />
      )}
      {resendData && resendSuccess !== "" && resendSuccess && (
        <Success message={resendSuccess} />
      )}
      {resendData && resendError !== "" && resendError && (
        <ErrorMessage message={resendError} />
      )}
      <div className="organisation-nav">
        <div className="organisation-text">
          <div className="organisation-maintext">Organisation</div>
          <div className="organisation-subtext">
            Manage members in your outlet
          </div>
        </div>

        {roleS === "BOSS" && (
          <div className="members-btn-flex">
            <div
              className="question-mark "
              onClick={() => {
                showRolesModal();
              }}
            >
              <img src="/assets/question-mark.svg" alt="" />
            </div>
            <button
              className="members-btn"
              onClick={() => {
                setShowInvites(true);
                pendingInvites();
              }}
              style={{ background: "#F8F8F8", color: "#757575" }}
            >
              Invitations
            </button>
            <button
              className="members-btn"
              onClick={() => {
                setMemShow(true);
                setInviteError("");
              }}
            >
              Add member
            </button>
          </div>
        )}
        {roleS === "MAGR" && (
          <div className="members-btn-flex">
            <button
              className="members-btn"
              onClick={() => {
                setShowInvites(true);
                pendingInvites();
              }}
              style={{ background: "#F7EDFF", color: "#757575" }}
            >
              Invitations
            </button>
            <button className="members-btn" onClick={() => setMemShow(true)}>
              Add a member
            </button>
          </div>
        )}
      </div>

      {memShow && (
        <div className="modalid" style={{ width: "492px", height: "443px" }}>
          <div className="dashboard-transaction-modal-heading">
            <p className="member-modal-heading">Add member</p>
          </div>
          {/* <div className="payouts-warning mb-1">
            <p>
              {" "}
              You’re about to add a new cashier to your organisation. You’ll be
              able to monitor transactions, refunds, payouts performed by this
              cashier.
            </p>
          </div> */}

          <div
            className="dashboard-modal-organisation-input"
            style={{
              width: "100%",
              marginLeft: "0px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <form
              onSubmit={createOutletRole}
              style={{ width: "100%", margin: "0px", padding: "0px" }}
              autocomplete="off"
            >
              <label htmlFor="">
                <p className="members-label">Email address</p>
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setInvitesSuccess("");
                  setOutletRoleError("");
                  filled();
                }}
                style={{
                  color: "transparent",
                  textShadow: "0 0 0 black",
                  cursor: "pointer",
                  paddingLeft: "14px",
                  borderRadius: "12px",
                  height: "50px",
                }}
                className="addMem-input"
              />
              <label htmlFor="" className="role-cont">
                <p className="members-label" style={{ marginTop: "6px" }}>
                  Role
                </p>
                <img
                  onClick={toggleRoles}
                  className="role-drop-img"
                  src="/assets/role-drop.svg"
                  alt=""
                  autocomplete="off"
                />
              </label>
              <input
                type="text"
                name="role"
                value={frole}
                onClick={toggleRoles}
                onChange={(e) => {
                  setFrole(e.target.value);
                  setInvitesSuccess("");
                  setOutletRoleError("");
                }}
                style={{
                  color: "transparent",
                  textShadow: "0 0 0 black",
                  cursor: "pointer",
                  paddingLeft: "14px",
                  borderRadius: "12px",
                  height: "50px",
                }}
                className="addMem-input"
              />
              {toggleRole && userole === "BOSS" && (
                <ul className="role-drop">
                  {rolearr.map((val, idx) => (
                    <li
                      key={idx}
                      onClick={roleoptions(val.roleFr, val.roleB)}
                      style={{ cursor: "pointer", color: "black" }}
                    >
                      {val.roleFr}
                    </li>
                  ))}
                </ul>
              )}
              {toggleRole && userole === "MAGR" && (
                <ul className="role-drop">
                  <li
                    onClick={roleoptionsalone("STAF")}
                    style={{ cursor: "pointer", color: "black" }}
                  >
                    Cashier
                  </li>
                </ul>
              )}

              <div className="membtn-group">
                <button
                  className="memCancel"
                  onClick={() => {
                    setMemShow(false);
                    setOutletRoleError("");
                    setInvitesSuccess("");
                    setRole("");
                    setEmail("");
                  }}
                >
                  {" "}
                  Cancel
                </button>
                {!outletRolePending && (
                  <button
                    type="submit"
                    className="memAdd"
                    // style={{
                    //   background: valid ? "#8F00FF" : "#F8F8F8",
                    //   color: valid ? "#FFF" : "#757575",
                    // }}
                  >
                    Add member
                  </button>
                )}
                {outletRolePending && (
                  <button type="submit" className="memAdd">
                    {" "}
                    <img
                      src="/assets/spinner.svg"
                      alt=""
                      style={{ width: "35px" }}
                    />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {showInvites && (
        <div id="modalodd">
          <div className="dashboard-transaction-modal-heading">
            <p>Pending invites</p>
          </div>
          <div className="invitation-container">
            {!InvitesPending &&
              pendingData &&
              pendingData.map((val, idx) => (
                <div className="invitation-flex-container">
                  <div className="invitation-left">
                    <div className="invite-cirle">
                      {val.role === "MAGR" && <p>M</p>}
                      {val.role === "STAF" && <p>C</p>}
                      {val.role === "BOSS" && <p>A</p>}
                    </div>
                    <div className="invite-text">
                      {val.role === "MAGR" && <p>Outlet Manager</p>}
                      {val.role === "STAF" && <p>Cashier</p>}
                      <p>{val.email}</p>
                    </div>
                  </div>
                  <div className="invitation-right" style={{ display: "flex" }}>
                    <button
                      className="resend-btn"
                      onClick={() => revokeInvite(val.role_secret_id)}
                      style={{
                        background: "#FFF1F1",
                        color: "#FF3737",
                        marginLeft: "0px",
                      }}
                    >
                      {/* {resendPending && (
                          <div>
                            
                              <img src="/assets/spinload.svg" alt="" className = 'resend-loader' />
                            
                          </div>
                        )} */}
                      Revoke
                    </button>
                    {
                      <button
                        disabled={resendPending}
                        className="resend-btn"
                        onClick={() => resendInvite(val.role_secret_id)}
                      >
                        Resend
                      </button>
                    }
                  </div>
                </div>
              ))}

            {pendingData && pendingData.length === 0 && !InvitesPending && (
              <div className="no-pending">You Have No Pending Invites</div>
            )}

            {InvitesPending && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled
              >
                <img
                  src="/assets/spin.svg"
                  alt="loading..."
                  className="pending"
                />
              </div>
            )}
            {invitesError !== "" && (
              <p className="invites-error">{invitesError}</p>
            )}
          </div>

          <div className="dashboard-modal-buttons invitations-cancel">
            <button
              onClick={() => {
                setShowInvites(false);
                setResenSuccess("");
                setResendError("");
              }}
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      )}
      {memShow && (
        <div
          className="cover"
          onClick={() => {
            setMemShow(false);
            setOutletRoleError("");
            setInvitesSuccess("");
            setRole("");
            setEmail("");
          }}
        ></div>
      )}
      {transactionPop && <div className="cover"  onClick={()=>dispatch({type: 'TRANSACTION_POP_UP' , payload: false})}></div>}
      {RolesModal && (
        <div
          className="cover"
          onClick={() => {
            setRolesModal(false);
            setOutletRoleError("");
            setInvitesSuccess("");
            setRole("");
            setEmail("");
          }}
        ></div>
      )}
      {showInvites && (
        <div
          className="cover"
          onClick={() => {
            setShowInvites(false);
            setOutletRoleError("");
            setInvitesSuccess("");
            setRole("");
            setEmail("");
          }}
        ></div>
      )}
      {window.innerWidth > 900 && (
        <>
          <OrganisationMobile />
        </>
      )}
    </div>
  );
};

export default Organisation;
