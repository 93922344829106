import React from "react";
import Hamburger from "../../Hamburger/Hamburger";
import { useState } from "react";
import "./SettingsMobile.css";
import QevoltLabel from "../../../QevoltLabel/QevoltLabel";
import QevoltInput from "../../../QevoltInput/QevoltInput";
import QevoltButton from "../../../QevoltButton/QevoltButton";
import QevoltWarning from "../../../QevoltWarning/QevoltWarning";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import PopUp from "../../PopUp/PopUp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SettingsMobile = () => {
  const navigate = useNavigate();
  const { pop, dispatch, transactionPop, token, api } = useAuthContext();
  const logoutUrl = api + "/accounts/auth/logout/";
  const [pending, setPending] = useState();

  const logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setPending(true);
    localStorage.removeItem("user_token");
    localStorage.removeItem("verified");
    localStorage.removeItem("business");
    localStorage.removeItem("business_secret_id");
    localStorage.removeItem("outlet_secret_id");
    localStorage.removeItem("role");
    localStorage.removeItem("fullname");
    localStorage.removeItem("reg");
    localStorage.removeItem("mail");
    try {
      dispatch({ type: "BUSINESS_DATA", payload: null });
      const response = await axios.post(logoutUrl, config);
      if (response) {
        dispatch({ type: "LOGIN", payload: null });
        setPending(false);
        navigate("/account");
      }
    } catch (err) {
      setPending(false);
      navigate("/account");
    }
  };

  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const showFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
    setFourth(false);
  };
  const showSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
    setFourth(false);
  };
  const showThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
    setFourth(false);
  };
  const showFourth = () => {
    setFirst(false);
    setSecond(false);
    setThird(false);
    setFourth(true);
  };

  const [amount, setAmount] = useState("");
  const [fee, setFee] = useState("");
  const [pay, setPay] = useState("");
  const feeCalculator = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setPending(true);
    const outlet_id = localStorage.getItem("outlet_secret_id");
    const form = { amount, outlet_id };
    try {
      const response = await axios.post(`${api}/main/calculator`, form, config);
      if (response) {
        setFee(response.data.charges);
        setPay(response.data.payout_amount);
        setPending(false);
        console.log(response);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  return (
    <div className="mobile-container-transactions">
      {!first && !second && !third && !fourth && !fifth && (
        <>
          <div className="home-mobile-top">
            <div className="m-transactions-top-left">
              <h1>More</h1>
            </div>
          </div>
          <div className="settings-tabs">
            <div className="tabs-flex" onClick={() => setFirst(true)}>
              <div className="tabs-flex-left">
                <img src="/assets/x1.svg" alt="" />
                <p>Profile</p>
              </div>
              <div className="tabs-flex-right">
                <img src="/assets/sn.svg" alt="" />
              </div>
            </div>
            <div className="tabs-flex" onClick={() => setSecond(true)}>
              <div className="tabs-flex-left">
                <img src="/assets/x2.svg" alt="" />
                <p>Outlet information</p>
              </div>
              <div className="tabs-flex-right">
                <img src="/assets/sn.svg" alt="" />
              </div>
            </div>
            <div className="tabs-flex" onClick={() => setThird(true)}>
              <div className="tabs-flex-left">
                <img src="/assets/x3.svg" alt="" />
                <p>Payout</p>
              </div>
              <div className="tabs-flex-right">
                <img src="/assets/sn.svg" alt="" />
              </div>
            </div>
            <div className="tabs-flex" onClick={() => setFourth(true)}>
              <div className="tabs-flex-left">
                <img src="/assets/x4.svg" alt="" />
                <p>Security</p>
              </div>
              <div className="tabs-flex-right">
                <img src="/assets/sn.svg" alt="" />
              </div>
            </div>
            <div className="tabs-flex">
              <div className="tabs-flex-left">
                <img src="/assets/x5.svg" alt="" />
                <p>Support</p>
              </div>
            </div>
            <div className="tabs-flex" onClick={() => setFifth(true)}>
              <div className="tabs-flex-left">
                <img src="/assets/fee.svg" alt="" />
                <p>Fee calculator</p>
              </div>
            </div>
            <div
              className="tabs-flex"
              onClick={() => {
                dispatch({ type: "POP_UP", payload: true });
                dispatch({ type: "TRANSACTION_POP_UP", payload: false });
              }}
            >
              <div className="tabs-flex-left">
                <img src="/assets/x6.svg" alt="" />
                <p style={{ color: "#FF3737" }}>Delete account</p>
              </div>
            </div>
            <div
              className="tabs-flex"
              onClick={() => {
                dispatch({ type: "POP_UP", payload: false });
                dispatch({ type: "TRANSACTION_POP_UP", payload: true });
              }}
            >
              <div className="tabs-flex-left">
                <img src="/assets/x7.svg" alt="" />
                <p style={{ color: "#FF3737" }}>Logout</p>
              </div>
            </div>
          </div>
          <Hamburger />
        </>
      )}
      {first && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setFirst(false)}
                />{" "}
                Profile
              </h1>
            </div>
          </div>
          <form action="">
            <QevoltLabel text={"First name"} />
            <QevoltInput />
            <QevoltLabel text={"Last name"} />
            <QevoltInput />
            <QevoltLabel text={"Email address"} />
            <QevoltInput />
            <QevoltButton text={"Update profile"} />
          </form>
        </>
      )}
      {second && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setSecond(false)}
                />{" "}
                Outlet information
              </h1>
            </div>
          </div>
          <QevoltWarning text="Upload CAC certificate to upgrade to a registered business" />
          <form action="">
            <QevoltLabel text={"Business name"} />
            <QevoltInput />
            <QevoltLabel text={"Business phone number"} />
            <QevoltInput />
            <QevoltLabel text={"CAC certificate"} />
            <QevoltInput
              style={{ border: "1px solid dashed" }}
              placeholder={"Upload file"}
            />
            <QevoltButton text={"Update Outlet"} />
          </form>
        </>
      )}
      {third && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setThird(false)}
                />{" "}
                Payout account
              </h1>
            </div>
          </div>
          <QevoltWarning
            text={
              "For us to complete the update, the name on your new bank account must match the business name."
            }
          />
          <form action="">
            <QevoltLabel text={"Bank"} />
            <QevoltInput />
            <QevoltLabel text={"Account Number"} />
            <QevoltInput />
            <QevoltLabel text={"Account name"} />
            <QevoltInput />
            <QevoltLabel text={"Password"} />
            <QevoltInput />
            <QevoltButton text={"Update payout account"} />
          </form>
        </>
      )}
      {fourth && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setFourth(false)}
                />{" "}
                Security
              </h1>
            </div>
          </div>
          <form action="">
            <QevoltLabel text={"Old password"} />
            <QevoltInput />
            <QevoltLabel text={"New password"} />
            <QevoltInput />
            <QevoltLabel text={"Confirm password"} />
            <QevoltInput />
            <QevoltButton text={"Update password"} />
          </form>
        </>
      )}
      {fifth && (
        <>
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1 style={{ fontSize: "18px" }}>
                <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setFifth(false)}
                />{" "}
                Fee calculator
              </h1>
            </div>
          </div>
          <QevoltWarning
            text={
              "The Central Bank charges a stamp duty of ₦50 for each transfer to your account worth ₦10,000 or more. So, when you receive a transfer of ₦10,000 or more, we will debit your account of ₦50 on the Central Banks behalf."
            }
          />
          <QevoltLabel text={"You receieve"} />
          <QevoltInput
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            name={"amount"}
          />
          <QevoltLabel text={"Qevolt takes"} />
          <input type="text" className="q-input" value={fee} disabled />
          <QevoltLabel text={"We settle into your account"} />
          <input type="text" className="q-input" value={pay} disabled />
          {!pending && (
            <QevoltButton text={"Calculate"} onClick={() => feeCalculator()} />
          )}
          {pending && (
            <div>
              <button className="q-button">
                <img
                  src="/assets/spinner.svg"
                  alt=""
                  style={{ width: "40px", transform: "translateY(3px)" }}
                />
              </button>
            </div>
          )}
        </>
      )}
      {pop && (
        <PopUp style={{ height: "30vh" }}>
          <div className="delte-container">
            <div className="pop-body">
              <h1 style={{ marginBottom: "24px" }}>Delete account</h1>
              <p style={{ marginBottom: "24px" }}>
                Are you sure you want to delete your account?
              </p>
            </div>
            <div className="pop-button">
              <button>Cancel</button>
              <button>Delete</button>
            </div>
          </div>
        </PopUp>
      )}
      {transactionPop && (
        <PopUp style={{ height: "30vh" }}>
          <div className="delte-container">
            <div className="pop-body">
              <h1 style={{ marginBottom: "24px" }}>Log out</h1>
              <p style={{ marginBottom: "24px" }}>
                Are you sure you want to log out?
              </p>
            </div>
            <div className="pop-button">
              <button>Cancel</button>
              {!pending && (
                <button
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </button>
              )}
              {pending && (
                <button>
                  {" "}
                  <img
                    src="/assets/redspin.svg"
                    alt=""
                    style={{
                      width: "60px",
                      height: "40px",
                      transform: "translateX(2px)",
                    }}
                  />{" "}
                </button>
              )}
            </div>
          </div>
        </PopUp>
      )}
    </div>
  );
};

export default SettingsMobile;
