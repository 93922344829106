import React from 'react'
import './ExtraId.css'
const ExtraId = () => {
  const bankName = localStorage.getItem('outlet_bankName')
  const accountNumber = localStorage.getItem('outlet_accNumber')

  return (
    <div className='extra'>
      <p>Qevolt Account Number</p>
      <p>{accountNumber}-{bankName}</p>
    </div>
  )
}

export default ExtraId