import React from "react";
import Sidebar from "../../components/Dashboard-components/Sidebar/Sidebar";
import "./Dashboard.css";
import { NavLink } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Hooks/useAuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import LogoutModal from "../../components/Dashboard-components/LogoutModal/LogoutModal";
import LogoutCover from "../../components/Dashboard-components/LogoutCover/LogoutCover";
import SupportModal from "../../components/Dashboard-components/SupportModal/SupportModal";
import SupportCover from "../../components/Dashboard-components/SupportCover/SupportCover";
import DeleteMemberModal from "../../components/Dashboard-components/DeleteMemberModal/DeleteMemberModal";

import QevoltWarning from "../../components/QevoltWarning/QevoltWarning";
import QevoltButton from "../../components/QevoltButton/QevoltButton";
import QevoltInput from "../../components/QevoltInput/QevoltInput";
import QevoltLabel from "../../components/QevoltLabel/QevoltLabel";
import Success from "../../components/Success/Success";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import PopUp from "../../components/Dashboard-components/PopUp/PopUp";

function Dashboard() {
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(0);
  const reg = localStorage.getItem("reg");
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");

  const [businessPending, setBusinessPending] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const [outletPending, setOutletPending] = useState(false);
  const [outletError, setOutletError] = useState("");
  const [outletSuccess, setOutletSuccess] = useState("");
  const [details, setDetails] = useState(null);
  const [Odata, setOData] = useState(null);
  const [Opending, setOPending] = useState(false);
  const [Oerror, setOError] = useState("O");
  const [formPop, setFormPop] = useState(false);
  const [outlet_name, setOutletName] = useState("");
  const [second, setSecond] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [cac, setCac] = useState(null);
  const [data, setData] = useState(null);
  const [arr, setArr] = useState([]);
  const [cover, setCover] = useState(false);

  const upload = () => {
    document.querySelector(".upload").click();
  };
  const uploadCAC = (e) => {
    if (e.target.files[0]) {
      setCac(e.target.files[0]);
    }
  };

  const changeId = (
    secret,
    name,
    accNumber,
    bankName,
    verified,
    processed,
    access,
    public_id
  ) => {
    localStorage.setItem("outlet_secret_id", secret);
    localStorage.setItem("access", access);
    localStorage.setItem(
      "OutletDetails",
      JSON.stringify({
        public_id,
        name,
        accNumber,
        bankName,
        verified,
        processed,
      })
    );
    setTrigger((prev) => prev + 1);
    setDetails(JSON.parse(localStorage.getItem("OutletDetails")));
    dispatch({
      type: "OUTLET_DETAILS",
      payload: JSON.parse(localStorage.getItem("OutletDetails")),
    });
    dispatch({ type: "FILTER_POP_UP", payload: false });
    dispatch({ type: "BUSINESS_DATA", payload: null });
    dispatch({ type: "OUTLET_RESET", payload: true });
  };
  const {
    dispatch,
    token,
    businessData,
    secret_id,
    api,
    outModal,
    deleteModal,
    supportModal,
    outletReset,
    filterPop,
    deletePop,
  } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [outletRefreshSecretId, setOutletRreshSecretId] = useState("");

  const getBusiness = async () => {
    setBusinessPending(true);
    try {
      const businessresponse = await axios.get(
        api + "/main/all/business",
        config
      );
      console.log(businessresponse);
      console.log(businessresponse.data.results[0].outlet.virtual_account);
      if (
        businessresponse &&
        businessresponse.data.results[0].outlet.virtual_account === null
      ) {
         console.log('trying 1')

         try{
        const virtualaccount = await axios.post(
          api + "/main/create/virtual_account",
          {
            outlet_secret_id: businessresponse.data.results[0].outlet.secret_id,
          },
          {
            headers:{
              "Content-type" : "application/json",
              Authorization: `Bearer ${token},`
            },
          }
        );

        console.log(virtualaccount )
    
        if (virtualaccount) {
          const businessresponse = await axios.get(
            api + "/main/all/business",
            config
          );

          dispatch({
            type: "BUSINESS_DATA",
            payload: businessresponse.data.results[0],
          });
          if (businessresponse) {
            dispatch({
              type: "SECRET_ID",
              payload: businessresponse.data.results[0].outlet.secret_id,
            });

            localStorage.setItem(
              "business_secret_id",
              businessresponse.data.results[0].secret_id
            );
            localStorage.setItem(
              "outlet_secret_id",
              businessresponse.data.results[0].outlet.secret_id
            );
            localStorage.setItem(
              "role",
              businessresponse.data.results[0].outlet.role
            );
            localStorage.setItem(
              "reg",
              businessresponse.data.results[0].registered
            );
            localStorage.setItem(
              "outlet_name",
              businessresponse.data.results[0].name
            );
            setOutletRreshSecretId(
              businessresponse.data.results[0].outlet.secret_id
            );
            setBusinessPending(false);
          }
        }
      }
      catch(err){
        console.log(err )
      }
      }

      dispatch({
        type: "BUSINESS_DATA",
        payload: businessresponse.data.results[0],
      });

      if (businessresponse &&  businessresponse.data.results[0].outlet.virtual_account !== null) {

        console.log('trying 2')
        dispatch({
          type: "SECRET_ID",
          payload: businessresponse.data.results[0].outlet.secret_id,
        });

        localStorage.setItem(
          "business_secret_id",
          businessresponse.data.results[0].secret_id
        );
        localStorage.setItem(
          "outlet_secret_id",
          businessresponse.data.results[0].outlet.secret_id
        );
        localStorage.setItem(
          "role",
          businessresponse.data.results[0].outlet.role
        );
        localStorage.setItem(
          "reg",
          businessresponse.data.results[0].registered
        );
        localStorage.setItem(
          "outlet_name",
          businessresponse.data.results[0].name
        );
        setOutletRreshSecretId(
          businessresponse.data.results[0].outlet.secret_id
        );
        setBusinessPending(false);
      }
    } catch (err) {}
  };

  const updateOutletReg = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { phone, address, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Business Graded Successfully please log in again to add outlets"
        );
        setPending(false);
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  const updateOutlet = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { pkone: phone, address, cac, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Business Graded Successfully please log in again to add outlets"
        );
        setPending(false);
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  const createOutlet = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setOutletPending(true);
    try {
      const outletresponse = await axios.post(
        api + "/main/create/outlet",
        { business_secret_id, outlet_name },
        config
      );
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: outletresponse.data.secret_id },
        config
      );

      if (virtualaccount) {
        setOutletPending(false);
        setOutletError(virtualaccount.data.Error);
        setFormPop(false);
        setCover(false);

        if (virtualaccount.data.Success) {
          // setOutletSuccess(
          //   "outlet successfully added please return to home page to switch outlets"
          // );
          <Success
            message={`outlet successfully added please return to home page to switch outlets`}
          />;
          setOutletName("");
        }
      }
    } catch (err) {
      setOutletPending(false);
      // setOutletError("couldnt handle your request at the moment");
      <ErrorMessage message={`couldnt handle your request at the moment`} />;
    } finally {
      setOutletPending(false);
    }
  };

  const checkuserr = async () => {
    const mail = localStorage.getItem("mail");
    const checkuser = await axios.get(`${api}/accounts/check_user/${mail}`);
    localStorage.setItem("verified", checkuser.data.email_verified);
    localStorage.setItem("business", checkuser.data.business);

    dispatch({ type: "VERIFIED", payload: localStorage.getItem("verified") });
    dispatch({ type: "BUSINESS", payload: localStorage.getItem("business") });

    if (checkuser.data.business === true) {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    getBusiness();
    checkuserr();

    dispatch({ type: "LOGIN", payload: localStorage.getItem("user_token") });
  }, []);

  // const expireToken=()=>{
  //    setTimeout(()=>{
  //         localStorage.removeItem('user_token')
  //         navigate('/account')
  //    }, '1800000')
  // }

  useEffect(() => {
    // expireToken()
  }, []);
  const business_id = localStorage.getItem("business_secret_id");
  const role = localStorage.getItem("role");
  const getOutletList = async () => {
    setOPending(true);
    try {
      const response = await axios.get(
        `${api}/main/all/outlet/${business_id}`,
        config
      );
      if (response) {
        setOPending(false);
        setOError(null);
        setOData(response.data.results);
        console.log(response);
      }
    } catch (err) {
      setOPending(false);
      setOError(err.message);
    }
  };

  return (
    <>
      {formPop && (
        <div className="create-outlet-container">
          {/* {outletSuccess !== "" && outletSuccess && (
            <Success message={`${outletSuccess}`} />
          )}
          {outletError !== "" && outletError && (
            <ErrorMessage message={outletError} />
          )} */}
          <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
            <div className="m-transactions-top-left">
              <h1>
                {/* <img
                  src="/assets/arrow-left.svg"
                  alt=""
                  style={{
                    marginRight: "26px",
                    alignSelf: "center",
                    transform: "translateY(5px)",
                  }}
                  onClick={() => setFormPop(false)}
                />{" "} */}
                Create an outlet
              </h1>
            </div>
          </div>
          <QevoltWarning
            style={{
              marginTop: "108px",
            }}
            text={
              "You’re about to add an outlet to your organization. You can monitor all activities of this outlet. Click to upgrade"
            }
          />

          <p className="cr-out-label" style={{ marginTop: "36px" }}>
            Outlet name
          </p>
          <QevoltInput
            value={outlet_name}
            onChange={(e) => setOutletName(e.target.value)}
            name={"outlet_name"}
          />

          <p className="cr-out-label" style={{ marginTop: "24px" }}>
            Manager’s email address{" "}
            <span className="greyed-out"> (optional)</span>
          </p>
          <QevoltInput />

          <div className="cr-out-btn-flex">
            <div className="cr-out-cancel">
              <QevoltButton
                text={"Cancel"}
                onClick={() => {
                  setFormPop(false);
                  setCover(false);
                }}
                style={{ background: "#F8F8F8", color: "#757575" }}
              />
            </div>
            <div className="cr-out-button">
              {outlet_name !== "" && !outletPending && (
                <QevoltButton
                  text={"Create outlet"}
                  onClick={() => createOutlet()}
                />
              )}
              {outlet_name === "" && !outletPending && (
                <QevoltButton
                  text={"Create outlet"}
                  style={{
                    background: "#F8F8F8",
                    color: "#757575",
                    cursor: "default",
                  }}
                  disabled
                />
              )}
              {outletPending && (
                <button
                  className="q-button"
                  disabled
                  style={{
                    background: "#8F00FF",
                    width: "138px",
                    height: "43px",
                  }}
                >
                  <img
                    src="/assets/spinner.svg"
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                    }}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* ending of create outlet */}
      {second && (
        <>
          {success !== "" && success && <Success message={`${success}`} />}
          {error !== "" && error && <ErrorMessage message={error} />}
          {reg === "false" && (
            <>
              <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
                <div className="m-transactions-top-left">
                  <h1 style={{ fontSize: "18px" }}>
                    {/* <img
                      src="/assets/arrow-left.svg"
                      alt=""
                      style={{
                        marginRight: "26px",
                        alignSelf: "center",
                        transform: "translateY(5px)",
                      }}
                      onClick={() => setSecond(false)}
                    />{" "} */}
                    Outlet information
                  </h1>
                </div>
              </div>
              <QevoltWarning text="Upload CAC certificate to upgrade to a registered business" />
              <form action="" onSubmit={updateOutlet}>
                <QevoltLabel text={"Business name"} />
                <QevoltInput value={outlet_name} />
                <QevoltLabel text={"Business phone number"} />
                <QevoltInput
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  name={"phone"}
                />
                <QevoltLabel text={"Business Address"} />
                <QevoltInput
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  name={"address"}
                />
                <QevoltLabel text={"CAC certificate"} />

                <div className="form-group" onClick={upload}>
                  {
                    <input
                      type="file"
                      onChange={uploadCAC}
                      name="Upload"
                      className="upload"
                      accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style={{ display: "none" }}
                      required
                    ></input>
                  }
                  <div
                    className="kyc-dash-box"
                    style={{
                      backgroundImage: "none",
                      width: "100%",
                      border: "1px dashed #d9d9d9",
                      margin: "6px 0px 24px 0px",
                      height: "48px",
                      alignItems: "flex-start",
                      paddingLeft: "16px",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#757575",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Upload file
                    </p>
                  </div>
                </div>
                {cac && (
                  <div
                    className=""
                    style={{
                      transform: "translateY(-15px)",
                      color: "green",
                      fontSize: "14px",
                    }}
                  >
                    Succesfully uploaded
                  </div>
                )}

                {!pending && <QevoltButton text={"Update Outlet"} />}
                {pending && (
                  <button className="q-button" disabled>
                    <img
                      src="/assets/spinner.svg"
                      alt=""
                      style={{
                        width: "40px",
                        transform: "translateY(3px)",
                      }}
                    />
                  </button>
                )}
              </form>
            </>
          )}
          {reg === "true" && (
            <>
              <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
                <div className="m-transactions-top-left">
                  <h1 style={{ fontSize: "18px" }}>
                    {/* <img
                      src="/assets/arrow-left.svg"
                      alt=""
                      style={{
                        marginRight: "26px",
                        alignSelf: "center",
                        transform: "translateY(5px)",
                      }}
                      onClick={() => setSecond(false)}
                    />{" "} */}
                    Outlet information
                  </h1>
                </div>
              </div>
              <QevoltWarning text="Only business phone number and address can be edited on this form. Reach out to support to fully update outlet" />
              <form action="" onSubmit={updateOutletReg}>
                <QevoltLabel text={"Business name"} />
                <QevoltInput value={outlet_name} />
                <QevoltLabel text={"Business phone number"} />
                <QevoltInput
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  name={"phone"}
                />
                <QevoltLabel text={"Business Address"} />
                <QevoltInput
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  name={"address"}
                />
                <QevoltLabel text={"CAC certificate"} />
                <div className="form-group">
                  {/* {  <input type= "file" onChange={uploadCAC} name="Upload" className='upload' accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{display:'none'}} required></input>} */}
                  <div
                    className="kyc-dash-box"
                    style={{
                      backgroundImage: "none",
                      width: "100%",
                      border: "1px dashed #d9d9d9",
                      margin: "6px 0px 24px 0px",
                      height: "48px",
                      alignItems: "flex-start",
                      paddingLeft: "16px",
                      borderRadius: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#757575",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Certificate in place{" "}
                    </p>
                  </div>
                </div>
                {!pending && <QevoltButton text={"Update Outlet"} />}
                {pending && (
                  <button className="q-button" disabled>
                    <img
                      src="/assets/spinner.svg"
                      alt=""
                      style={{
                        width: "40px",
                        transform: "translateY(3px)",
                      }}
                    />
                  </button>
                )}
              </form>
            </>
          )}
        </>
      )}
      {filterPop && (
        <>
          <PopUp style={{ height: "400px" }}>
            {reg === "true" && (
              <>
                <>
                  <div className="pop-body">
                    <div className="pop-body">
                      <h1>Switch Outlets</h1>
                    </div>
                    {Opending && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src="/assets/spin.svg"
                          style={{
                            position: "absolute",
                            width: "70px",
                            height: "70px",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                          alt=""
                        />
                      </div>
                    )}
                    <div
                      className="outlet-switch-contain"
                      style={{
                        height: "165px",
                        marginTop: "102px",
                        overflowY: "auto",
                      }}
                    >
                      {!Opending &&
                        Odata &&
                        Odata.map((val, idx) => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginRight: "24px",
                            }}
                          >
                            <div>
                              <div
                                style={{ display: "flex", gap: "6px" }}
                                onClick={() =>
                                  changeId(
                                    val.outlet.secret_id,
                                    val.outlet.name,
                                    val.outlet.virtual_account.account_number,
                                    val.outlet.virtual_account.bank_name, 
                                    val.outlet.virtual_account.total,   
                                    val.outlet.total_transactions,                                
                                                     
                                    val.outlet.default,
                                    val.outlet.public_id
                                  )
                                }
                              >
                                <div
                                  className="name-circle"
                                  style={{
                                    display: "grid",
                                    placeItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginTop: "0px",
                                      color: "white",
                                      marginLeft: "0px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {" "}
                                    {val.outlet.name
                                      .substring(0, 1)
                                      .toUpperCase()}
                                  </p>
                                </div>
                                <p
                                  className="switch-outlet-name ellipsis"
                                  style={{
                                    transform: "translateY(6px)",
                                    marginLeft: "6px",
                                    maxWidth: "220px",
                                  }}
                                >
                                  {val.outlet.name}
                                </p>
                              </div>
                              <p
                                style={{
                                  // marginLeft: "42px",
                                  // transform: "translateY(-8px)",
                                  color: "#c4c4c4",
                                }}
                                className="switch-outlet-outlet"
                              >
                                Outlet ID: {val.outlet.public_id}{" "}
                              </p>
                            </div>
                            <div
                              className="filter-radio"
                              style={
                                outlet_secret_id === val.outlet.secret_id
                                  ? {
                                      border: "1px solid #8F00FF",
                                      transform: "translateY(11px)",
                                    }
                                  : {
                                      border: "1px solid #c4c4c4",
                                      transform: "translateY(11px)",
                                    }
                              }
                            >
                              <div
                                className="filter-radio-circle"
                                style={
                                  outlet_secret_id === val.outlet.secret_id
                                    ? { background: "#8f00ff" }
                                    : { background: "none" }
                                }
                              ></div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="create-out"
                    onClick={() => {
                      setFormPop(true);
                      setCover(true);
                      dispatch({ type: "FILTER_POP_UP", payload: false });
                    }}
                  >
                    <img
                      src="/assets/cc.svg"
                      alt=""
                      style={{ cursor: "pointer" }}
                    />
                    <p>Create a new outlet</p>
                  </div>
                </>
              </>
            )}

            <button
              className="switch-cancel"
              onClick={() => {
                setFormPop(false);
                dispatch({ type: "FILTER_POP_UP", payload: false });
              }}
            >
              Cancel
            </button>
            <div className="pop-white-bot"></div>

            {reg === "false" && (
              <>
                <div className="pop-body">
                  <div className="pop-body">
                    <h1>Switch Outlets</h1>
                  </div>
                  <div
                  // onClick={() => setSecond(true)}
                  >
                    {/* <QevoltWarning
                      onClick={dispatch({
                        type: "FILTER_POP_UP",
                        payload: false,
                      })}
                      text={
                        "You’re unable to add an outlet because your business is unregistered."
                      }
                    /> */}
                    <NavLink to="/dashboard/settings?outlet=true">
                      <div
                        className="switch-warning"
                        onClick={() => {
                          setFormPop(false);
                          dispatch({ type: "FILTER_POP_UP", payload: false });
                          dispatch({ type: "OUTLET_INFO", payload: true });
                        }}
                      >
                        <p>
                          You’re unable to add an outlet because your business
                          is unregistered. Click to upgrade
                        </p>
                      </div>
                    </NavLink>
                  </div>
                  <div className="pop-content" style={{ paddingTop: "36px" }}>
                    {Opending && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src="/assets/spin.svg"
                          style={{
                            width: "70px",
                            height: "70px",
                            transform: "translateX(8px)",
                          }}
                          alt=""
                        />
                      </div>
                    )}
                    {/* unreg outlet */}
                    {!Opending &&
                      Odata &&
                      Odata.map((val, idx) => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginRight: "24px",
                          }}
                        >
                          <div>
                            <div
                              style={{ display: "flex", gap: "6px" }}
                              // onClick={() =>
                              //   changeId(
                              //     val.outlet.secret_id,
                              //     val.outlet.name,
                              //     val.outlet.virtual_account.account_number,
                              //     val.outlet.virtual_account.bank_name,
                              //     val.outlet.total_transactions,
                              //     val.outlet.virtual_account.total,
                              //     val.outlet.default,
                              //     val.outlet.public_id
                              //   )
                              // }
                            >
                              <div
                                className="name-circle"
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                  cursor: "pointer",
                                }}
                              >
                                <p
                                  style={{
                                    marginTop: "0px",
                                    color: "white",
                                    marginLeft: "0px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {" "}
                                  {val.outlet.name
                                    .substring(0, 1)
                                    .toUpperCase()}
                                </p>
                              </div>
                              <p
                                className="switch-outlet-name ellipsis"
                                style={{
                                  transform: "translateY(6px)",
                                  marginLeft: "6px",
                                  maxWidth: "220px",
                                }}
                              >
                                {val.outlet.name}
                              </p>
                            </div>
                            <p
                              style={{
                                // marginLeft: "42px",
                                // transform: "translateY(-8px)",
                                color: "#c4c4c4",
                              }}
                              className="switch-outlet-outlet"
                            >
                              Outlet ID: {val.outlet.public_id}{" "}
                            </p>
                          </div>
                          <div
                            className="filter-radio"
                            style={
                              outlet_secret_id === val.outlet.secret_id
                                ? {
                                    border: "1px solid #8F00FF",
                                    transform: "translateY(11px)",
                                  }
                                : {
                                    border: "1px solid #c4c4c4",
                                    transform: "translateY(11px)",
                                  }
                            }
                          >
                            <div
                              className="filter-radio-circle"
                              style={
                                outlet_secret_id === val.outlet.secret_id
                                  ? { background: "#8f00ff" }
                                  : { background: "none" }
                              }
                            ></div>
                          </div>
                        </div>
                      )).reverse()}
                  </div>
                </div>
              </>
            )}
          </PopUp>
        </>
      )}
      <div className="home-mobile-top">
        {businessData && !outletReset && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="home-mobile-top-left">
              <div className="name-circle">
                <p> {businessData.name.substring(0, 1)} </p>
              </div>
          
              
                <div
                  className="business-name"
                  style={{ maxWidth: "220px" }}
                  onClick={() => {
                    dispatch({ type: "FILTER_POP_UP", payload: true });
                    getOutletList();
                  }}
                >
                  <p>
                    <span className="ellipsis" style={{ maxWidth: "220px" }}>
                      {/* outlet name top left for non bosses*/}
                      {businessData.name}{" "}
                    </span>
                  </p>
                   <img src="/assets/md.svg" alt="" />
                </div>
        
            </div>
            <p className="outlet_id" style={{ marginLeft: "30px" }}>
              Outlet ID: {businessData.public_id}
            </p>
          </div>
        )}
        {details && outletReset && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="home-mobile-top-left">
              <div className="name-circle">
                <p> {details.name.substring(0, 1)} </p>
              </div>
              <div
                className="business-name"
                onClick={() => {
                  dispatch({ type: "FILTER_POP_UP", payload: true });
                  getOutletList();
                }}
              >
                <p>
                  <span className="ellipsis" style={{ maxWidth: "170px" }}>
                    {details.name}
                  </span>
                </p>
                <img src="/assets/md.svg" alt="" />
              </div>
            </div>
            <p className="outlet_id" style={{ marginLeft: "30px" }}>
              Outlet ID: {details.public_id}
            </p>
          </div>
        )}
      </div>
      {businessPending ? (
        <div className="pending-container">
          <div class="center">
            <div className="getbusinessLoader">
              <img
                src="/assets/spin.svg"
                alt=""
                style={{ width: "70px", height: "70px" }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="dashboard-container">
          <Sidebar />

          {outModal && <LogoutModal />}
          {outModal && <LogoutCover />}
          {supportModal && <SupportModal />}
          {supportModal && <SupportCover />}
          {deleteModal && <DeleteMemberModal />}

          <div className="dashboard-outlet">
            <Outlet context={[trigger, setTrigger]} />
          </div>
        </div>
      )}
      {cover && <div className="cover"></div>}
    </>
  );
}

export default Dashboard;
