import './PayoutsId.css'
import { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Fade } from 'react-reveal'
import axios from 'axios'
import { useAuthContext } from '../../../../Hooks/useAuthContext'
import DateComponent from '../../DateComponent/DateComponent'
import { QevoltData } from '../../../QevoltData/QevoltData'
const PayoutsId = () => {
  const {api , token , businessData} = useAuthContext()
  const {livecode} = QevoltData()
  const { id} = useParams()
  const [data,setData] = useState(null)
const [pending,setPending] = useState(false)
const [error,setError] = useState(null)
  const [arr,setArr] = useState([])
  const [showTransaction,setShowTransaction] = useState(false)
  const [showModal,setShowModal] = useState(false)

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const addModal = (event)=>{
          
    setArr((prev)=>{
      return prev.filter((val)=>{
        return val.id === event.id
      })
    })
    
    setShowModal(true)
  
    setShowTransaction(true)
  
  
  }
  
  const removeitem  =()=>{
    if(data){
        setArr(data)
    }
  
    arr.splice(0,0)
    setShowModal(false)
    setShowTransaction(false)
  }

  const outlet_secret_id  = localStorage.getItem('outlet_secret_id')
  const url = `${api}/main/view/withdraw/${outlet_secret_id}`


  const getData = async() =>{
   
    setPending(true);
    try {
     
      const response = await axios.get(url, config);
      if (response) {
        setPending(false);
        setError(null);
        setFilterState(false)
        setToday('Filter')
        setDropFilter(false)
      }

      setData(response.data.results);
      setArr(response.data.results);
      setPagRes(response.data)
      setPayoutsData(response.data)
      console.log(response.data)
  


      if (data) {
        setArr(data);
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
    
  }


  useEffect(() => {
    setPaginationCount(2)
    seetPaginationPrev(0)
    setPaginationFirst(1)
    setPaginationSecond(10)
    getData();
  }, [url]);


  const [pagRes , setPagRes] = useState([])

  const [paginationLink, setPaginationLink] = useState("");
 
    const [paginationCount, setPaginationCount] = useState(2);
    const [paginationPrev , seetPaginationPrev] = useState(0)
    const [paginationFirst , setPaginationFirst] = useState(1)
    const [paginationSecond , setPaginationSecond] = useState(10)

  const [panLink, setPanLink] = useState("");
  const paginationNext = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true)
    try{

    
    const nextpagination = await axios.get(
      `${api}/main/view/withdraw/${outlet_secret_id}?page=${paginationCount}`,
      config
    );
    if(nextpagination){
      setPending(false)
      setData(nextpagination.data.results);
    setPanLink(nextpagination.data.previous); 
    setPaginationCount((prev) => prev + 1);
    seetPaginationPrev((prev)=> prev + 1 )
    setPaginationFirst((prev) => prev + 10)
    setPaginationSecond((prev)=> prev + 10)
    setPaginationLink(nextpagination.data.previous)
    }
    
  }
  catch(err){

  }
  };


  const paginationPrevious = async () => {
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");
    setPending(true)
    try{
    
      const nextpagination =  await axios.get(
        `${api}/main/view/withdraw/${outlet_secret_id}?page=${paginationPrev}`,
        config
      );

      if(nextpagination){
        setPending(false)
        setPaginationCount((prev) => prev - 1);
        seetPaginationPrev((prev)=> prev - 1 )
        setPaginationFirst((prev) => prev - 10)
        setPaginationSecond((prev)=> prev - 10)
        setData(nextpagination.data.results);
  
      }

     
    
     


     
    }catch(err){

    }
  
     
    
   
  
  };


  let now = new Date()
const [filterCount , setFilterCount] = useState('')
const [filterResults , setFilterResults] = useState(null)
const [filterState , setFilterState] = useState(false)
const filterTransactions = async ( end_date , start_date , change) =>{
 setPending(true)  
 const outlet_secret_id = localStorage.getItem('outlet_secret_id')
setToday(change)
 const form = {start_date , end_date}


 try{  
     const response = await axios.post(`${api}/main/filter/withdraw/${outlet_secret_id}` , form , config)
     if(response){
         setPending(false)

         setData(response.data.results)
         setFilterResults(response.data)
         setFilterCount(response.data.count)
         setFilterState(true)
     } 

 }
 catch(err){

 }

}

  
  const [payoutsData , setPayoutsData] = useState(null)
   



  const [dropFilter , setDropFilter] = useState(false)

const [filterInput , setFilterInput] = useState('')
const [today , setToday] = useState('Today')
const [sDays , setSDays] = useState('Last 7 Days')
const [fDays , setFDays] = useState('Last 14 Days')
const [tDays , setTDays] = useState('Last 30 Days')



const [Radio1 , setRadio1] = useState(false)
const [Radio2 , setRadio2] = useState(false)
const [Radio3 , setRadio3] = useState(false)
const [Radio4 , setRadio4] = useState(false)

const showRadio1 = () =>{
  setRadio1(true)
  setRadio2(false)
  setRadio3(false)
  setRadio4(false)
  setDropFilter(false)
} 
const showRadio2 = () =>{
  setRadio1(false)
  setRadio2(true)
  setRadio3(false)
  setRadio4(false)
  setDropFilter(false)
} 
const showRadio3 = () =>{
  setRadio1(false)
  setRadio2(false)
  setRadio3(true)
  setRadio4(false)
  setDropFilter(false)
} 
const showRadio4 = () =>{
  setRadio1(false)
  setRadio2(false)
  setRadio3(false)
  setRadio4(true)
  setDropFilter(false)
} 


const filterNextPagination = async () =>{
const outlet_secret_id = localStorage.getItem("outlet_secret_id");
setPending(true)
try{


const nextpagination = await axios.get(
  `${api}/main/filter/withdraw/${outlet_secret_id}?page=${paginationCount}`,config
);
if(nextpagination){
  setPending(false)
  setData(nextpagination.data.results);
  setPanLink(nextpagination.data.previous); 
  setPaginationCount((prev) => prev + 1);
  seetPaginationPrev((prev)=> prev + 1 )
  setPaginationFirst((prev) => prev + 10)
  setPaginationSecond((prev)=> prev + 10)
  setPaginationLink(nextpagination.data.previous)
}

}catch(err){

}
}
const filterPreviousPagination = async () =>{
const outlet_secret_id = localStorage.getItem("outlet_secret_id");
setPending(true)
try{


const nextpagination = await axios.get(
  `${api}/main/filter/withdraw/${outlet_secret_id}?page=${paginationCount}`,config
);
if(nextpagination){
  setPending(false)
  setData(nextpagination.data.results);
  setPanLink(nextpagination.data.previous); 
  setPaginationCount((prev) => prev - 1);
  seetPaginationPrev((prev)=> prev - 1 )
  setPaginationFirst((prev) => prev - 10)
  setPaginationSecond((prev)=> prev - 10)
  setPaginationLink(nextpagination.data.previous)
}

}catch(err){

}
}




  return (
    <div>


          <div className="" >

          {showTransaction && <div className="modal" data-aos="fade-up" >
                <div className="dashboard-transaction-modal-heading" >
                    <p>Transaction Details</p>
                </div>
                {arr && arr.map((val,idx)=>(
                    
                        
                        <div  className='dashboard-modal-flex-container' key={idx}>
                            <div className="dashboard-modal-flex">
                            <p>Date and Time</p>
                            <p>{val.dateandtime}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Name</p>
                            <p>{val.name}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Amount</p>
                            <p> &#x20A6;{val.amount}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Bank</p>
                            <p>{val.bank}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Account Number</p>
                            <p>{val.account}</p>
                            </div>  
                            <div className="dashboard-modal-flex">
                            <p>Transaction ID</p>
                            <p>{val.amount}</p>
                            </div>  
                            </div>
                   
                       
               
              
                   
                ))}
                

                <div className="dashboard-modal-buttons">
                    <button onClick={()=>removeitem()} > Cancel</button>
                    
                </div>
                
                </div>
                }

<div className="transaction-id-nav">
  <div>
 {!filterState && <div className="dashboard-transactions-pagination">
         { paginationPrev !== 0 && !pending && <div
            className="previous"
            onClick={() => {
              
              paginationPrevious();
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
         { paginationPrev === 0 && !pending && <div
            className="previous"
            style={{
              pointerEvents: 'none'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}

          {pending && <div
            className="previous"
            style={{
              pointerEvents: 'none'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
          { payoutsData && <div className="dashboard-transactions-pagination-text">
            <p>
              Results: {paginationFirst} - {  paginationSecond > +payoutsData.count ?   `${payoutsData.count}`: ` ${ paginationSecond}`} of{" "} 
              {payoutsData.count}
            </p>
          </div> }

        { businessData && (paginationCount <= Math.ceil(pagRes.count/10)) && !pending &&  <div
            className="next"
            onClick={() => {
              paginationNext();
            }}
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                
              }}
            />
          </div>}
          {pending &&  <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none'
              }}
            />
          </div>}
        { businessData && (paginationCount > Math.ceil(pagRes.count/10)) && !pending &&   <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none'
              }}
            />
          </div>}



        </div>}

        {filterState && <div className="dashboard-transactions-pagination">
         { paginationPrev !== 0 && !pending && <div
            className="previous"
            onClick={() => {
              
             filterPreviousPagination();
            }}
          >
         <img src="/assets/right-arrow.svg" alt="" />
          </div>}
         { paginationPrev === 0 && !pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}

          {pending && <div
            className="previous"
            style={{
              pointerEvents: 'none',
              cursor: 'not-allowed'
            }}
          >
           <img src="/assets/right-arrow.svg" alt="" />
          </div>}
          { filterResults && <div className="dashboard-transactions-pagination-text">
            <p>
              Results: {paginationFirst} - {  paginationSecond > +filterCount ?   `${filterCount}`: ` ${ paginationSecond}`} of{" "} 
              {filterCount}
            </p>
          </div> }

        { filterResults && ( paginationCount <= Math.ceil(filterCount/10)) && !pending &&  <div
            className="next"
            onClick={() => {
              filterNextPagination();
            }}
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
               
              }}
            />
          </div>}
          {pending &&  <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        { filterResults && (paginationCount > Math.ceil(filterCount/10)) && !pending &&   <div
            className="next"
          
          >
            <img
              src="/assets/right-arrow.svg"
              alt=""
              style={{
                pointerEvents: 'none',
                cursor: 'not-allowed'
              }}
            />
          </div>}
        </div>}
    </div>
    <div style={{display: 'flex' , gap: '30px' , marginTop: '28px'}}>

{filterState && <div className="clear-filter" onClick={getData}>
        <p>Clear Filter</p>
</div>}
<div className="filter-container-dropdown">
{!pending && <div className="filter-drop" onClick={()=>setDropFilter(!dropFilter)}>
    <input 
    type="text" 
    value={today}
    style={{
      color: "transparent",
      textShadow: "0 0 0 black",
      cursor: "pointer",
    }}
    />
    <img src="/assets/dropfilter.svg" alt="" />
</div>}
{pending && <div className="filter-drop" style={{pointerEvents: 'none'}}>
    <input 
    type="text" 
    value={today}
    style={{
      color: "transparent",
      textShadow: "0 0 0 black",
      cursor: "pointer",
    }}
    />
    <img src="/assets/dropfilter.svg" alt="" />
</div>}
{dropFilter && <div className="filter-drop-value" style={{zIndex: '30000'}}>
  <div className="filter-drop-container" onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 0)).toISOString().substring(0,10) , 'Today'  ); showRadio1() }}>
  <div className="filter-radio" style={Radio1 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}} >
    <div className="filter-radio-circle"  style={ Radio1 ? {background: '#8f00ff'} : {background: 'none'}} >

    </div>
  </div>
<p  >
 
 Today
</p>
</div>
  <div className="filter-drop-container" onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 7)).toISOString().substring(0,10) , sDays  ) ; showRadio2()}}>
  <div className="filter-radio" style={Radio2 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}}  >
    <div className="filter-radio-circle" style={ Radio2 ? {background: '#8f00ff'} : {background: 'none'}} >

    </div>
  </div>
<p  >
 
{sDays}
</p>
</div>
  <div className="filter-drop-container" onClick={()=>{filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 14)).toISOString().substring(0,10) , fDays  ) ; showRadio3()}}>
  <div className="filter-radio" style={Radio3 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}} >
    <div className="filter-radio-circle" style={ Radio3 ? {background: '#8f00ff'} : {background: 'none'}}  >

    </div>
  </div>
<p  >
 
{fDays}
</p>
</div>
  <div className="filter-drop-container" onClick={()=> {filterTransactions(now.toISOString().substring(0,10) ,  new Date(now.setDate(now.getDate() - 30)).toISOString().substring(0,10) , tDays ) ; showRadio4()}}>
  <div className="filter-radio" style={Radio4 ? {border: '1px solid #8F00FF'} : {border: '1px solid #c4c4c4'}} >
    <div className="filter-radio-circle" style={ Radio4 ? {background: '#8f00ff'} : {background: 'none'}} >

    </div>
  </div>
<p  >
 
{tDays}
</p>
</div>

</div>}
</div>

</div>
        </div>

                <div className="dashboard-transactions-transactions" style={{height: '55vh'}}>

      


      <div className="dashboard-payouts-transaction-header-flex">
          <p>Date and Time</p>
          <p>Amount</p>
          <p>Status</p>
          <p>Bank</p>
          <p>Account Number</p>
      </div>
      

      <div className='preload'>
            
            {error && <p className='error'>There was an error getting your data </p> }
            {pending && <img src='/assets/roller.svg' alt='loading...' className='pending' />}
         
           
          </div>
{data && data.map((val,idx)=>(
        <div key={idx}>
 <Fade duration={250}><div className='dashboard-transactions-fetch-flex' >
        
 <div className="dashboard-payouts-transactions-list">

    <DateComponent date_created={val.date_created} />
     
 </div>
 <div className="dashboard-payouts-transactions-list">
    
     
         <p>
         &#x20A6;{val.amount}
           
         </p>
     
 </div>
                <div className="dashboard-payouts-transactions-list">

                <p>
                                      
                <h4
                        className="status"
                        style={
                          val.status === true
                            ? {
                                color: "#4BB543",
                                background: "#eefcf3",
                                width: "50px",
                              }
                            : val.status === false? {
                              color: "#757575",
                                background: "#f8f8f8",
                                width: "62px",
                            }
                            : {
                                color: "#757575",
                                background: "#f8f8f8",
                                width: "80px",
                              }
                        }
                      >
                        {val.status === null && "Pending"}
                        {val.status === true && "Paid"}
                        {val.status === false && "Failed"}
                      </h4>
                                      </p>
                    
                </div>
              <div className="dashboard-payouts-transactions-list">
                  
              
              {livecode.map((va, idx) => (
                      <>
                        {va.bankCode === val.pay_to_bank_code && (
                          <p>{va.bankName}</p>
                        )}
                      </>
                    ))}
                  
              </div>
              <div className="dashboard-payouts-transactions-list">
                  
              
                      <p>
                          {val.pay_to_account_number}
                      </p>
                  
              </div>
              </div>

 

 </Fade>
</div>

 ))}  
 </div>
      

    </div>
    {showModal && <div className="cover" onClick={()=>removeitem()}>
            
            </div>}

</div>
  )
}

export default PayoutsId