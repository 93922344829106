import { createContext, useReducer } from "react";
import axios from "axios";
export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, token: action.payload };
    case "SIGNUP":
      return { ...state, username: action.payload };
    case "SIGNUP_EMAIL":
      return { ...state, email: action.payload };
    case "VERIFIED":
      return { ...state, verifiedemail: action.payload };
    case "SECRET_ID":
      return { ...state, secret_id: action.payload };
    case "BUSINESS":
      return { ...state, business: action.payload };
    case "ADMIN":
      return { ...state, admin: action.payload };
    case "BUSINESS_DATA":
      return { ...state, businessData: action.payload };
    case "LOGOUT_MODAL":
      return { ...state, outModal: action.payload };
    case "SUPPORT_MODAL":
      return { ...state, supportModal: action.payload };
    case "DELETE-MEMBER-MODAL":
      return { ...state, deleteModal: action.payload };
    case "LOGOUT_COVER":
      return { ...state, outCover: action.payload };
    case "SUPPORT_COVER":
      return { ...state, supportCover: action.payload };
    case "POP_UP":
      return { ...state, pop: action.payload };
    case "PROFILE_POP_UP":
      return { ...state, profilePop: action.payload };
    case "TRANSACTION_POP_UP":
      return { ...state, transactionPop: action.payload };
    case "FILTER_POP_UP":
      return { ...state, filterPop: action.payload };
    case "OUTLET_RESET":
      return { ...state, outletReset: action.payload };
    case "OUTLET_DETAILS":
      return { ...state, outletDetails: action.payload };
    case "OUTLET_INFO":
      return { ...state, outletInfo: action.payload };
    // case "LOGGING_OUT":
    //   return { ...state, loggingOut: action.payload };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    token: null,
    username: null,
    email: null,
    outModal: false,
    supportModal: false,
    deleteModal: false,
    outCover: false,
    supportCover: false,
    verifiedemail: false,
    business: false,
    secret_id: null,
    admin: false,
    businessData: null,
    pop: false,
    transactionPop: false,
    filterPop: false,
    profilePop: false,
    outletReset: false,
    outletDetails: null,
    outletInfo: false,
    // loggingOut: true,
    // api: "https://qevolt-test.herokuapp.com",
    api: "https://api.qevolt.com",
  });

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${state.token}`,
    },
  };

  const business = async (
    data,
    setData,
    setPending,
    setError,
    setOutletRreshSecretId,
    setArr,
    setBusinessPending,
    setFilterState,
    setToday,
    setDropFilter,
    setPagRes,
    setRollerPending,
    roll
  ) => {
    if (!setRollerPending) {
      setPending(true);
    }
    setBusinessPending(true);
    if (setRollerPending) {
      setRollerPending(true);
      roll?.current?.classList?.add("roll");
    }
    try {
      const businessresponse = await axios.get(
        state.api + "/main/all/business",
        config
      );

      if (businessresponse) {
        if (!state.outletReset) {
          dispatch({
            type: "SECRET_ID",
            payload: businessresponse.data.results[0].outlet.secret_id,
          });

          dispatch({
            type: "BUSINESS_DATA",
            payload: businessresponse.data.results[0],
          });
        }

        localStorage.setItem(
          "business_secret_id",
          businessresponse.data.results[0].secret_id
        );
        // localStorage.setItem(
        //   "outlet_secret_id",
        //   businessresponse.data.results[0].outlet.secret_id
        // );
        setOutletRreshSecretId(
          businessresponse.data.results[0].outlet.secret_id
        );
        setBusinessPending(false);
      }

      const response = await axios.get(
        `${state.api}/main/view/transactions/${localStorage.getItem(
          "outlet_secret_id"
        )}`,
        config
      );

      setData(response.data.results);
      setArr(response.data.results);
      setPagRes(response.data);

      if (response) {
        if (!setRollerPending) {
          setPending(false);
        }
        setError(null);
        setFilterState(false);
        setToday("Filter");
        setDropFilter(false);
        setArr(response.data.results);
        if (setRollerPending) {
          roll?.current?.classList?.remove("roll");
          setRollerPending(false);
        }
      }

      if (data) {
        setArr(data);
      }
    } catch (err) {}
  };

  return (
    <AuthContext.Provider value={{ ...state, dispatch, business }}>
      {children}
    </AuthContext.Provider>
  );
};
