import "./Hamburger.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../Hooks/useAuthContext";

const Hamburger = ({ stat }) => {
  const burg = useRef();
  const [show, setShow] = useState(true);
  const [logout, setLogout] = useState(false);

  const transac = document.querySelector(".dashboard-transactions-container");
  const refu = document.querySelector(".refunds");
  const dashcontainer = document.querySelector(".dashboard-container");

  const { businessData } = useAuthContext();

  const showLogout = () => {
    burg.current.classList.remove("fade-in-left");
    burg.current.classList.add("fade-out-left");
    setShow(true);
    setLogout(true);
  };

  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const navigate = useNavigate();
  const showFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
    setFourth(false);
  };
  const showSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
    setFourth(false);
  };
  const showThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
    setFourth(false);
  };
  const showFourth = () => {
    setFirst(false);
    setSecond(false);
    setThird(false);
    setFourth(true);
  };

  return (
    <div className="burger-container">
      {logout && (
        <div className="modal-logout" data-aos="fade-up">
          <div className="dashboard-transaction-modal-heading">
            <p>Logout</p>
          </div>
          <div className="modal-confirm">
            <p>Are you sure you want to logout?</p>
            <div className="confirm-btns">
              <button onClick={() => setLogout(false)}>Cancel</button>
              <button onClick={() => navigate("/account")}>Logout</button>
            </div>
          </div>
        </div>
      )}

      <div className="hamburger">
        <div className="burger">
          <div className="burger-links">
            <NavLink to="/dashboard/home" className="dashboard-sidebar-links">
              <img src="/assets/g5.svg" alt="" />
              <p>Home</p>
            </NavLink>
            <NavLink
              to="/dashboard/transactions"
              className="dashboard-sidebar-links"
            >
              <img src="/assets/g1.svg" alt="" />
              <p>Transactions</p>
            </NavLink>
            <NavLink
              to="/dashboard/payouts"
              className="dashboard-sidebar-links"
            >
              {/* <img src="/assets/g2.svg" alt=""  /> */}
              <p>Payouts</p>
            </NavLink>
            <NavLink
              to="/dashboard/organisation"
              className="dashboard-sidebar-links"
            >
              <img src="/assets/g3.svg" alt="" />
              <p>Organisaiton</p>
            </NavLink>
            <NavLink
              to="/dashboard/settings"
              className="dashboard-sidebar-links"
            >
              <img src="/assets/g4.svg" alt="" />
              <p>More</p>
            </NavLink>
          </div>
        </div>
      </div>

      {logout && <div className="cover"></div>}
    </div>
  );
};

export default Hamburger;
