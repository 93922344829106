import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import axios from "axios";
import ReactDatePicker from "react-datepicker";
import CsvDownloader from "react-csv-downloader";
import "./CsvDownloader.css";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { CSVLink } from "react-csv";
const CsvDownloaderComponent = ({ type, setCsvModal }) => {
  const { api, token } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };

  const [csvShow, setCsvShow] = useState(false);

  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  var date = new Date(startDate);

  //day before
  const startinputDate = new Date(startDate.toISOString().substring(0, 10));
  const dayBeforeDate = new Date(
    startinputDate.getTime() - 24 * 60 * 60 * 1000
  );
  const dayBeforeDateString = dayBeforeDate.toISOString().slice(0, 10);

  //day after
  const endinputDate = new Date(endDate.toISOString().substring(0, 10));
  const dayAfterDate = new Date(endinputDate.getTime() + 24 * 60 * 60 * 1000);
  const dayAfterString = dayAfterDate.toISOString().slice(0, 10);

  console.log(dayBeforeDateString);

  const [data, setData] = useState([]);
  const [dateChanged, setDateChanged] = useState(false);
  const [dateChanged1, setDateChanged1] = useState(false);
  const [error, setError] = useState("");
  const [pending, setPending] = useState(false);

  const download = async (end_date, start_date) => {
    const outlet_id = localStorage.getItem("outlet_secret_id");
    console.log(outlet_id);
    setPending(true);
    setError("");

    console.log({ start_date, end_date });

    try {
      const response = await axios.post(
        `${api}/main/csv/${type}/${outlet_id}`,
        { start_date, end_date },
        config
      );

      const commonItems = [
        "secret_id",
        "ref",
        "pay_to_bank_code",
        "pay_to_account_firtname",
        "pay_to_account_lastname",
        "pay_to_account_number",
        "outlet",
      ];
      const newArray = response.data.map((obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(
            ([key, value]) => !commonItems.includes(key)
          )
        );
      });
      const objectNames = {
        public_id: "Rt ID",
        payer_account_name: "Payer Account Name",
        payer_account_number: "Payer Account Number",
        amount: "Amount",
        payer_bank_name: "Payer Bank Name",
        seen: "Status",
        date_created: "Date Created",
      };
      const finalArray = newArray.map((obj) => {
        const newObj = {};
        for (const [key, value] of Object.entries(obj)) {
          if (objectNames[key]) {
            newObj[objectNames[key]] = value;
          } else {
            newObj[key] = value;
          }
        }
        return newObj;
      });
      console.log(finalArray);
      setData(finalArray);
      setPending(false);
      setError("");

      document.querySelector(".click-btn").click();
    } catch {
      setError("");
      setPending(false);
    } finally {
      setError("");
      setPending(false);
    }
  };

  const rn = new Date().toISOString().substring(0, 10);

  const dateModded = () => {
    if (endDate.toISOString().substring(0, 10) !== rn) {
      setDateChanged(true);
    } else {
      setDateChanged(false);
    }
  };
  const startDateModded = () => {
    if (startDate.toISOString().substring(0, 10) !== rn) {
      setDateChanged1(true);
    } else {
      setDateChanged1(false);
    }
  };

  useEffect(() => {
    dateModded();
  }, [endDate]);

  useEffect(() => {
    startDateModded();
  }, [startDate]);

  return (
    <>
      {error !== "" && <ErrorMessage message={error} />}
      <div className="csv">
        <h1>Select Duration</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
          className="date-pick"
        >
          <div style={{ positon: "absolute", right: "0" }}>
            <ReactDatePicker
              className="filter-date"
              placeholder="Start date"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
            />
          </div>

          <div className="t">to</div>
          <div style={{ positon: "absolute", right: "0" }}>
            <ReactDatePicker
              className="filter-date"
              placeholder="End date"
              selected={endDate}
              onChange={(date: Date) => {
                setEndDate(date);
              }}
            />
          </div>
        </div>

        <div
          className="csv-btns"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {
            <button
              style={{
                color: "#757575",
                background: "#f8f8f8",
                width: "97px",
                height: "43px",
              }}
              onClick={() => setCsvModal(false)}
            >
              Cancel
            </button>
          }

          <div style={{ display: "none" }}>
            {data && data.length > 0 && (
              <CSVLink data={data} filename={"info.csv"}>
                <button
                  style={{ color: "white", background: "#8F00FF" }}
                  className="click-btn"
                >
                  Download
                </button>
              </CSVLink>
            )}
          </div>

          {data && !pending && (
            <button
              style={{
                width: "97px",
                height: "43px",
                backgroundColor: dateChanged1 ? "#8f00ff" : "#F8F8F8",
                color: dateChanged1 ? "#fff" : "#757575",
                pointerEvents: dateChanged1 ? "all" : "none",
              }}
              onClick={() => download(dayAfterString, dayBeforeDateString)}
            >
              Download
            </button>
          )}

          {data && pending && (
            <button
              style={{
                color: "white",
                background: "#8F00FF",
                width: "97px",
                height: "43px",
              }}
            >
              <img
                src="/assets/spinner.svg"
                alt=""
                style={{
                  width: "30px",
                  height: "30px",
                  transform: "translateY(2px)",
                }}
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default CsvDownloaderComponent;
