import React from "react";
import "./supportCover.css";
import { useAuthContext } from "../../../Hooks/useAuthContext";


const SupportCover = () => {
    const { dispatch } = useAuthContext();
  
    const removeSupportModal = () => {
      dispatch({ type: "SUPPORT_MODAL", payload: false });
    };
  
    return <div className="logout-cover" onClick={removeSupportModal}></div>;
  };
  
  export default SupportCover;