import React from "react";
import "./loginn.css";

import { useState, useRef, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import axios from "axios";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const Loginn = () => {
  const { dispatch, token, business, verifiedemail, api } = useAuthContext();

  const navigate = useNavigate();

  const email = useRef();
  const passwordd = useRef();

  const [pass, setPass] = useState(true);
  const [passType, setPassType] = useState("password");
  const [checkLogin, setCheckLogin] = useState(false);
  const [show, setShow] = useState("Show");

  const togglePass = () => {
    setPass(!pass);
    if (!pass) {
      setPassType("password");
      setShow("Show");
    }
    if (pass) {
      setPassType("text");
      setShow("Hide");
    }
  };

  const filled = () => {
    if (
      email.current.value !== "" &&
      passwordd.current.value !== "" &&
      passwordd.current.value.length >= 8
    ) {
      setCheckLogin(true);
    } else {
      setCheckLogin(false);
    }
  };

  const resetlocal = () => {
    localStorage.removeItem("user_token");
    localStorage.removeItem("business");
    localStorage.removeItem("verified");
  };
  useEffect(() => {
    filled();
    logout();
  }, []);

  const logoutUrl = api + "/accounts/auth/logout/";
  const logout = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    localStorage.removeItem("user_token");
    localStorage.removeItem("verified");
    localStorage.removeItem("business");
    localStorage.removeItem("business_secret_id");
    localStorage.removeItem("outlet_secret_id");
    localStorage.removeItem("role");
    localStorage.removeItem("fullname");
    localStorage.removeItem("reg");
    localStorage.removeItem("mail");
    try {
      dispatch({ type: "BUSINESS_DATA", payload: null });
      const response = await axios.post(logoutUrl, config);
      if (response) {
        dispatch({ type: "LOGIN", payload: null });
      }
    } catch (err) {}
  };

  //USER VALUE STATES

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");

  const url = api + "/accounts/auth/login/";
  const form = { username, password };

  const submitLogin = async (e) => {
    e.preventDefault();
    setError("");
    setPending(true);

    if (navigator.onLine) {
      try {
        const response = await axios.post(url, form);
        if (response) {
          dispatch({ type: "SIGNUP_EMAIL", payload: response.data.user.email });
          localStorage.setItem("mail", response.data.user.email);
        }
        const verified = await axios.get(
          `${api}/accounts/check_user/${response.data.user.email}`
        );

        localStorage.setItem("verified", verified.data.email_verified);
        localStorage.setItem("business", verified.data.business);
        localStorage.setItem("fullname", verified.data.fullname);
        dispatch({
          type: "VERIFIED",
          payload: localStorage.getItem("verified"),
        });
        dispatch({
          type: "BUSINESS",
          payload: localStorage.getItem("business"),
        });

        if (
          verified.data.email_verified === false &&
          verified.data.business === false
        ) {
          navigate("/account/loginotp");
        } else if (
          verified.data.business === false &&
          verified.data.email_verified === true
        ) {
          localStorage.setItem("user_token", response.data.access_token);
          const user = localStorage.getItem("user_token");
          dispatch({ type: "LOGIN", payload: user });
          navigate("/organisation");
        }
        if (
          verified.data.email_verified === true &&
          verified.data.business === true
        ) {
          localStorage.setItem("user_token", response.data.access_token);
          const user = localStorage.getItem("user_token");
          dispatch({ type: "LOGIN", payload: user });
          navigate("/dashboard");
          window.ReactNativeWebView.postMessage(username);
        }

        const businessresponse = await axios.get(api + "/main/all/business", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${response.data.access_token}`,
          },
        });

        if (businessresponse) {
          dispatch({
            type: "SECRET_ID",
            payload: businessresponse.data.results[0].outlet.secret_id,
          });
          setPending(false);
          dispatch({
            type: "BUSINESS_DATA",
            payload: businessresponse.data.results[0],
          });
          localStorage.setItem(
            "business_secret_id",
            businessresponse.data.results[0].secret_id
          );
          localStorage.setItem(
            "role",
            businessresponse.data.results[0].outlet.role
          );
        }
      } catch (err) {
        if (err) {
          setError("Check your email or password and try again");
        }

        setPending(false);
      }
    } else {
      setPending(false);
      setError("Please check your internet connection");
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("login");
    navigate("/dashboard");
  };
  return (
    <>
      <Fade duration={250}>
        <div className="login-to-signup top-bar-right">
          <div className="top-right-inner">
            <p>
              {" "}
              Don’t have an account?{" "}
              <Link to="/account/signup" className="app-purple">
                Sign Up
              </Link>
            </p>
          </div>
        </div>

        <div className="Login-container">
          {error !== "" && error && <ErrorMessage message={error} />}

          {/* <div className="static-app-top">
            <div className="static-app-textarea-top">
              <div className="static-app-maintext">Login</div>
              <div className="static-app-subtext">Log in to continue</div>
            </div>
            
          </div> */}

          {/* <div className="mobile-nav">
            <img src="/assets/Qevolt-logo-1.svg" alt="" />
            <NavLink to="/account/signup">Sign up</NavLink>
          </div> */}

          <div className="Login-main-text">Login</div>
          <div className="Login-subtext">Securely sign in to your account</div>
          <form className="Login-form" onSubmit={submitLogin}>
            <label>
              <div className="name-label">Email </div>
              <input
                className="inputboxSignup"
                type="text"
                name="username"
                value={username}
                placeholder="johndoe@gmail.com"
                ref={email}
                onKeyUp={filled}
                onChange={(e) => setUserName(e.target.value)}
              />
            </label>
            <label>
              <div className="name-label">
                Password
                <div className="ShowLoginPass" onClick={togglePass}>
                  {show}
                </div>
              </div>
              <input
                className="inputboxSignup"
                type={passType}
                value={password}
                name="password"
                placeholder="Min. of 8 characters"
                ref={passwordd}
                onKeyUp={filled}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>

            <div className="LoginToForgot">
              <p>
                Forgot Password?
                <Link to="/account/forgotpassword" className="app-purple">
                  {" "}
                  Reset it here
                </Link>
              </p>
            </div>

            {!pending && checkLogin && (
              <button
                type="submit"
                value="Next"
                className="Login-button"
                style={{
                  backgroundColor: "#8F00FF",
                  color: "#fff",
                  cursor: "pointer",

                  cursor: "pointer",
                }}
              >
                Login
              </button>
            )}
            {!pending && !checkLogin && (
              <button
                type="submit"
                value="Next"
                className="Login-button"
                style={{
                  backgroundColor: "#D9D9D9",
                  color: "#FFF",
                  cursor: "default",

                  cursor: "not-allowed",
                }}
                disabled="true"
              >
                Login
              </button>
            )}

            {pending && (
              <button
                type="submit"
                value="Next"
                className="Login-button"
                style={{
                  backgroundColor: "#8F00FF",
                  color: "#fff",
                  cursor: "pointer",
                  pointerEvents: "none",
                  cursor: "pointer",
                }}
                disabled
              >
                <img
                  src="/assets/spinner.svg"
                  alt=""
                  style={{ transform: "translateY(3px)" }}
                />
              </button>
            )}
          </form>

          {/* <div className="login-auth">
            <img src="/src/assets/loginauth.svg" alt="" />
            <div className="loginauthText">
              Touch or Face ID
            </div>
          </div> */}

          {window.innerWidth > 768 && (
            <div className="forgot-mobile">
              <Link to="/account/forgotpassword">Forgot Password?</Link>
            </div>
          )}
          {window.innerWidth > 768 && (
            <div className="LoginToSignUp">
              <p>
                {" "}
                Don’t have an account?{" "}
                <Link to="/account/signup" className="app-purple">
                  Sign Up
                </Link>
              </p>
            </div>
          )}

          <div className="bottomonb-link mobile-app">
            <p>
              {" "}
              Don’t have an account?{" "}
              <Link to="/account/signup" className="app-purple">
                Sign Up
              </Link>
            </p>
          </div>
        </div>

        {/* <div className="login-to-signup">
            <div className="">
              Don’t have an account? <Link to="/account/signup" className="app-purple">
             Sign Up
            </Link>
            </div>
            
            
          </div> */}

        {/* <div className="mobile-app">
            <div className="bottomLink">
              <p> 
              Don’t have an account?{" "}
              <Link to="/account/signup" className="app-purple">
              Sign Up
            </Link>

              </p>
            </div>
          </div> */}
      </Fade>
    </>
  );
};

export default Loginn;
