import React from "react";
import "./PopUp.css";
import { Fade } from "react-reveal";
import { useState } from "react";
import { useAuthContext } from "../../../Hooks/useAuthContext";
const PopUp = ({ children, style, removeItem, getRoles }) => {
  const [mode, setMode] = useState(true);
  const { pop, dispatch, transactionPop, filterPop, profilePop } =
    useAuthContext();
  return (
    <div className="popup-container">
      <div
        onClick={() => {
          dispatch({ type: "POP_UP", payload: false });
          dispatch({ type: "PROFILE_POP_UP", payload: false });
          dispatch({ type: "TRANSACTION_POP_UP", payload: false });
          dispatch({ type: "FILTER_POP_UP", payload: false });

          typeof removeItem === "function" ? removeItem() : console.log("");
          typeof getRoles === "function" ? getRoles() : console.log("");
        }}
        style={{
          height: "100vh",
          width: "100%",
          position: "fixed",
          display: mode ? "block" : "none",
          top: "0",
          left: "0",
          zIndex: "20000",
          backgroundColor: "rgba(0, 0, 0, .3)",
        }}
      ></div>
      {/* <Fade up duration={250} > */}
      <div
        className={
          pop || transactionPop || filterPop || profilePop
            ? "pop-white"
            : "slide-out-bottom"
        }
        style={style}
      >
        <div className="border-top">
          <img
            src="/assets/rec.svg"
            alt=""
            onTouchStart={() => {
              dispatch({ type: "POP_UP", payload: false });
              dispatch({ type: "PROFILE_POP_UP", payload: false });
              dispatch({ type: "TRANSACTIONS_POP_UP", payload: false });
              dispatch({ type: "FILTER_POP_UP", payload: false });
            }}
          />
        </div>
        {children}
      </div>
      {/* </Fade> */}
    </div>
  );
};

export default PopUp;
