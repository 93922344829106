import { Fade } from "react-reveal";
import "./MembersId.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import Success from "../../../Success/Success";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
const MembersId = ({ roleposition, getnewData }) => {
  const [memShow, setMemShow] = useState(false);
  const [data, setData] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [showInvites, setShowInvites] = useState(false);

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [frole, setFrole] = useState("");
  const [toggleRole, setToggleRole] = useState(false);

  //delete array modal useSatate

  const [showDelModal, setShowDelModal] = useState(false);

  // delte array useState

  const [arr, setArr] = useState([]);

  const deleteRoleModal = (role_id) => {
    setArr((prev) => {
      return prev.filter((val) => {
        return val.role_secret_id === role_id;
      });
    });

    setShowDelModal(true);
  };

  const toggleRoles = () => {
    setToggleRole(!toggleRole);
  };
  const rolearr = [
    {
      roleB: "MAGR",
      roleFr: "Outlet Manager",
    },

    { roleB: "STAF", roleFr: "Cashier" },
  ];
  const roleoptions = (value, value2) => () => {
    setToggleRole(false);
    setFrole(value);
    setRole(value2);
  };

  const roleoptionsalone = (value) => () => {
    setToggleRole(false);
    setRole(value);
  };

  const { id } = useParams();
  const { token, api, dispatch } = useAuthContext();

  const showDeleteModal = () => {
    dispatch({ type: "DELETE-MEMBER-MODAL", payload: true });
  };

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const outlet_id = localStorage.getItem("outlet_secret_id");

  const getData = async () => {
    setPending(true);
    try {
      const response = await axios.get(
        `${api}/main/view/roles/${outlet_id}`,
        config
      );
      if (response) {
        setPending(false);
        setError(null);
        setData(response.data.results);
        setArr(response.data.results);
      }
    } catch (err) {
      setPending(false);
      setError(err.message);
    }
  };

  const [pendingData, setPendingData] = useState(null);

  const [InvitesPending, setInvitesPending] = useState(false);
  const [invitesError, setInviteError] = useState("");
  const [invitesSuccess, setInvitesSuccess] = useState(null);
  const [RolesModal, setRolesModal] = useState(false);

  const showRolesModal = () => {
    setRolesModal(true);
  };

  const pendingInvites = async () => {
    setInvitesPending(true);
    try {
      const response = await axios.get(
        `${api}/main/view/pending/roles/${outlet_id}`,
        config
      );

      if (response) {
        setInvitesPending(false);
      }
      setPendingData(response.data.results);
    } catch (err) {
      setInviteError("There was an error getting your data");
    }
  };

  const [resendPending, setResendPending] = useState(false);
  const [resendData, setResendData] = useState(null);
  const [resendSuccess, setResenSuccess] = useState("");
  const [resendError, setResendError] = useState("");
  const resendInvite = async (roleid) => {
    setResendPending(true);
    setResenSuccess("");
    setResendError("");
    try {
      const response = await axios.post(
        `${api}/main/resend/invite`,
        { roleid },
        config
      );

      if (response) {
        setResendData(response);
        setResendPending(false);

        setResenSuccess("Email has been sent");
        setResendError(response.data.Error);
      }
    } catch (err) {
      setResendPending(false);
    }
  };

  const revokeInvite = async (roleid) => {
    setResendPending(true);
    setResenSuccess("");
    setResendError("");
    try {
      const response = await axios.post(
        `${api}/main/revoke/invite`,
        { roleid },
        config
      );

      if (response) {
        setResendData(response);
        setResendPending(false);
        console.log(response);
        setResenSuccess(response.data);
        setResendError(response.data.error);
        resendInvite();
      }
    } catch (err) {
      setResendPending(false);
    }
  };

  const [outletRolePending, setOutletRolePending] = useState(false);
  const [outletRoleError, setOutletRoleError] = useState("");
  const [outletRoleData, setOutletRoleData] = useState(null);

  const userole = localStorage.getItem("role");

  const createOutletRole = async (e) => {
    setOutletRolePending(true);
    setInvitesSuccess("");
    setOutletRoleError("");
    try {
      e.preventDefault();
      const response = await axios.post(
        api + "/main/create/roles",
        { outlet_secret_id: outlet_id, role, email },
        config
      );

      if (response) {
        setOutletRolePending(false);
        setOutletRoleData(response.data);
      }

      if (response.data.Error !== "") {
        setOutletRoleError(response.data.Error);
      }

      if (response.data.Success !== "") {
        setInvitesSuccess(response.data.Success);
      }
    } catch (err) {
      setOutletRoleError(
        "There was an error sending your request , please check your internet connection"
      );
      setOutletRolePending(false);
    }
  };

  //deleteRoles function vairables

  const [rolePending, setRolePending] = useState(false);

  const deleteRoles = async (role_secret_id) => {
    setRolePending(true);
    const outlet_secret_id = localStorage.getItem("outlet_secret_id");

    const form = { outlet_secret_id, role_secret_id };
    try {
      const response = await axios.post(
        `${api}/main/delete/roles`,
        form,
        config
      );

      if (response) {
        setRolePending(false);
        getData();
        setShowDelModal(false);
      }
    } catch (err) {
      setRolePending(false);
      alert("Couldnt perform request at the moment");
    }
  };

  useEffect(() => {
    // getnewData()
    getData();
  }, []);

  const roleS = localStorage.getItem("role");

  return (
    <div className="members">
      {showDelModal && (
        <>
          {arr.map((val, idx) => (
            <div className="logoutModal-container" key={idx}>
              <div className="logoutModal-header">Delete Role</div>
              <div className="logoutModal-body">
                <div className="logoutModal-question">
                  Are you sure you want to Delete?
                </div>
                <div className="logoutModal-buttons">
                  <button
                    className="logoutModal-cancel"
                    onClick={() => setShowDelModal(false)}
                  >
                    <p>No</p>
                  </button>
                  {!rolePending && (
                    <button
                      className="logoutModal-logout"
                      onClick={() => {
                        deleteRoles(val.role_secret_id);
                      }}
                    >
                      <p>Yes</p>
                    </button>
                  )}
                  {rolePending && (
                    <button className="logoutModal-logout">
                      <p>loading...</p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {RolesModal && (
        <div className="rolesModal">
          <div className="rolesModal-header">
            <p>Roles</p>
          </div>
          <div className="rolesModal-body">
            <div className="rolesModal-object">
              <div className="rolesModal-object-header ">Admin</div>
              <div className="rolesModal-object-body">
                Your business name is the same as your full name because you
                don’t have legal documentation displaying business name or
                company registration
              </div>
            </div>
            <div className="rolesModal-object">
              <div className="rolesModal-object-header mt-36">
                Outlet Managers
              </div>
              <div className="rolesModal-object-body">
                Your business name is the same as your full name because you
                don’t have legal documentation displaying business name or
                company registration
              </div>
            </div>
            <div className="rolesModal-object">
              <div className="rolesModal-object-header mt-36">Cashiers</div>
              <div className="rolesModal-object-body mb-30">
                Your business name is the same as your full name because you
                don’t have legal documentation displaying business name or
                company registration
              </div>
            </div>
          </div>
          {/* <button
            className="rolesModal-close-btn"
            onClick={() => setRolesModal(false)}
          >
            Cancel
          </button> */}
        </div>
      )}

      {outletRoleData && invitesSuccess !== "" && invitesSuccess && (
        <Success message={`${invitesSuccess.substring(0, 27)} ${email}`} />
      )}
      {outletRoleData && outletRoleError !== "" && outletRoleError && (
        <ErrorMessage message={outletRoleError} />
      )}
      {resendData && resendSuccess !== "" && resendSuccess && (
        <Success message={resendSuccess} />
      )}
      {resendData && resendError !== "" && resendError && (
        <ErrorMessage message={resendError} />
      )}

      {roleS === "BOSS" && (
        <div className="members-btn-flex">
          <div
            className="question-mark members-btn"
            onClick={() => {
              showRolesModal();
              setMemShow(true);
            }}
          >
            <img src="/assets/question-mark.svg" alt="" />
          </div>
          <button
            className="members-btn"
            onClick={() => {
              setShowInvites(true);
              pendingInvites();
            }}
            style={{ background: "#F8F8F8", color: "#757575" }}
          >
            Invitations
          </button>
          <button className="members-btn" onClick={() => setMemShow(true)}>
            Add a cashier
          </button>
        </div>
      )}

      {roleS === "MAGR" && (
        <div className="members-btn-flex">
          <button
            className="members-btn"
            onClick={() => {
              setShowInvites(true);
              pendingInvites();
            }}
            style={{ background: "#F7EDFF", color: "#757575" }}
          >
            Invitations
          </button>
          <button className="members-btn" onClick={() => setMemShow(true)}>
            Add a member
          </button>
        </div>
      )}

      {memShow && (
        <div className="modalid">
          <div className="dashboard-transaction-modal-heading">
            <p className="member-modal-heading">Add member</p>
          </div>
          {/* <div className="payouts-warning mb-1">
            <p>
              {" "}
              You’re about to add a new cashier to your organisation. You’ll be
              able to monitor transactions, refunds, payouts performed by this
              cashier.
            </p>
          </div> */}

          <div className="dashboard-modal-organisation-input">
            <form onSubmit={createOutletRole} autocomplete="off">
              <label htmlFor="">
                <p className="members-label">Email address</p>
              </label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setInvitesSuccess("");
                  setOutletRoleError("");
                }}
              />
              <label htmlFor="">
                <p className="members-label" style={{ marginTop: "6px" }}>
                  Role
                </p>
              </label>
              <input
                type="text"
                name="role"
                value={frole}
                onClick={toggleRoles}
                onChange={(e) => {
                  setFrole(e.target.value);
                  setInvitesSuccess("");
                  setOutletRoleError("");
                }}
                style={{
                  color: "transparent",
                  textShadow: "0 0 0 black",
                  cursor: "pointer",
                }}
                autocomplete="off"
              />
              {toggleRole && userole === "BOSS" && (
                <ul className="role-drop">
                  {rolearr.map((val, idx) => (
                    <li
                      key={idx}
                      onClick={roleoptions(val.roleFr, val.roleB)}
                      style={{ cursor: "pointer", color: "black" }}
                    >
                      {val.roleFr}
                    </li>
                  ))}
                </ul>
              )}
              {toggleRole && userole === "MAGR" && (
                <ul className="role-drop">
                  <li
                    onClick={roleoptionsalone("STAF")}
                    style={{ cursor: "pointer", color: "black" }}
                  >
                    Cashier
                  </li>
                </ul>
              )}

              <div className="membtn-group">
                <button
                  className="memCancel"
                  onClick={() => {
                    setMemShow(false);
                    setOutletRoleError("");
                    setInvitesSuccess("");
                    setRole("");
                    setEmail("");
                  }}
                >
                  {" "}
                  Cancel
                </button>
                {!outletRolePending && (
                  <button type="submit" className="memAdd">
                    Add member
                  </button>
                )}
                {outletRolePending && (
                  <button type="submit" className="memAdd" disabled>
                    {" "}
                    <img
                      src="/assets/spinner.svg"
                      alt=""
                      style={{ width: "35px" }}
                    />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}

      {showInvites && (
        <div id="modalodd">
          <div className="dashboard-transaction-modal-heading">
            <p>Pending invites</p>
          </div>
          <div className="invitation-container">
            {!InvitesPending &&
              pendingData &&
              pendingData.map((val, idx) => (
                <div className="invitation-flex-container">
                  <div className="invitation-left">
                    <div className="invite-cirle">
                      {val.role === "MAGR" && <p>M</p>}
                      {val.role === "STAF" && <p>C</p>}
                      {val.role === "BOSS" && <p>A</p>}
                    </div>
                    <div className="invite-text">
                      {val.role === "MAGR" && <p>Outlet Manager</p>}
                      {val.role === "STAF" && <p>Cashier</p>}
                      <p>{val.email}</p>
                    </div>
                  </div>
                  <div className="invitation-right" style={{ display: "flex" }}>
                    <button
                      className="resend-btn"
                      onClick={() => revokeInvite(val.role_secret_id)}
                      style={{ background: "#F8F8F8", color: "#757575" }}
                    >
                      {/* {resendPending && (
                          <div>
                            
                              <img src="/assets/spinload.svg" alt="" className = 'resend-loader' />
                            
                          </div>
                        )} */}
                      Revoke
                    </button>
                    {
                      <button
                        className="resend-btn"
                        onClick={() => resendInvite(val.role_secret_id)}
                      >
                        Resend
                      </button>
                    }
                  </div>
                </div>
              ))}

            {InvitesPending && (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/assets/roller.svg"
                  alt="loading..."
                  className="pending"
                />
              </div>
            )}
            {invitesError !== "" && <p>{invitesError}</p>}
          </div>

          <div className="dashboard-modal-buttons invitations-cancel">
            <button
              onClick={() => {
                setShowInvites(false);
                setResenSuccess("");
                setResendError("");
              }}
            >
              {" "}
              Cancel
            </button>
          </div>
        </div>
      )}

      <div
        className="dashboard-transactions-transactions "
        style={{ height: "65vh", marginTop: "0px" }}
      >
        <div className="dashboard-membersId-transaction-header-flex">
          <p>Name</p>
          <p>Email</p>
          <p>Role</p>
          <p>Manage</p>
        </div>

        <div className="preload">
          {error && (
            <p className="error">There was an error getting your data </p>
          )}
          {pending && (
            <img
              src="/assets/roller.svg"
              alt="loading..."
              className="pending"
            />
          )}
        </div>

        {data &&
          data
            .map((val, idx) => (
              <Fade duration={250}>
                <div className="dashboard-payouts-fetch-flex" key={idx}>
                  <div className="dashboard-membersId-transactions-list">
                    <p>{val.account.name}</p>
                  </div>

                  <div className="dashboard-membersId-transactions-list">
                    <p>{val.account.email}</p>
                  </div>
                  <div className="dashboard-membersId-transactions-list">
                    <p>
                      <h4
                        className="status"
                        style={
                          val.role !== "Pending"
                            ? {
                                color: "#4BB543",
                                background: "#eefcf3",
                                width: "80px",
                                display: "flex",
                                justifyContent: "center",
                              }
                            : {
                                color: "#757575",
                                background: "#f8f8f8",
                                width: "70px",
                              }
                        }
                      >
                        {val.role === "MAGR" && "Manager"}
                        {val.role === "STAF" && "Cashier"}
                        {val.role === "BOSS" && "Admin"}
                      </h4>
                    </p>
                  </div>
                  {val.role === "MAGR" && (
                    <div
                      className="dashboard-membersId-transactions-list"
                      onClick={() => deleteRoleModal(val.role_secret_id)}
                    >
                      <p className="members-edit" style={{ color: "#FF3737" }}>
                        Delete
                      </p>
                    </div>
                  )}
                  {val.role === "STAF" && (
                    <div
                      className="dashboard-membersId-transactions-list"
                      onClick={() => deleteRoleModal(val.role_secret_id)}
                    >
                      <p className="members-edit" style={{ color: "#FF3737" }}>
                        Delete
                      </p>
                    </div>
                  )}
                  {val.role === "BOSS" && (
                    <div className="dashboard-membersId-transactions-list">
                      <p className="members-edit"></p>
                    </div>
                  )}
                </div>
              </Fade>
            ))
            .reverse()}
      </div>

      {showInvites && (
        <div
          className="cover"
          onClick={() => {
            setShowInvites(false);
            setResenSuccess("");
            setResendError("");
          }}
        ></div>
      )}
      {RolesModal && (
        <div className="cover" onClick={() => setRolesModal(false)}></div>
      )}
      {showDelModal && (
        <div className="cover" onClick={() => setShowDelModal(false)}>
          {" "}
        </div>
      )}
      {memShow && (
        <div
          className="cover"
          onClick={() => {
            setMemShow(false);
            setOutletRoleError("");
            setInvitesSuccess("");
            setRole("");
            setEmail("");
          }}
        ></div>
      )}
    </div>
  );
};

export default MembersId;
